type GuideLinesProps = {
	elementGuideLine: {
		baseCoord: number;
		startCoord: number;
		endCoord: number;
	};
	type: "vertical" | "horizontal";
};

export const GuideLines: React.FC<GuideLinesProps> = (props) => {
	if (
		props.elementGuideLine.baseCoord === 0 &&
		props.elementGuideLine.startCoord === 0 &&
		props.elementGuideLine.endCoord === 0
	) {
		return null;
	}

	if (props.type === "horizontal") {
		return (
			<div
				style={{
					position: "absolute",
					top: `${props.elementGuideLine.baseCoord * 100}%`,
					left: `${props.elementGuideLine.startCoord * 100}%`,
					right: `${props.elementGuideLine.endCoord * 100}%`,
					height: 0,
					borderTop: "1px dashed #d43f8c",
					zIndex: "1000",
				}}
			></div>
		);
	} else {
		return (
			<div
				style={{
					position: "absolute",
					left: `${props.elementGuideLine.baseCoord * 100}%`,
					top: `${props.elementGuideLine.startCoord * 100}%`,
					bottom: `${props.elementGuideLine.endCoord * 100}%`,
					width: 0,
					borderLeft: "1px dashed #d43f8c",
					zIndex: "1000",
				}}
			></div>
		);
	}
};
