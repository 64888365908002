import { Coordinates, Cover } from "@giga-user-fern/api/types/api";
import { VideoEdits } from "@giga-user-fern/api/types/api/resources/video";
import { dummyVideoEdits } from "../../../videoEditTypes/core";
import { getModifiedWidthAndHeight } from "../../resolutionUtils";
import { CanvasAssets } from "../assets/CanvasAssets";
import feCanvasAssets from "../assets/FeCanvasAssets";

// IMPORTANT NOTE: THE CALCULATIONS FOR CANVAS DIMS IS REPLCIATED ON THE BE REPO
// IF YOU MAKE CHANGES HERE, YOU MUST MAKE CHANGES THERE
export class CanvasCoordinates {
	/**
	 *
	 * COORDINATE SYSTEM CONVERTERS
	 *
	 * fractionalCoords: {x, y} in the range [0,1] representing a fraction of the
	 * total width and height of only the screenclip in the canvas
	 * canvasCoords: {x, y} in the canvas coordinate system
	 * pixels: {x,y} pixels at the DOM level.
	 * pixels and canvas coords are off by just a scale factor
	 *
	 */

	videoEdits: VideoEdits;

	videoRenderWidth: number;
	videoRenderHeight: number;

	assets: CanvasAssets;

	canvasHeight: number;
	canvasWidth: number;

	constructor(videoEdits: VideoEdits, assets: CanvasAssets) {
		this.videoEdits = videoEdits;
		this.assets = assets;

		//canvas dims
		const canvasDims = this.initCanvasDims();
		this.canvasWidth = canvasDims.x;
		this.canvasHeight = canvasDims.y;

		//screenclip dimensions in the canvas
		const videoRenderDims = this.initVideoRenderDims();
		this.videoRenderWidth = videoRenderDims.x;
		this.videoRenderHeight = videoRenderDims.y;
	}

	getPaddingFactor: () => number = () => {
		let paddingFactor = 0;

		if (!this.videoEdits.background?.visible) {
			paddingFactor = 0;
		} else {
			if (this.videoEdits.background.padding) {
				paddingFactor = this.videoEdits.background.padding / 100;
			} else {
				paddingFactor = 0;
			}
		}

		return paddingFactor;
	};

	//#region CALCULATE DIMESIONS

	initCanvasDims: (covers?: { intro?: Cover; outro?: Cover }) => Coordinates =
		// IMPORTANT NOTE: THE CALCULATIONS FOR CANVAS DIMS IS REPLCIATED ON THE BE REPO
		// IF YOU MAKE CHANGES HERE, YOU MUST MAKE CHANGES THERE
		(covers) => {
			let _canvasWidth = 1920;
			let _canvasHeight = 1080;

			const { videoEdits } = this;

			let _videoWidth = this.assets.screenclip.naturalWidth;
			let _videoHeight = this.assets.screenclip.naturalHeight;
			let intro = videoEdits.intro;
			let outro = videoEdits.outro;

			if (covers?.intro) {
				intro = covers.intro;
			}
			if (covers?.outro) {
				outro = covers.outro;
			}

			const fixDimensions = (edit: any) => {
				_canvasWidth = edit?.naturalWidth || 1920;
				_canvasHeight = edit?.naturalHeight || 1080;
				const { width, height } = getModifiedWidthAndHeight(
					_canvasWidth,
					_canvasHeight,
					1920,
					1080,
				);
				_canvasWidth = width;
				_canvasHeight = height;
			};

			if (intro?.fixDimensions && intro.visible) {
				fixDimensions(intro);
			} else if (outro?.fixDimensions && outro.visible) {
				fixDimensions(outro);
			} else {
				if (videoEdits.crop) {
					_videoWidth = _videoWidth * videoEdits.crop.size[0];
					_videoHeight = _videoHeight * videoEdits.crop.size[1];
				}

				const paddingFactor = this.getPaddingFactor();

				if (_videoWidth && _videoHeight) {
					_canvasWidth = _videoWidth + paddingFactor * _videoWidth;
					_canvasHeight = _videoHeight + paddingFactor * _videoWidth;
				} else {
					_canvasWidth = (_videoWidth || 0) * (1 + paddingFactor);
					_canvasHeight =
						(_videoHeight || 0) +
						paddingFactor * (_videoWidth || 0);
				}
			}

			// if (
			// 	(intro?.fixDimensions && intro.visible) ||
			// 	(outro?.fixDimensions && outro.visible)
			// ) {
			// 	const desiredWidth = _canvasWidth;
			// 	const desiredHeight = _canvasHeight;
			// 	const desiredAspectRatio = desiredWidth / desiredHeight;
			// 	const actualAspectRatio = _canvasWidth / _canvasHeight;
			// 	if (desiredAspectRatio > actualAspectRatio) {
			// 		_canvasWidth = Math.max(
			// 			_videoWidth,
			// 			Math.min(desiredWidth, 1080),
			// 		);
			// 		_canvasHeight = _canvasWidth / desiredAspectRatio;
			// 	} else {
			// 		_canvasHeight = Math.max(
			// 			_videoHeight,
			// 			Math.min(desiredHeight, 1080),
			// 		);
			// 		_canvasWidth = _canvasHeight * desiredAspectRatio;
			// 	}
			// }
			// console.log("canvas dims", _canvasWidth, _canvasHeight);
			return {
				x: Math.floor(_canvasWidth) + (Math.floor(_canvasWidth) % 2),
				y: Math.floor(_canvasHeight) + (Math.floor(_canvasHeight) % 2),
			};
		};

	initVideoRenderDims: () => Coordinates = () => {
		let _w = 0;
		let _h = 0;

		const standardizeDims =
			this.videoEdits.intro?.fixDimensions ||
			this.videoEdits.outro?.fixDimensions;

		const paddingFactor = this.getPaddingFactor();

		let { naturalWidth, naturalHeight } = this.assets.screenclip;

		const crop = this.videoEdits.crop;

		if (crop) {
			naturalWidth *= crop.size[0];
			naturalHeight *= crop.size[1];
		}

		if (standardizeDims) {
			// First step: Recompute width and height for the video based on fixed dimensions

			//Now, given a paddingFactor, we know the 'ideal' size of video
			const idealWidth =
				this.canvasWidth - paddingFactor * this.canvasWidth;
			const idealHeight =
				this.canvasHeight - paddingFactor * this.canvasHeight;

			const idealAspectRatio = idealWidth / idealHeight;
			const actualAspectRatio = naturalWidth / naturalHeight;

			if (actualAspectRatio > idealAspectRatio) {
				_w = Math.floor(idealWidth);
				_h = Math.floor(idealWidth / actualAspectRatio);
			} else {
				_h = Math.floor(idealHeight);
				_w = Math.floor(idealHeight * actualAspectRatio);
			}

			_h += _h % 2;
			_w += _w % 2;
		} else {
			_w = naturalWidth;
			_h = naturalHeight;
		}

		return {
			x: _w,
			y: _h,
		};
	};

	//#endregion

	//#region COORDINATE SYSTEM CONVERTERS
	fractionalCoordsToCanvasCoords: (
		pos: Coordinates,
		wrtVideo?: boolean,
	) => Coordinates = (pos, wrtVideo) => {
		/**
		 * @param wrtVideo: if true, the fractional value is with respect to the video and not the full canvas
		 * @returns {x, y} with respect to the full canvas
		 */

		let w = this.canvasWidth;
		let h = this.canvasHeight;

		let pw = 0;
		let ph = 0;

		if (wrtVideo) {
			w = this.videoRenderWidth;
			h = this.videoRenderHeight;

			pw = (this.canvasWidth - this.videoRenderWidth) / 2;
			ph = (this.canvasHeight - this.videoRenderHeight) / 2;
		}

		const canvas_x = pw + w * pos.x;
		const canvas_y = ph + h * pos.y;

		return { x: canvas_x, y: canvas_y };
	};

	canvasCoordsToPixels: (
		pos_c: Coordinates,
		wrtVideo?: boolean,
	) => Coordinates = (pos_c, wrtVideo = false) => {
		/**
		 * @param pos_c: {x, y} with respect to the full canvas
		 * @param wrtVideo: if true, the returned fractional value is with respect to the video and not the full canvas
		 */

		const w = this.canvasWidth;
		const h = this.canvasHeight;

		let pw = 0;
		let ph = 0;

		if (!this.videoEdits.background?.visible) {
			pw = 0;
			ph = 0;
		}

		const pixels_x = pw + w * pos_c.x;
		const pixels_y = ph + h * pos_c.y;

		return { x: pixels_x, y: pixels_y };
	};

	canvasCoordsToFractionalCoords: (
		pos_c: Coordinates,
		wrtVideo?: boolean,
	) => Coordinates = (pos_c, wrtVideo = false) => {
		/**
		 * @param pos_c: {x, y} with respect to the full canvas
		 * @param wrtVideo: if true, the returned fractional value is with respect to the video and not the full canvas
		 */

		let w = this.canvasWidth;
		let h = this.canvasHeight;

		let pw = 0;
		let ph = 0;

		if (wrtVideo) {
			// if ignore offset is true, we are working with pure video file
			// which is taking the complete canvas
			w = this.videoRenderWidth;
			h = this.videoRenderHeight;

			pw = (this.canvasWidth - this.videoRenderWidth) / 2;
			ph = (this.canvasHeight - this.videoRenderHeight) / 2;
		} else {
			pw = 0;
			ph = 0;
		}

		const fractional_x = (pos_c.x - pw) / w;
		const fractional_y = (pos_c.y - ph) / h;

		return { x: fractional_x, y: fractional_y };
	};

	//#endregion
}
let canvasCoordinates: CanvasCoordinates = null as unknown as CanvasCoordinates;
if (typeof globalThis !== "undefined" && globalThis.window) {
	canvasCoordinates = new CanvasCoordinates(dummyVideoEdits, feCanvasAssets);
}

export default canvasCoordinates;
