import {
	Crop,
	VideoEdits,
} from "@giga-user-fern/api/types/api/resources/video";

import { ElementEdit } from "@giga-user-fern/api/types/api";
import { hexToRGBA } from "../../../../../utils/colorUtils";
import { DEFAULT_CROP, dummyVideoEdits } from "../../../videoEditTypes/core";
import { interpolate } from "../../../video_effects/interpolations";
import { isElementWrtVideo } from "../../canvasUtils";
import { RendererType } from "../../constants";
import { drawFilledRoundedRect } from "../../elements/canvasTextbox";
import { MutableElement } from "../../mutables/elements/MutableElement";
import { CanvasAssets } from "../assets/CanvasAssets";
import cc, { CanvasCoordinates } from "../coordinates/CanvasCoordinates";

type CanvasElementsProps = {
	canvas?: HTMLCanvasElement;
	tempCanvas?: HTMLCanvasElement;
	paused?: boolean;
};

//animation
const INTERPOLATION_METHOD = "easeOutCubic";
const ANIMATION_TIME = 0.3;
const TRANSITION_TYPE = "draw";

//formatting
export const PADDING_W = 30;
export const PADDING_H = 20;

export class CanvasElements {
	videoEdits: VideoEdits;
	props: CanvasElementsProps;
	assets: CanvasAssets;
	coordinates: CanvasCoordinates;
	rendererType: RendererType;
	constructor(
		videoEdits: VideoEdits,
		props: CanvasElementsProps,
		assets: CanvasAssets,
		coordinates: CanvasCoordinates,
		rendererType: RendererType,
	) {
		this.videoEdits = videoEdits;
		this.props = props;
		this.assets = assets;
		this.coordinates = coordinates;
		this.rendererType = rendererType;
	}

	renderElements(currentTime: number, transition = false) {
		const elementEditsToDraw: ElementEdit[] = [];
		const { videoEdits } = this;

		for (const ele of videoEdits.elements ?? []) {
			if (currentTime >= ele.startTime && currentTime <= ele.endTime) {
				elementEditsToDraw.push(ele);
			}
		}

		elementEditsToDraw.sort((a, b) => {
			if (a.geo === "blur" && b.geo !== "blur") {
				return -1;
			}
			if (a.geo !== "blur" && b.geo === "blur") {
				return 1;
			}
			if (a.geo === "text" && b.geo !== "text") {
				return 1;
			}
			if (a.geo !== "text" && b.geo === "text") {
				return -1;
			}
			return 0;
		});

		if (elementEditsToDraw.length) {
			for (const ele of elementEditsToDraw) {
				this.drawElement(ele, currentTime, transition);
			}
		}
	}

	private computeElementContainer(ele: ElementEdit): {
		rectWidth: number;
		rectHeight: number;
		rectX: number;
		rectY: number;
	} {
		const { position, size, geo } = ele;
		const [x, y] = position;
		const [width, height] = size;

		const { coordinates } = this;

		const wrtVideo = isElementWrtVideo(ele);

		const rect_coords = coordinates.fractionalCoordsToCanvasCoords(
			new MutableElement(ele).getPosition(),
			wrtVideo,
		);

		const rectX = rect_coords.x;
		const rectY = rect_coords.y;

		let rectWidth = 0;
		let rectHeight = 0;

		if (wrtVideo) {
			rectWidth = width * coordinates.videoRenderWidth; ///adj_zf
			rectHeight = height * coordinates.videoRenderHeight; // / adj_zf;
		} else {
			rectWidth = width * coordinates.canvasWidth; ///adj_zf
			rectHeight = height * coordinates.canvasHeight; // / adj_zf;
		}

		if (geo === "text") {
			rectWidth += PADDING_W;
			rectHeight += PADDING_H;
		}

		return {
			rectWidth,
			rectHeight,
			rectX,
			rectY,
		};
	}

	private drawElement(
		ele: ElementEdit,
		currentTime: number,
		transition = false,
	) {
		const canvas = this.props.canvas;
		if (!canvas) return;

		const ctx = canvas.getContext("2d") as CanvasRenderingContext2D;

		ctx.save();

		switch (ele.geo) {
			case "blur":
				this.drawBlur(ele, currentTime);
				break;
			case "rectangle":
				this.drawRectangle(ele, currentTime);
				break;
			case "text":
				this.drawText(ele, currentTime, transition);
				break;
			case "spotlight":
				this.drawSpotlight(ele, currentTime);
				break;
			case "callout":
				this.drawCallout(ele, currentTime);
				break;
			case "arrow":
				this.drawArrow(ele, currentTime);
				break;
		}

		ctx.restore();
	}

	private drawBlur(ele: ElementEdit, currentTime: number) {
		const { tempCanvas, canvas } = this.props;
		const { position, size, geo } = ele;

		const { videoEdits } = this;

		const [x, y] = position;
		const [width, height] = size;
		// These are the coordinates of the element in the video
		// With respect to rendered dimensions of the video.
		// That is, these video coordinates will also factor in the crop.

		const crop: Crop = videoEdits.crop || DEFAULT_CROP;

		// These are the coordinates of the rectangle with respect to the full canvas.

		const { rectX, rectY, rectWidth, rectHeight } =
			this.computeElementContainer(ele);

		if (!tempCanvas || !canvas) {
			console.error("canvas unavailable to render blur");
			return;
		}

		// We are going to create a temp canvas to draw the rectangle on.
		// We will then apply the blur filter to the temp canvas and draw it back onto the original canvas.
		const tempCtx = tempCanvas.getContext("2d");
		// Reset transformations on the temp canvas
		const ctx = canvas.getContext("2d");

		if (!tempCtx || !ctx) return;

		// The actual content to draw onto this temp canvas is going to be a rectangle from the base video
		// For that, it is being rendered at its natural width and height.

		// TODO: Instead of rendering at natural width and height, we can render at a reduced size
		// This size will later be renderWidth and renderHeight.
		const naturalWidth = this.assets.screenclip.naturalWidth;
		const naturalHeight = this.assets.screenclip.naturalHeight;

		// But first, we need to get the offset potentially by the crop in the video
		// Coordinates are all with respect to entire video
		// So, crop is factored externally.

		let sx = 0; // Source x - start of crop in video coordinates
		let sy = 0; // Source y - start of crop in video coordinates
		let sWidth = naturalWidth; // Source width - width of crop in video coordinates
		let sHeight = naturalHeight; // Source height - height of crop in video coordinates
		if (videoEdits.crop) {
			sx = crop.position[0] * naturalWidth; // Source x - start of crop in video coordinates
			sy = crop.position[1] * naturalHeight; // Source y - start of crop in video coordinates
			sWidth = crop.size[0] * naturalWidth; // Source width - width of crop in video coordinates
			sHeight = crop.size[1] * naturalHeight; // Source height - height of crop in video coordinates
		}

		// Now the part of the frame we're interested in drawing is determined by these widths and heights
		const frameRectWidth = width * sWidth;
		const frameRectHeight = height * sHeight;

		// Adding the crop offset to the x and y coordinates to account for the crop
		// Since the frame is being drawn from the base video, the crop offset is added to the x and y coordinates
		const frameRectX = sx + x * sWidth;
		const frameRectY = sy + y * sHeight;

		// Set the dimensions of the temp canvas to the size of the rectangle

		tempCanvas.width = rectWidth;
		tempCanvas.height = rectHeight;

		// draw some colour onto the temp canvas
		// tempCtx.fillStyle = "red";
		// tempCtx.fillRect(0, 0, tempCanvas.width, tempCanvas.height);

		// // Now, we factor in the blur radius
		// The blur radius needs to be scaled according to the size of the base video
		// This is because the visual effect of a blur with radius 20px on a video that is say 1600x900 px
		// is different from the same blur on a video that is say 800x450 px
		// To scale it, we can just assume some constant factor and multiply by the size of the video
		// THe scale should be with square root of the area of the video, and not area because then it
		// will be too much for larger videos
		//TODO: Make this a variable that can be set by the user
		const blurRadius =
			0.03 *
			Math.sqrt(naturalWidth * naturalHeight) *
			(this.rendererType === "skia" ? 2 : 1);

		// Ideally, we want to shift the top left by (blurRadius, blurRadius)
		// But, we may not be able to do that because the rectangle may be too close to the edge of the video
		// So the actual shift will be the minimum of the two values
		const leftBlurRadius = Math.min(blurRadius, frameRectX);
		const topBlurRadius = Math.min(blurRadius, frameRectY);

		const rightBlurRadius = Math.min(
			blurRadius,
			naturalWidth - (frameRectWidth + frameRectX),
		);
		const bottomBlurRadius = Math.min(
			blurRadius,
			naturalHeight - (frameRectHeight + frameRectY),
		);

		// We need to scale the blur radius to account for the fact that the rectangle is being drawn at a different size
		// This is because the blur radius is being added to the rectangle that's scaled according to video,
		// not the part in the video canvas
		// So we cannot add directly the blur radius - we need to scale it according to the
		// size of the rectangle in the video canvas

		const scaleFactor = rectWidth / frameRectWidth;

		tempCanvas.width =
			rectWidth +
			leftBlurRadius * scaleFactor +
			rightBlurRadius * scaleFactor;
		tempCanvas.height =
			rectHeight +
			topBlurRadius * scaleFactor +
			bottomBlurRadius * scaleFactor;

		tempCtx.filter = `blur(${(blurRadius / 3) * scaleFactor}px)`; // adjust the blur radius as needed
		tempCtx.drawImage(
			this.assets.screenclip.frame(currentTime), //use 'canvas' in case  of rendering full
			// this.assets.screenclip.frame(currentTime),
			frameRectX - leftBlurRadius,
			frameRectY - topBlurRadius,
			frameRectWidth + leftBlurRadius + rightBlurRadius,
			frameRectHeight + topBlurRadius + bottomBlurRadius,
			0,
			0,
			tempCanvas.width,
			tempCanvas.height,
		);

		// // Apply the blur filter
		// tempCtx.drawImage(tempCanvas, 0, 0);

		// Now to draw the blurred rectangle back onto the original canvas
		// We are drawing the blurred rectangle at the same position as the original rectangle
		// But with the blur radius added to the x and y coordinates to account for the blur effect
		ctx.drawImage(
			tempCanvas,
			leftBlurRadius * scaleFactor,
			topBlurRadius * scaleFactor,
			rectWidth,
			rectHeight,
			rectX,
			rectY,
			rectWidth,
			rectHeight,
		);
	}

	private drawRectangle(ele: ElementEdit, currentTime: number) {
		let stroke_width = 0;
		let stroke_color = "transparent";
		let fill_color = "transparent";
		let borderRadius = 0;
		let backgroundOpacity = 1;
		const strokeOpacity = 1;
		let drawTime = ANIMATION_TIME;
		let transitionTime = ANIMATION_TIME;
		let transitionType = TRANSITION_TYPE;
		let strokeProgress = 0;
		const isPaused = this.props?.paused;

		const { canvas } = this.props;
		const ctx = canvas?.getContext("2d");
		if (!ctx) return;

		const { rectWidth, rectHeight, rectX, rectY } =
			this.computeElementContainer(ele);

		if (ele.shapedata) {
			stroke_width = ele.shapedata.strokeWidth || 0;
			stroke_color = ele.shapedata.strokeColor || "transparent";
			fill_color = ele.shapedata.backgroundColor || "transparent";
			backgroundOpacity =
				ele.shapedata.backgroundOpacity === -0.01
					? 0
					: (ele.shapedata.backgroundOpacity ?? 100) / 100;
			borderRadius = Math.max(ele.shapedata.borderRadius || 0, 0);
			drawTime = ele.shapedata.drawTime || 0;
			transitionTime = ele.shapedata.transitionTime || 0;
			transitionType = ele.shapedata.transitionType || TRANSITION_TYPE;
		}

		const t = currentTime;

		// Compute the opacity only for fade transition
		const maxOpacity = (ele.shapedata?.backgroundOpacity ?? 100) / 100;
		if (transitionType === "fade") {
			if (t > ele.startTime && t <= ele.startTime + transitionTime) {
				const start = { y: 0, x: ele.startTime };
				const end = {
					y: maxOpacity,
					x: ele.startTime + transitionTime,
				};
				backgroundOpacity = isPaused
					? maxOpacity
					: interpolate(start, end, t, INTERPOLATION_METHOD);
			} else if (t > ele.endTime - transitionTime) {
				const start = {
					y: maxOpacity,
					x: ele.endTime - transitionTime,
				};
				const end = { y: 0, x: ele.endTime };
				backgroundOpacity = isPaused
					? maxOpacity
					: interpolate(start, end, t, INTERPOLATION_METHOD);
			}
		} // Compute stroke progress only for draw transition

		if (transitionType === "draw") {
			if (t > ele.startTime && t <= ele.startTime + drawTime) {
				const start = { y: 0, x: ele.startTime };
				const end = { y: 1, x: ele.startTime + drawTime };
				strokeProgress = interpolate(
					start,
					end,
					t,
					INTERPOLATION_METHOD,
				);
			} else if (t > ele.startTime + drawTime) {
				strokeProgress = 1;
			}
		}

		ctx.save();

		// Begin path for rounded rectangle
		ctx.beginPath();
		ctx.roundRect(
			rectX,
			rectY,
			rectWidth,
			rectHeight,
			(borderRadius * Math.min(rectWidth, rectHeight)) / 100,
		);

		// Fill rectangle with opacity
		ctx.globalAlpha = backgroundOpacity;
		ctx.fillStyle = fill_color;
		ctx.fill();

		// Reset globalAlpha for stroke
		ctx.globalAlpha = 1;

		// Stroke rectangle with animation
		if (stroke_width > 0) {
			ctx.strokeStyle = stroke_color;
			ctx.lineWidth = stroke_width;
			ctx.lineCap = "round";

			if (transitionType === "draw") {
				// Calculate perimeter and border radius
				const perimeter = 2 * (rectWidth + rectHeight);
				let progressLength = 0;

				// Calculate progress length based on whether we're in entrance or exit animation
				if (t > ele.startTime && t <= ele.startTime + drawTime) {
					// Entrance animation
					const start = { y: 0, x: ele.startTime };
					const end = { y: 1, x: ele.startTime + drawTime };
					progressLength =
						perimeter *
						interpolate(start, end, t, INTERPOLATION_METHOD);
					if (isPaused) {
						progressLength = Math.max(progressLength, perimeter);
					}
				} else if (t > ele.endTime - drawTime && t <= ele.endTime) {
					// Exit animation
					const start = { y: 1, x: ele.endTime - drawTime };
					const end = { y: 0, x: ele.endTime };
					progressLength =
						perimeter *
						interpolate(start, end, t, INTERPOLATION_METHOD);
					if (isPaused) {
						progressLength = Math.max(progressLength, perimeter);
					}
				} else if (
					t > ele.startTime + drawTime &&
					t <= ele.endTime - drawTime
				) {
					// Fully drawn
					progressLength = perimeter;
				} else {
					// Not visible
					progressLength = 0;
				}

				const radius =
					(borderRadius * Math.min(rectWidth, rectHeight)) / 100;
				ctx.globalAlpha = 1;

				ctx.beginPath();
				let remainingLength = progressLength;

				// Start from top-left corner + radius
				ctx.moveTo(rectX + radius, rectY);

				// Draw top line
				if (remainingLength > 0) {
					const topLength = Math.min(
						rectWidth - 2 * radius,
						remainingLength,
					);
					ctx.lineTo(rectX + topLength + radius, rectY);
					remainingLength -= topLength;

					// Draw top-right corner arc
					if (remainingLength > 0) {
						const arcLength = (Math.PI * radius) / 2;
						const arcProgress = Math.min(
							arcLength,
							remainingLength,
						);
						ctx.arc(
							rectX + rectWidth - radius,
							rectY + radius,
							radius,
							-Math.PI / 2,
							-Math.PI / 2 + arcProgress / radius,
							false,
						);
						remainingLength -= arcProgress;
					}
				}

				// Draw right line
				if (remainingLength > 0) {
					const rightLength = Math.min(
						rectHeight - 2 * radius,
						remainingLength,
					);
					ctx.lineTo(rectX + rectWidth, rectY + rightLength + radius);
					remainingLength -= rightLength;

					// Draw bottom-right corner arc
					if (remainingLength > 0) {
						const arcLength = (Math.PI * radius) / 2;
						const arcProgress = Math.min(
							arcLength,
							remainingLength,
						);
						ctx.arc(
							rectX + rectWidth - radius,
							rectY + rectHeight - radius,
							radius,
							0,
							arcProgress / radius,
							false,
						);
						remainingLength -= arcProgress;
					}
				}

				// Draw bottom line
				if (remainingLength > 0) {
					const bottomLength = Math.min(
						rectWidth - 2 * radius,
						remainingLength,
					);
					ctx.lineTo(
						rectX + rectWidth - bottomLength - radius,
						rectY + rectHeight,
					);
					remainingLength -= bottomLength;

					// Draw bottom-left corner arc
					if (remainingLength > 0) {
						const arcLength = (Math.PI * radius) / 2;
						const arcProgress = Math.min(
							arcLength,
							remainingLength,
						);
						ctx.arc(
							rectX + radius,
							rectY + rectHeight - radius,
							radius,
							Math.PI / 2,
							Math.PI / 2 + arcProgress / radius,
							false,
						);
						remainingLength -= arcProgress;
					}
				}

				// Draw left line
				if (remainingLength > 0) {
					const leftLength = Math.min(
						rectHeight - 2 * radius,
						remainingLength,
					);
					ctx.lineTo(rectX, rectY + rectHeight - leftLength - radius);
					remainingLength -= leftLength;

					// Draw top-left corner arc
					if (remainingLength > 0) {
						const arcLength = (Math.PI * radius) / 2;
						const arcProgress = Math.min(
							arcLength,
							remainingLength,
						);
						ctx.arc(
							rectX + radius,
							rectY + radius,
							radius,
							Math.PI,
							Math.PI + arcProgress / radius,
							false,
						);
					}
				}
				ctx.stroke();
			} else {
				// For fade transition, draw complete stroke
				ctx.stroke();
			}
		}

		ctx.restore();
	}

	private drawCallout(ele: ElementEdit, currentTime: number) {
		const { canvas, tempCanvas } = this.props;
		if (!canvas || !tempCanvas) return;

		const ctx = canvas.getContext("2d") as CanvasRenderingContext2D;
		const tempCtx = tempCanvas.getContext("2d");

		if (!ctx || !tempCtx) return;

		const { position, size, shapedata, startTime, endTime } = ele;

		if (!shapedata) {
			return;
		}

		const {
			backgroundColor,
			backgroundOpacity = 0,
			borderRadius = 1,
			transitionTime = 0,
			scaleFactor = 1.5,
			strokeWidth = 2,
			strokeColor = "white",
			drawTime = 1,
		} = shapedata;

		// make sure borderRadius is not negative
		const borderRadiusNew = Math.max(borderRadius || 0, 0);

		const maxScale = scaleFactor;
		const scaleRange = maxScale - 1;

		const [x, y] = position;
		const [width, height] = size;

		const rect_coords = this.coordinates.fractionalCoordsToCanvasCoords(
			{ x, y },
			true,
		);

		const rectX = rect_coords.x;
		const rectY = rect_coords.y;

		const rectWidth = width * this.coordinates.videoRenderWidth;
		const rectHeight = height * this.coordinates.videoRenderHeight;

		// Set the dimensions of the temp canvas to the size of the entire canvas
		tempCanvas.width = canvas.width;
		tempCanvas.height = canvas.height;

		// Calculate the current opacity, scale, and stroke progress based on time
		const elapsedTime = currentTime - startTime;
		const totalDuration = endTime - startTime;

		let currentOpacity: number;
		let currentScale: number;
		let strokeProgress: number;
		let strokeOpacity: number;

		const isPaused = this.props?.paused;

		// Add a 0.5-second delay between drawTime and transitionTime
		const delayTime = 0.5;

		if (isPaused) {
			// When paused, set stroke to full and scale to 1
			strokeProgress = 1;
			currentScale = 1;
			currentOpacity = backgroundOpacity / 100;
			strokeOpacity = 1; // Full opacity for stroke when paused
		} else {
			// Compute the opacity only for transition
			if (elapsedTime < drawTime) {
				// Stroke drawing phase
				strokeProgress = elapsedTime / drawTime;
				currentOpacity = 0;
				currentScale = 1;
				strokeOpacity = strokeProgress; //Added stroke opacity
			} else if (elapsedTime < drawTime + delayTime) {
				// Delay phase
				strokeProgress = 1;
				currentOpacity = 0;
				currentScale = 1;
				strokeOpacity = 1;
			} else if (elapsedTime < drawTime + delayTime + transitionTime) {
				// Scaling phase
				strokeProgress = 1;
				const scaleProgress =
					(elapsedTime - drawTime - delayTime) / transitionTime;
				currentOpacity = scaleProgress * (backgroundOpacity / 100);
				currentScale = 1 + scaleRange * scaleProgress;
				strokeOpacity = 1;
			} else if (elapsedTime > totalDuration - transitionTime) {
				// Exit animation
				strokeProgress = 1;
				const exitProgress =
					(totalDuration - elapsedTime) / transitionTime;
				currentOpacity = exitProgress * (backgroundOpacity / 100);
				currentScale = 1 + scaleRange * exitProgress;
				strokeOpacity = exitProgress; // Fade out the stroke
			} else {
				// Fully visible
				strokeProgress = 1;
				currentOpacity = backgroundOpacity / 100;
				currentScale = maxScale;
				strokeOpacity = 1; // Full opacity for stroke
			}
		}

		currentOpacity = Math.max(
			0,
			Math.min(currentOpacity, backgroundOpacity / 100),
		);

		// Create a dark overlay with transition
		tempCtx.fillStyle = `${backgroundColor}`;
		tempCtx.globalAlpha = currentOpacity;
		tempCtx.fillRect(0, 0, tempCanvas.width, tempCanvas.height);

		// Calculate scaled dimensions and position
		const scaledWidth = rectWidth * currentScale;
		const scaledHeight = rectHeight * currentScale;
		const scaledX = rectX - (scaledWidth - rectWidth) / 2;
		const scaledY = rectY - (scaledHeight - rectHeight) / 2;

		// Draw stroke with animation
		tempCtx.strokeStyle = strokeColor;
		tempCtx.lineWidth = strokeWidth;
		tempCtx.lineCap = "round";
		tempCtx.globalAlpha = strokeOpacity;

		const radius =
			(borderRadiusNew / 100) * Math.min(scaledWidth, scaledHeight);
		const perimeter = 2 * (scaledWidth + scaledHeight);
		const progressLength = perimeter * strokeProgress;

		tempCtx.beginPath();

		// Draw stroke with animation
		this.drawRoundedRectPath(
			tempCtx,
			scaledX,
			scaledY,
			scaledWidth,
			scaledHeight,
			radius,
			progressLength,
		);

		tempCtx.stroke();

		// Reset composite operation and global alpha
		tempCtx.globalCompositeOperation = "source-over";
		tempCtx.globalAlpha = 1;

		// Create a clipping path for the content
		tempCtx.save();
		tempCtx.beginPath();
		this.drawRoundedRectPath(
			tempCtx,
			scaledX,
			scaledY,
			scaledWidth,
			scaledHeight,
			radius,
			perimeter, // Use full perimeter to draw complete shape
		);
		tempCtx.clip();

		// Draw the scaled content
		tempCtx.drawImage(
			canvas,
			rectX,
			rectY,
			rectWidth,
			rectHeight,
			scaledX,
			scaledY,
			scaledWidth,
			scaledHeight,
		);

		tempCtx.restore();

		// Draw the temp canvas (with dark overlay, cutout, and scaled content) onto the main canvas
		ctx.drawImage(tempCanvas, 0, 0);
	}

	private drawRoundedRectPath(
		ctx: CanvasRenderingContext2D,
		x: number,
		y: number,
		width: number,
		height: number,
		radius: number,
		progressLength: number,
	) {
		let remainingLength = progressLength;

		// Start from top-left corner
		ctx.moveTo(x + radius, y);

		// Top side
		if (remainingLength > 0) {
			const sideLength = Math.min(width - 2 * radius, remainingLength);
			ctx.lineTo(x + sideLength + radius, y);
			remainingLength -= sideLength;

			// Top-right corner
			if (remainingLength > 0) {
				const cornerLength = Math.min(
					(radius * Math.PI) / 2,
					remainingLength,
				);
				ctx.arc(
					x + width - radius,
					y + radius,
					radius,
					-Math.PI / 2,
					-Math.PI / 2 + cornerLength / radius,
					false,
				);
				remainingLength -= cornerLength;
			}
		}

		// Right side
		if (remainingLength > 0) {
			const sideLength = Math.min(height - 2 * radius, remainingLength);
			ctx.lineTo(x + width, y + sideLength + radius);
			remainingLength -= sideLength;

			// Bottom-right corner
			if (remainingLength > 0) {
				const cornerLength = Math.min(
					(radius * Math.PI) / 2,
					remainingLength,
				);
				ctx.arc(
					x + width - radius,
					y + height - radius,
					radius,
					0,
					cornerLength / radius,
					false,
				);
				remainingLength -= cornerLength;
			}
		}

		// Bottom side
		if (remainingLength > 0) {
			const sideLength = Math.min(width - 2 * radius, remainingLength);
			ctx.lineTo(x + width - sideLength - radius, y + height);
			remainingLength -= sideLength;

			// Bottom-left corner
			if (remainingLength > 0) {
				const cornerLength = Math.min(
					(radius * Math.PI) / 2,
					remainingLength,
				);
				ctx.arc(
					x + radius,
					y + height - radius,
					radius,
					Math.PI / 2,
					Math.PI / 2 + cornerLength / radius,
					false,
				);
				remainingLength -= cornerLength;
			}
		}

		// Left side
		if (remainingLength > 0) {
			const sideLength = Math.min(height - 2 * radius, remainingLength);
			ctx.lineTo(x, y + height - sideLength - radius);
			remainingLength -= sideLength;

			// Top-left corner
			if (remainingLength > 0) {
				const cornerLength = Math.min(
					(radius * Math.PI) / 2,
					remainingLength,
				);
				ctx.arc(
					x + radius,
					y + radius,
					radius,
					Math.PI,
					Math.PI + cornerLength / radius,
					false,
				);
			}
		}
	}

	private drawSpotlight(ele: ElementEdit, currentTime: number) {
		const { tempCanvas, canvas } = this.props;
		const { position, size, shapedata, startTime, endTime } = ele;
		const isPaused = this.props.paused;

		if (!shapedata) {
			console.log("Shapedata unavailable to render");
			return;
		}

		const {
			backgroundColor,
			backgroundOpacity = 0,
			borderRadius = 1,
			transitionTime = 0,
		} = shapedata;

		const [x, y] = position;
		const [width, height] = size;

		const rect_coords = this.coordinates.fractionalCoordsToCanvasCoords(
			{ x, y },
			true,
		);

		const rectX = rect_coords.x;
		const rectY = rect_coords.y;

		const rectWidth = width * this.coordinates.videoRenderWidth;
		const rectHeight = height * this.coordinates.videoRenderHeight;

		if (!tempCanvas || !canvas) {
			console.error("Canvas unavailable to render spotlight");
			return;
		}

		const tempCtx = tempCanvas.getContext("2d");
		const ctx = canvas.getContext("2d");

		if (!tempCtx || !ctx) return;

		// Set the dimensions of the temp canvas to the size of the entire canvas
		tempCanvas.width = canvas.width;
		tempCanvas.height = canvas.height;

		// Calculate the current opacity based on transition time for both entry and exit
		const elapsedTime = currentTime - (startTime || 0);
		const totalDuration = (endTime || 0) - (startTime || 0);
		const exitStartTime = totalDuration - transitionTime;

		let currentOpacity: number;

		if (isPaused) {
			currentOpacity = backgroundOpacity / 100;
		} else {
			if (elapsedTime < transitionTime) {
				// Entry animation
				currentOpacity =
					(elapsedTime / transitionTime) * (backgroundOpacity / 100);
			} else if (elapsedTime > exitStartTime) {
				// Exit animation
				currentOpacity =
					((totalDuration - elapsedTime) / transitionTime) *
					(backgroundOpacity / 100);
			} else {
				// Fully visible
				currentOpacity = backgroundOpacity / 100;
			}

			currentOpacity = Math.max(
				0,
				Math.min(currentOpacity, backgroundOpacity / 100),
			);
		}

		// Create a dark overlay with transition
		tempCtx.fillStyle = `${backgroundColor}`;
		tempCtx.globalAlpha = 1;
		ctx.globalAlpha = currentOpacity;
		tempCtx.fillRect(0, 0, tempCanvas.width, tempCanvas.height);

		// Clear the spotlight area with border radius using drawRoundedRectPath
		tempCtx.globalCompositeOperation = "destination-out";
		tempCtx.fillStyle = "rgba(0, 0, 0, 1)";
		tempCtx.beginPath();
		const computedRadius =
			(borderRadius * Math.min(rectWidth, rectHeight)) / 100;
		const perimeter = 2 * (rectWidth + rectHeight);
		this.drawRoundedRectPath(
			tempCtx,
			rectX,
			rectY,
			rectWidth,
			rectHeight,
			computedRadius,
			perimeter,
		);
		tempCtx.closePath();
		tempCtx.fill();

		// Reset composite operation and global alpha
		tempCtx.globalCompositeOperation = "source-over";
		tempCtx.globalAlpha = 1;

		// Draw only the dark overlay with the spotlight cutout onto the main canvas
		ctx.drawImage(tempCanvas, 0, 0);
	}

	private drawLine(
		context: CanvasRenderingContext2D,
		fromx: number,
		fromy: number,
		tox: number,
		toy: number,
		offsets: [number, number, number][],
		strokeColor: string,
	) {
		// draw the line with the offsets to have a natural stroke effect
		for (const offset of offsets) {
			const [offsetX, offsetY, lineWidth] = offset;

			context.beginPath();
			context.moveTo(fromx + offsetX, fromy + offsetY);
			context.lineTo(tox + offsetX, toy + offsetY);

			context.strokeStyle = strokeColor;
			context.lineWidth = lineWidth;
			context.stroke();
		}
	}

	private generateOffsets(
		points: number,
		radius: number,
	): [number, number, number][] {
		const offsets: [number, number, number][] = [];

		// generate the offsets for the line to be drawn
		for (let i = 0; i < points; i++) {
			offsets.push([
				(Math.random() * (-1) ** i * radius) / 1.2,
				(Math.random() * (-1) ** i * radius) / 1.2,
				radius,
			]);
		}
		return offsets;
	}

	private makeArrowOnCanvas = (
		context: CanvasRenderingContext2D,
		fromx: number,
		fromy: number,
		tox: number,
		toy: number,
		width = 8,
		strokeColor = "black",
	) => {
		const points = 500;
		const radius = width;

		// set context stroke style and round
		context.strokeStyle = strokeColor;
		context.lineCap = "round";
		context.lineJoin = "round";

		const offsets = this.generateOffsets(points, radius);

		// Draw arrow body
		this.drawLine(context, fromx, fromy, tox, toy, offsets, strokeColor);

		// Calculate arrow head
		const angle = Math.atan2(toy - fromy, tox - fromx);
		const headlen = width * 10;

		// Draw arrow head with brush stroke effect
		const headPoints = 450;
		const headOffsets = this.generateOffsets(headPoints, width);

		// calculate the left and right positions of the arrow head (arrow head will be drawn at the end of the line and in two directions)
		// first direction of the arrow head
		const leftX = tox - headlen * Math.cos(angle - Math.PI / 6);
		const leftY = toy - headlen * Math.sin(angle - Math.PI / 6);

		// second direction of the arrow head
		const rightX = tox - headlen * Math.cos(angle + Math.PI / 6);
		const rightY = toy - headlen * Math.sin(angle + Math.PI / 6);

		// draw the arrow head in two directions
		this.drawLine(
			context,
			tox,
			toy,
			leftX,
			leftY,
			headOffsets,
			strokeColor,
		);
		this.drawLine(
			context,
			tox,
			toy,
			rightX,
			rightY,
			headOffsets,
			strokeColor,
		);
	};

	private drawArrow(ele: ElementEdit, currentTime: number) {
		const { canvas } = this.props;
		const paused = this.props.paused;
		if (!canvas) return;

		const ctx = canvas.getContext("2d");
		if (!ctx) return;

		const { position, size, shapedata } = ele;
		if (!shapedata) return;

		const {
			strokeWidth = 8,
			strokeColor = "#d43f8c",
			drawTime = 0.5,
		} = shapedata;

		const { rectWidth, rectHeight, rectX, rectY } =
			this.computeElementContainer(ele);

		const t = currentTime;

		ctx.save();

		// Calculate animation progress
		let progress = 1;

		if (paused) {
			progress = 1;
		} else if (t > ele.startTime && t <= ele.startTime + drawTime) {
			progress = (t - ele.startTime) / drawTime;
		} else if (t > ele.endTime - drawTime && t <= ele.endTime) {
			progress = (ele.endTime - t) / drawTime;
		}

		// Draw the animated arrow with ensured non-zero progress
		if (progress > 0.2) {
			const currentX = rectX + rectWidth * progress;
			const currentY = rectY + rectHeight * progress;

			this.makeArrowOnCanvas(
				ctx,
				rectX,
				rectY,
				currentX,
				currentY,
				strokeWidth,
				strokeColor,
			);
		}

		ctx.restore();
	}

	private drawText(
		ele: ElementEdit,
		currentTime: number,
		transition = false,
	) {
		const { canvas } = this.props;
		const ctx = canvas?.getContext("2d");
		if (!ctx) return;

		let opacity = 1;
		let fillStyle: string;

		const { rectWidth, rectHeight, rectX, rectY } =
			this.computeElementContainer(ele);

		const x = rectX;
		const y = rectY;
		const w = rectWidth;
		const h = rectHeight;

		const { textdata } = ele;

		if (!textdata) return;

		//#region transition animation
		if (transition) {
			const t = currentTime;

			//compute the opacity
			if (t > ele.startTime && t <= ele.startTime + ANIMATION_TIME) {
				const start = { y: 0, x: ele.startTime };
				const end = { y: 1, x: ele.startTime + ANIMATION_TIME };

				opacity = interpolate(start, end, t, INTERPOLATION_METHOD);
			} else if (t > ele.endTime - ANIMATION_TIME) {
				const start = { y: 1, x: ele.endTime - ANIMATION_TIME };
				const end = { y: 0, x: ele.endTime };

				opacity = interpolate(start, end, t, INTERPOLATION_METHOD);
			}
		}
		//#endregion

		//#region draw the text

		const fontSize = textdata.fontSize * this.coordinates.canvasHeight;
		const fontFamily = textdata.font;
		const textAlign = textdata.alignment;
		const lines = textdata.lines || [""];
		const color = textdata.textColor || "#000000";
		let lineHeight = fontSize * 1.2;
		if (textdata.lineHeight) {
			lineHeight = textdata.lineHeight * fontSize;
		}

		const fontName = this.assets.getFont(fontFamily);

		ctx.font = `${fontSize}px/${fontSize}px ${fontName}`;
		// ctx.font = "normal 400 400px/400px Arizonia";
		// console.log("setting font", `${fontSize}px ${fontName}`);
		// console.log("ctx.font", ctx.font);
		ctx.fillStyle = hexToRGBA(color, opacity * 100);
		ctx.textBaseline = "top";

		const paddedY = y + PADDING_H;
		const startY = paddedY;
		ctx.textAlign = textAlign;

		// Determine horizontal position based on text alignment
		let posX: number;
		switch (textAlign) {
			case "center":
				posX = x + w / 2; // Center of the rectangle for center alignment
				break;
			case "right":
				posX = x + w - PADDING_W; // Adjust for padding for right alignment
				break;
			case "left":
				posX = x + PADDING_W; // Adjust for padding for left alignment
				break;
		}

		//#region draw the background rectangle
		if (textdata.backgroundColor) {
			ctx.save();

			let bgOpacity = textdata.backgroundOpacity || 100;
			if (textdata.backgroundOpacity === 0) bgOpacity = 0;

			fillStyle = hexToRGBA(
				textdata.backgroundColor,
				opacity * bgOpacity,
			);

			ctx.fillStyle = fillStyle;
			drawFilledRoundedRect(ctx, x, y, w, h, h * 0.0, fillStyle);
			ctx.fill();
			ctx.restore();
		}
		//#endregion

		// Draw each line of text according to the specified alignment
		lines.forEach((text, i) => {
			// ctx.fillText(line, posX, startY + i * lineHeight);
			const metrics = ctx.measureText(text);
			const x = posX;
			const y =
				startY +
				i * lineHeight -
				// 0.1 * fontSize -
				0;
			ctx.fillText(text, x, y);
			// console.log("=== Debugging Text Rendering ===");

			// Log current font and textBaseline
			// console.log("Font and Baseline:", {
			// 	font: ctx.font,
			// 	textBaseline: ctx.textBaseline,
			// 	textAlign: ctx.textAlign,
			// });

			// // Measure text metrics
			// console.log("Text Metrics:", metrics);
			// // @ts-ignore
			// console.log("text wrap", ctx.textWrap);

			// // Draw text and debugging lines
			// ctx.strokeStyle = "blue"; // Baseline
			// ctx.beginPath();
			// ctx.moveTo(0, y);
			// ctx.lineTo(canvas?.width ?? 0, y);
			// ctx.stroke();

			// ctx.strokeStyle = "red"; // Ascent
			// ctx.beginPath();
			// ctx.moveTo(0, y - metrics.actualBoundingBoxAscent);
			// ctx.lineTo(canvas?.width ?? 0, y - metrics.actualBoundingBoxAscent);
			// ctx.stroke();

			// ctx.strokeStyle = "green"; // Descent
			// ctx.beginPath();
			// ctx.moveTo(0, y + metrics.actualBoundingBoxDescent);
			// ctx.lineTo(
			// 	canvas?.width ?? 0,
			// 	y + metrics.actualBoundingBoxDescent,
			// );
			// ctx.stroke();
		});
		//#endregion
	}
}

export default new CanvasElements(
	dummyVideoEdits,
	{},
	null as unknown as CanvasAssets,
	cc,
	"browser",
);
