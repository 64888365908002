import { VideoEdits } from "@giga-user-fern/api/types/api/resources/video";
import { dummyVideoEdits } from "../../videoEditTypes/core";
import { RendererType } from "../constants";
import { CanvasAssets } from "./assets/CanvasAssets";
import feCanvasAssets from "./assets/FeCanvasAssets";
import { CanvasCoordinates } from "./coordinates/CanvasCoordinates";
import {
	CanvasFramer,
	CanvasFramerProps,
	dummyFramerProps,
} from "./framer/CanvasFramer";
import {
	CanvasTimer,
	CanvasTimerProps,
	dummyTimerProps,
} from "./timer/CanvasTimer";

export class CanvasRenderer {
	/**
	 * This class is used to manage the entire canvas rendering process.
	 */

	videoEdits: VideoEdits;
	timer: CanvasTimer;
	framer: CanvasFramer;
	coordinates: CanvasCoordinates;
	assets: CanvasAssets;
	rendererType: RendererType;

	constructor(
		videoEdits: VideoEdits,
		timerProps: CanvasTimerProps,
		framerProps: CanvasFramerProps,
		assets: CanvasAssets,
		rendererType: RendererType,
	) {
		this.videoEdits = videoEdits;
		this.assets = assets;
		this.coordinates = new CanvasCoordinates(videoEdits, this.assets);
		this.timer = new CanvasTimer(videoEdits, timerProps);
		this.framer = new CanvasFramer(
			videoEdits,
			framerProps,
			this.timer,
			this.assets,
			this.coordinates,
			rendererType,
		);
		this.rendererType = rendererType;
	}
}

let canvasRenderer = null as unknown as CanvasRenderer;
if (typeof globalThis !== "undefined" && globalThis.window) {
	canvasRenderer = new CanvasRenderer(
		dummyVideoEdits,
		dummyTimerProps,
		dummyFramerProps,
		feCanvasAssets,
		"browser",
	);
}
export default canvasRenderer;
