import { Coordinates } from "@giga-user-fern/api/types/api";
import {
	VideoEdits,
	ZoomEdit,
} from "@giga-user-fern/api/types/api/resources/video";
import { VideoClip } from "../../../../types/guide";
import { interpolate } from "../../../video_effects/interpolations";
import { drawIntro, printIntroClipTitle } from "../../canvasIntro";
import { drawOutro } from "../../canvasOutro";
import { drawBoxShadow, drawRoundedRect } from "../../canvasUtils";
import { RendererType, ZOOM_TRANSITION_TIME } from "../../constants";
import { CanvasAssets } from "../assets/CanvasAssets";
import { CanvasCoordinates } from "../coordinates/CanvasCoordinates";
import { CanvasTimer } from "../timer/CanvasTimer";
import { CanvasElements } from "./CanvasElements";

export type CanvasFramerProps = {
	canvas?: HTMLCanvasElement;
	clips?: VideoClip[];
	tempCanvas?: HTMLCanvasElement;
	paused?: boolean;
};

type RenderResult = {
	zoom?: ZoomRender | null;
};

type ZoomRender = {
	zoomFactor: number;
	zoomCenter: Coordinates;
	e?: number;
	f?: number;
};

const INTERPOLATION_METHOD = "easeOutQuart";

export const dummyFramerProps: CanvasFramerProps = {};

export class CanvasFramer {
	/**
	 * This class is used to orchestrate rendering frames of the canvas
	 * Primarily, it will be used to render a canvas frame based on a passed value of current time.
	 */

	videoEdits: VideoEdits;
	props: CanvasFramerProps;
	timer: CanvasTimer;
	assets: CanvasAssets;
	elements: CanvasElements;
	coordinates: CanvasCoordinates;
	rendererType: RendererType;
	//more assets
	sortedZooms: ZoomEdit[];

	constructor(
		videoEdits: VideoEdits,
		props: CanvasFramerProps,
		timer: CanvasTimer,
		assets: CanvasAssets,
		coordinates: CanvasCoordinates,
		rendererType: RendererType,
	) {
		this.videoEdits = videoEdits;
		this.props = { ...props };
		this.timer = timer;
		this.assets = assets;
		this.coordinates = coordinates;
		this.rendererType = rendererType;
		//more assets
		if (this.videoEdits.zooms) {
			this.sortedZooms = Array.from(this.videoEdits.zooms).sort(
				(a, b) => a.startTime - b.startTime,
			);
		} else {
			this.sortedZooms = [];
		}

		//framer helpers
		this.elements = new CanvasElements(
			videoEdits,
			{
				canvas: props.canvas,
				tempCanvas: props.tempCanvas,
				paused: props.paused,
			},
			assets,
			coordinates,
			rendererType,
		);
	}

	initialize = () => {};

	setDimensions = () => {};

	//#region CHARACTER functions

	hasIntroClip = () => {
		return (
			this.videoEdits?.intro?.visible &&
			this.videoEdits.intro.type === "video"
		);
	};

	hasOutroClip = () => {
		return (
			this.videoEdits?.outro?.visible &&
			this.videoEdits.outro.type === "video"
		);
	};

	//#endregion

	//#region RENDER functions

	render: (time: number) => RenderResult = (time: number) => {
		const res: RenderResult = {};

		const ctx = this.props.canvas?.getContext(
			"2d",
		) as CanvasRenderingContext2D;

		if (!ctx || !this.props.canvas) {
			// console.error("returning prematurely", ctx, this.props.canvas);
			return res;
		}

		const intro = this.videoEdits?.intro || undefined;
		const outro = this.videoEdits?.outro || undefined;

		let introDuration = intro?.duration ?? 0;

		if (intro?.transition?.type !== undefined) {
			introDuration += intro?.transition?.duration ?? 0;
		}

		const outroDuration = outro?.duration;

		// Clear previous frame
		ctx.save();
		// ADDED FOR SKIA

		ctx.clearRect(
			0,
			0,
			this.coordinates.canvasWidth,
			this.coordinates.canvasHeight,
		);
		// Clear previous zoom, if any
		ctx.resetTransform();

		//check if we should render intro
		if (intro?.visible && introDuration && time < introDuration) {
			//Render the intro

			// construct imageDataMap

			if (this.hasIntroClip()) {
				this.renderBookendsFrame("intro", time);

				if (!intro.hideText) {
					printIntroClipTitle(ctx, time, intro, {
						width: this.coordinates.canvasWidth,
						height: this.coordinates.canvasHeight,
						background: this.assets.intro.bgImage?.src || null,
						//logo: this.assets.intro.logo?.src || null,
						getFont: this.assets.getFont,
						imageDataMap: null,
					});
				}
			} else {
				drawIntro(this.props.canvas, intro, {
					width: this.coordinates.canvasWidth,
					height: this.coordinates.canvasHeight,
					background: this.assets.intro.bgImage?.src || null,
					//logo: this.assets.intro.logo?.src || null,
					// background: cp.introImgRef?.current || null,
					// logo: cp.introLogoRef?.current || null,
					getFont: this.assets.getFont,
					imageDataMap: this.assets.intro.imageElementsMap || null,
				});

				//TODO skia: Should this be there??
				// drawElements();

				if (intro.transition) {
					const transition_duration = intro.transition.duration;
					if (
						time >=
							this.timer.getScreenclipStartTime() -
								transition_duration &&
						time <= this.timer.getScreenclipStartTime()
					) {
						if (intro.transition.type === "circleWipe") {
							const wipe_duration = 0.6 * transition_duration;
							const radius = interpolate(
								{
									x:
										this.timer.getScreenclipStartTime() -
										transition_duration,
									y: 10,
								},
								{
									x:
										this.timer.getScreenclipStartTime() -
										(transition_duration - wipe_duration),
									y: Math.sqrt(
										this.coordinates.canvasWidth ** 2 +
											this.coordinates.canvasHeight ** 2,
									),
								},
								time,
								"easeInQuad",
							);

							const opacity = interpolate(
								{
									x:
										this.timer.getScreenclipStartTime() -
										transition_duration,
									y: 0.8,
								},
								{
									x: this.timer.getScreenclipStartTime(),
									y: 1,
								},
								time,
								"easeOutQuint",
							);
							// ctx.restore();
							// REMOVED FOR SKIA
							ctx.save();
							ctx.beginPath();
							ctx.arc(
								this.coordinates.canvasWidth / 2,
								this.coordinates.canvasHeight / 2,
								radius,
								0,
								Math.PI * 2,
								true,
							);
							ctx.closePath();
							ctx.clip();
							res.zoom = this.drawScreenclip(time, 1, opacity);
							ctx.restore();
							// ADDED FOR SKIA
						} else if (intro.transition.type === "rainbowWipe") {
							const corner =
								intro.transition.direction || "topRight";

							const baseColor =
								intro.transition.color || "#D43F8C";

							const lightBaseColorRGBA = blendWithWhite(
								baseColor,
								0.7,
							);

							let centerX: number;
							let centerY: number;
							switch (corner) {
								case "topLeft":
									centerX = 0;
									centerY = 0;
									break;
								case "topRight":
									centerX = this.coordinates.canvasWidth;
									centerY = 0;
									break;
								case "bottomRight":
									centerX = this.coordinates.canvasWidth;
									centerY = this.coordinates.canvasHeight;
									break;
								default:
									centerX = 0;
									centerY = this.coordinates.canvasHeight;
									break;
							}

							const scale = interpolate(
								{
									x:
										this.timer.getScreenclipStartTime() -
										transition_duration,
									y: 0.8,
								},
								{
									x: this.timer.getScreenclipStartTime(),
									y: 1,
								},
								time,
								"easeInOutSin",
							);

							const elapsed =
								time -
								(this.timer.getScreenclipStartTime() -
									transition_duration);
							const progress = Math.min(
								elapsed / transition_duration,
								1,
							);
							const easedProgress = interpolate(
								{
									x: 0,
									y: 0,
								},
								{
									x: 1,
									y: 1,
								},
								progress,
								"easeInOutSin",
							);

							// Calculate the maximum radius to ensure it covers the entire canvas
							const maxRadius =
								Math.sqrt(
									this.coordinates.canvasWidth ** 2 +
										this.coordinates.canvasHeight ** 2,
								) + 100;
							const radius = easedProgress * 2 * maxRadius;

							const innerRadius =
								easedProgress < 0.5
									? 0
									: radius * (easedProgress - 0.4999);
							const midRadius = radius * (easedProgress + 0.1);

							if (innerRadius > 0) {
								res.zoom = this.drawScreenclip(time, 1, scale);
							}

							ctx.beginPath();
							ctx.arc(
								centerX,
								centerY,
								innerRadius,
								0,
								Math.PI * 2,
							);
							ctx.fillStyle = "transparent";
							ctx.fill();

							ctx.beginPath();
							ctx.arc(
								centerX,
								centerY,
								midRadius,
								0,
								Math.PI * 2,
							);
							ctx.arc(
								centerX,
								centerY,
								innerRadius,
								0,
								Math.PI * 2,
								true,
							);
							ctx.fillStyle = baseColor;
							ctx.fill();

							ctx.beginPath();
							ctx.arc(centerX, centerY, radius, 0, Math.PI * 2);
							ctx.arc(
								centerX,
								centerY,
								midRadius,
								0,
								Math.PI * 2,
								true,
							);
							ctx.fillStyle = lightBaseColorRGBA;
							ctx.fill();

							ctx.clip();

							ctx.restore();
						} else if (intro.transition.type === "cornerWipe") {
							const corner =
								intro.transition.direction || "topRight";

							let centerX: number;
							let centerY: number;
							switch (corner) {
								case "topLeft":
									centerX = 0;
									centerY = 0;
									break;
								case "topRight":
									centerX = this.coordinates.canvasWidth;
									centerY = 0;
									break;
								case "bottomRight":
									centerX = this.coordinates.canvasWidth;
									centerY = this.coordinates.canvasHeight;
									break;
								default:
									centerX = 0;
									centerY = this.coordinates.canvasHeight;
									break;
							}

							const scale = interpolate(
								{
									x:
										this.timer.getScreenclipStartTime() -
										transition_duration,
									y: 0.8,
								},
								{
									x: this.timer.getScreenclipStartTime(),
									y: 1,
								},
								time,
								"easeInOutSin",
							);

							const elapsed =
								time -
								(this.timer.getScreenclipStartTime() -
									transition_duration);
							const progress = Math.min(
								elapsed / transition_duration,
								1,
							);

							const easedProgress = interpolate(
								{
									x: 0,
									y: 0,
								},
								{
									x: 1,
									y: 1,
								},
								progress,
								"easeInOutSin",
							);

							// Calculate the radius of the arc to cover the entire canvas
							const maxRadius = Math.sqrt(
								this.coordinates.canvasWidth ** 2 +
									this.coordinates.canvasHeight ** 2,
							);
							const radius = easedProgress * maxRadius;

							// Save the current canvas state
							ctx.save();

							// Create a circular clipping region from the specified corner
							ctx.beginPath();
							ctx.arc(centerX, centerY, radius, 0, Math.PI * 2);
							ctx.clip();

							// Draw the screenclip with zoom effect
							res.zoom = this.drawScreenclip(
								time,
								1,
								scale,
								true,
							);

							// Restore the canvas state
							ctx.restore();
						} else if (intro.transition.type === "stack") {
							const edge = intro.transition.direction || "right";

							const elapsed =
								time -
								(this.timer.getScreenclipStartTime() -
									transition_duration);
							const progress = Math.min(
								elapsed / transition_duration,
								1,
							);

							const easedProgress = interpolate(
								{
									x: 0,
									y: 0,
								},
								{
									x: 1,
									y: 1,
								},
								progress,
								"easeOutQuint",
							);

							// Calculate the distance to move based on the edge
							let translateX = 0;
							let translateY = 0;

							switch (edge) {
								case "top":
									translateY =
										-this.coordinates.canvasHeight +
										easedProgress *
											this.coordinates.canvasHeight;
									break;
								case "bottom":
									translateY =
										this.coordinates.canvasHeight -
										easedProgress *
											this.coordinates.canvasHeight;
									break;
								case "left":
									translateX =
										-this.coordinates.canvasWidth +
										easedProgress *
											this.coordinates.canvasWidth;
									break;
								case "right":
									translateX =
										this.coordinates.canvasWidth -
										easedProgress *
											this.coordinates.canvasWidth;
									break;
							}

							ctx.save();
							ctx.translate(translateX, translateY);
							this.drawScreenclip(time, 1, 1, true);
							ctx.restore();
						} else if (intro.transition.type === "colorWipe") {
							const isRightDirection =
								intro.transition.direction === "right";
							const primaryColor =
								intro.transition.color || "#073cad";
							const secondaryColor = blendWithWhite(
								primaryColor,
								0.7,
							);
							const tertiaryColor = blendWithBlack(
								primaryColor,
								0.3,
							);

							const elapsed =
								time -
								(this.timer.getScreenclipStartTime() -
									transition_duration);
							const progress = Math.min(
								elapsed / transition_duration,
								1,
							);
							const easedProgress = interpolate(
								{ x: 0, y: 0 },
								{ x: 1, y: 1 },
								progress,
								"easeInOutSin",
							);

							ctx.save();
							const canvasWidth = this.coordinates.canvasWidth;
							const canvasHeight = this.coordinates.canvasHeight;

							if (easedProgress <= 0.3) {
								const phaseProgress = easedProgress / 0.3;

								// Calculate base positions
								const smallBlock1Width = Math.min(
									canvasWidth * 0.13,
									canvasWidth * phaseProgress * 0.13,
								);
								const smallBlock1Offset = Math.min(
									canvasWidth * 0.3,
									canvasWidth * phaseProgress * 0.3,
								);

								if (isRightDirection) {
									// Right to left animation
									const bigBlockX =
										canvasWidth * (1 - phaseProgress);

									ctx.fillStyle = primaryColor;
									ctx.fillRect(
										bigBlockX,
										0,
										smallBlock1Offset,
										canvasHeight,
									);

									ctx.fillStyle = secondaryColor;
									ctx.fillRect(
										bigBlockX + smallBlock1Offset,
										0,
										smallBlock1Width,
										canvasHeight,
									);

									ctx.fillStyle = tertiaryColor;
									ctx.fillRect(
										bigBlockX +
											smallBlock1Offset +
											smallBlock1Width,
										0,
										canvasWidth -
											(smallBlock1Offset +
												smallBlock1Width),
										canvasHeight,
									);
								} else {
									// Left to right animation - exact mirror of right direction
									const bigBlockX =
										-canvasWidth * (1 - phaseProgress);

									ctx.fillStyle = primaryColor;
									ctx.fillRect(
										canvasWidth +
											bigBlockX -
											smallBlock1Offset,
										0,
										smallBlock1Offset,
										canvasHeight,
									);

									ctx.fillStyle = secondaryColor;
									ctx.fillRect(
										canvasWidth +
											bigBlockX -
											smallBlock1Offset -
											smallBlock1Width,
										0,
										smallBlock1Width,
										canvasHeight,
									);

									ctx.fillStyle = tertiaryColor;
									ctx.fillRect(
										0,
										0,
										canvasWidth +
											bigBlockX -
											smallBlock1Offset -
											smallBlock1Width,
										canvasHeight,
									);
								}
							} else if (easedProgress <= 0.5) {
								const phaseProgress =
									(easedProgress - 0.3) / 0.2;
								const smallBlock1Width = canvasWidth * 0.13;
								const smallBlock1InitialOffset =
									canvasWidth * 0.3;

								const firstBlockProgress = interpolate(
									{ x: 0, y: 0 },
									{ x: 1, y: 1 },
									phaseProgress,
									"linear",
								);

								const secondBlockStartPoint = 0.8;
								const secondBlockProgress = Math.max(
									0,
									(phaseProgress - secondBlockStartPoint) /
										(1 - secondBlockStartPoint),
								);

								const secondBlockEasedProgress = interpolate(
									{ x: 0, y: 0 },
									{ x: 1, y: 1 },
									secondBlockProgress,
									"linear",
								);

								if (isRightDirection) {
									const smallBlock1Offset =
										smallBlock1InitialOffset *
											(1 - firstBlockProgress) -
										smallBlock1Width * firstBlockProgress;
									const secondBlockWidth = canvasWidth * 0.13;
									const secondBlockInitialOffset =
										canvasWidth * 0.4;
									const secondBlockX =
										canvasWidth +
										secondBlockWidth -
										secondBlockInitialOffset *
											secondBlockEasedProgress;

									ctx.fillStyle = primaryColor;
									ctx.fillRect(
										0,
										0,
										Math.max(
											smallBlock1Offset +
												smallBlock1Width,
											0,
										),
										canvasHeight,
									);

									if (
										smallBlock1Offset + smallBlock1Width >
										0
									) {
										ctx.fillStyle = secondaryColor;
										ctx.fillRect(
											smallBlock1Offset,
											0,
											smallBlock1Width,
											canvasHeight,
										);
									}

									if (
										phaseProgress >= secondBlockStartPoint
									) {
										ctx.fillStyle = tertiaryColor;
										ctx.fillRect(
											secondBlockX,
											0,
											secondBlockWidth,
											canvasHeight,
										);
									}

									ctx.fillStyle = tertiaryColor;
									ctx.fillRect(
										Math.max(
											smallBlock1Offset +
												smallBlock1Width,
											0,
										),
										0,
										canvasWidth -
											Math.max(
												smallBlock1Offset +
													smallBlock1Width,
												0,
											),
										canvasHeight,
									);
								} else {
									// Mirror of right direction
									const smallBlock1Offset =
										smallBlock1InitialOffset *
											(1 - firstBlockProgress) -
										smallBlock1Width * firstBlockProgress;
									const secondBlockWidth = canvasWidth * 0.13;
									const secondBlockInitialOffset =
										canvasWidth * 0.4;
									const secondBlockX =
										-secondBlockWidth +
										secondBlockInitialOffset *
											secondBlockEasedProgress;

									ctx.fillStyle = primaryColor;
									ctx.fillRect(
										canvasWidth -
											Math.max(
												smallBlock1Offset +
													smallBlock1Width,
												0,
											),
										0,
										Math.max(
											smallBlock1Offset +
												smallBlock1Width,
											0,
										),
										canvasHeight,
									);

									if (
										smallBlock1Offset + smallBlock1Width >
										0
									) {
										ctx.fillStyle = secondaryColor;
										ctx.fillRect(
											canvasWidth -
												smallBlock1Offset -
												smallBlock1Width,
											0,
											smallBlock1Width,
											canvasHeight,
										);
									}

									if (
										phaseProgress >= secondBlockStartPoint
									) {
										ctx.fillStyle = tertiaryColor;
										ctx.fillRect(
											secondBlockX,
											0,
											secondBlockWidth,
											canvasHeight,
										);
									}

									ctx.fillStyle = tertiaryColor;
									ctx.fillRect(
										0,
										0,
										canvasWidth -
											Math.max(
												smallBlock1Offset +
													smallBlock1Width,
												0,
											),
										canvasHeight,
									);
								}
							} else {
								ctx.drawImage(
									this.assets.background.src,
									0,
									0,
									this.coordinates.canvasWidth,
									this.coordinates.canvasHeight,
								);

								const vidWidth =
									this.coordinates.videoRenderWidth;
								const vidHeight =
									this.coordinates.videoRenderHeight;
								const finalXPos =
									(this.coordinates.canvasWidth - vidWidth) *
									0.5;
								const startingXPos = isRightDirection
									? this.coordinates.canvasWidth * 0.7
									: -this.coordinates.videoRenderWidth * 0.2;

								const x_pos =
									startingXPos +
									(finalXPos - startingXPos) *
										((easedProgress - 0.5) * 2);
								const y_pos =
									(this.coordinates.canvasHeight -
										vidHeight) *
									0.5;

								this.renderScreenclipFrame(
									this.timer.getUnadjustedTime(time),
									1,
									1,
									x_pos,
									y_pos,
								);

								const phaseProgress =
									(easedProgress - 0.5) / 0.5;
								const bigBlockWidth = canvasWidth;
								const maxSmallBlockWidth = canvasWidth * 0.13;
								const smallBlockWidth =
									maxSmallBlockWidth * (1 - phaseProgress);
								const maxOffset = canvasWidth * 0.1;
								const offset = maxOffset * (1 - phaseProgress);

								if (isRightDirection) {
									const bigBlockX =
										-canvasWidth * phaseProgress;

									ctx.fillStyle = tertiaryColor;
									ctx.fillRect(
										bigBlockX,
										0,
										bigBlockWidth,
										canvasHeight,
									);

									if (smallBlockWidth > 0) {
										ctx.fillRect(
											bigBlockX +
												bigBlockWidth -
												smallBlockWidth -
												offset,
											0,
											smallBlockWidth,
											canvasHeight,
										);
									}
								} else {
									const bigBlockX =
										canvasWidth * phaseProgress;

									ctx.fillStyle = tertiaryColor;
									ctx.fillRect(
										bigBlockX,
										0,
										bigBlockWidth,
										canvasHeight,
									);

									if (smallBlockWidth > 0) {
										ctx.fillRect(
											bigBlockX + offset,
											0,
											smallBlockWidth,
											canvasHeight,
										);
									}
								}
							}

							ctx.restore();
						}
					}
				}
			}
		}
		//check if we should render the outro
		else if (
			outro?.visible &&
			outroDuration &&
			time >= this.timer.getScreenclipEndTime()
		) {
			if (
				outro.transition?.duration &&
				time >= this.timer.getScreenclipEndTime() &&
				time <=
					this.timer.getScreenclipEndTime() +
						outro.transition?.duration
			) {
				const transition_duration = outro.transition.duration;

				const transitionStartAbsoluteTime =
					this.timer.getScreenclipEndTime();
				const transitionEndAbsoluteTime =
					transitionStartAbsoluteTime + transition_duration;

				const scale = interpolate(
					{
						x: transitionStartAbsoluteTime,
						y: 1,
					},
					{
						x: transitionEndAbsoluteTime,
						y: 0.7,
					},
					time,
					"easeInOutSin",
				);
				res.zoom = this.drawScreenclip(
					transitionStartAbsoluteTime,
					1,
					scale,
				);

				ctx.save();

				if (outro.transition.type === "circleWipe") {
					const radius = interpolate(
						{
							x: transitionStartAbsoluteTime,
							y: 10,
						},
						{
							x: transitionEndAbsoluteTime,
							y: Math.sqrt(
								this.coordinates.canvasWidth ** 2 +
									this.coordinates.canvasHeight ** 2,
							),
						},
						time,
						"easeInQuad",
					);

					ctx.beginPath();
					ctx.arc(
						this.coordinates.canvasWidth / 2,
						this.coordinates.canvasHeight / 2,
						radius,
						0,
						Math.PI * 2,
						true,
					);
					ctx.closePath();
					ctx.clip();

					if (outro.type === "video") {
						this.renderBookendsFrame("outro", 0);
					} else
						drawOutro(this.props.canvas, outro, {
							width: this.coordinates.canvasWidth,
							height: this.coordinates.canvasHeight,
							background: this.assets.outro.bgImage?.src || null,
							//logo: this.assets.outro.logo?.src || null,
							imageDataMap:
								this.assets.outro.imageElementsMap || null,
							getFont: this.assets.getFont,
							// background: cp.outroImgRef?.current || null,
							// logo: cp.outroLogoRef?.current || null,
						});

					ctx.restore();
					// ADDED FOR SKIA
				} else if (outro.transition.type === "rainbowWipe") {
					const corner = outro.transition.direction || "topRight";

					const baseColor = outro.transition.color || "#D43F8C";

					const lightBaseColorRGBA = blendWithWhite(baseColor, 0.7);

					let centerX: number;
					let centerY: number;
					switch (corner) {
						case "topLeft":
							centerX = 0;
							centerY = 0;
							break;
						case "topRight":
							centerX = this.coordinates.canvasWidth;
							centerY = 0;
							break;
						case "bottomRight":
							centerX = this.coordinates.canvasWidth;
							centerY = this.coordinates.canvasHeight;
							break;
						default:
							centerX = 0;
							centerY = this.coordinates.canvasHeight;
							break;
					}

					const elapsed = time - transitionStartAbsoluteTime;
					const progress = Math.min(elapsed / transition_duration, 1);
					const easedProgress = interpolate(
						{
							x: 0,
							y: 0,
						},
						{
							x: 1,
							y: 1,
						},
						progress,
						"easeInOutSin",
					);

					// Calculate the maximum radius to ensure it covers the entire canvas
					const maxRadius = Math.sqrt(
						this.coordinates.canvasWidth ** 2 +
							this.coordinates.canvasHeight ** 2,
					);
					const radius = easedProgress * 2 * maxRadius;

					const innerRadius =
						easedProgress < 0.5
							? 0
							: radius * (easedProgress - 0.4999);
					const midRadius = radius * (easedProgress + 0.1);

					if (innerRadius > 0) {
						if (outro.type === "video") {
							this.renderBookendsFrame("outro", 0);
						} else
							drawOutro(this.props.canvas, outro, {
								width: this.coordinates.canvasWidth,
								height: this.coordinates.canvasHeight,
								background:
									this.assets.outro.bgImage?.src || null,
								//logo: this.assets.outro.logo?.src || null,
								imageDataMap:
									this.assets.outro.imageElementsMap || null,
								getFont: this.assets.getFont,
								// background: cp.outroImgRef?.current || null,
								// logo: cp.outroLogoRef?.current || null,
							});
					}

					// Draw the transition arcs
					ctx.beginPath();
					ctx.arc(centerX, centerY, innerRadius, 0, Math.PI * 2);
					ctx.fillStyle = "transparent";
					ctx.fill();

					ctx.beginPath();
					ctx.arc(centerX, centerY, midRadius, 0, Math.PI * 2);
					ctx.arc(
						centerX,
						centerY,
						innerRadius,
						0,
						Math.PI * 2,
						true,
					);
					ctx.fillStyle = baseColor;
					ctx.fill();

					ctx.beginPath();
					ctx.arc(centerX, centerY, radius, 0, Math.PI * 2);
					ctx.arc(centerX, centerY, midRadius, 0, Math.PI * 2, true);
					ctx.fillStyle = lightBaseColorRGBA;
					ctx.fill();

					ctx.clip();

					ctx.restore();
				} else if (outro.transition.type === "cornerWipe") {
					const corner = outro.transition.direction || "topRight";

					let centerX: number;
					let centerY: number;
					switch (corner) {
						case "topLeft":
							centerX = 0;
							centerY = 0;
							break;
						case "topRight":
							centerX = this.coordinates.canvasWidth;
							centerY = 0;
							break;
						case "bottomRight":
							centerX = this.coordinates.canvasWidth;
							centerY = this.coordinates.canvasHeight;
							break;
						default:
							centerX = 0;
							centerY = this.coordinates.canvasHeight;
							break;
					}

					const elapsed = time - transitionStartAbsoluteTime;
					const progress = Math.min(elapsed / transition_duration, 1);

					const easedProgress = interpolate(
						{
							x: 0,
							y: 0,
						},
						{
							x: 1,
							y: 1,
						},
						progress,
						"easeInOutSin",
					);

					// Calculate the radius of the arc to cover the entire canvas
					const maxRadius =
						Math.sqrt(
							this.coordinates.canvasWidth ** 2 +
								this.coordinates.canvasHeight ** 2,
						) + 100;
					const radius = easedProgress * maxRadius;

					// Save the current canvas state
					ctx.save();

					// Create a circular clipping region from the specified corner
					ctx.beginPath();
					ctx.arc(centerX, centerY, radius, 0, Math.PI * 2);
					ctx.clip();

					if (outro.type === "video") {
						this.renderBookendsFrame("outro", 0);
					} else
						drawOutro(this.props.canvas, outro, {
							width: this.coordinates.canvasWidth,
							height: this.coordinates.canvasHeight,
							background: this.assets.outro.bgImage?.src || null,
							//logo: this.assets.outro.logo?.src || null,
							imageDataMap:
								this.assets.outro.imageElementsMap || null,
							getFont: this.assets.getFont,
							// background: cp.outroImgRef?.current || null,
							// logo: cp.outroLogoRef?.current || null,
						});

					// Restore the canvas state
					ctx.restore();
				} else if (outro.transition.type === "stack") {
					const edge = outro.transition.direction || "right";

					const elapsed = time - transitionStartAbsoluteTime;
					const progress = Math.min(elapsed / transition_duration, 1);

					const easedProgress = interpolate(
						{
							x: 0,
							y: 0,
						},
						{
							x: 1,
							y: 1,
						},
						progress,
						"easeOutQuint",
					);

					// Calculate the distance to move based on the edge
					let translateX = 0;
					let translateY = 0;

					switch (edge) {
						case "top":
							translateY =
								-this.coordinates.canvasHeight +
								easedProgress * this.coordinates.canvasHeight;
							break;
						case "bottom":
							translateY =
								this.coordinates.canvasHeight -
								easedProgress * this.coordinates.canvasHeight;
							break;
						case "left":
							translateX =
								-this.coordinates.canvasWidth +
								easedProgress * this.coordinates.canvasWidth;
							break;
						case "right":
							translateX =
								this.coordinates.canvasWidth -
								easedProgress * this.coordinates.canvasWidth;
							break;
					}

					// Save canvas state before applying transformations
					ctx.save();

					// Apply translation based on the calculated X or Y distance
					ctx.translate(translateX, translateY);

					if (outro.type === "video") {
						this.renderBookendsFrame("outro", 0);
					} else
						drawOutro(this.props.canvas, outro, {
							width: this.coordinates.canvasWidth,
							height: this.coordinates.canvasHeight,
							background: this.assets.outro.bgImage?.src || null,
							//logo: this.assets.outro.logo?.src || null,
							imageDataMap:
								this.assets.outro.imageElementsMap || null,
							getFont: this.assets.getFont,
							// background: cp.outroImgRef?.current || null,
							// logo: cp.outroLogoRef?.current || null,
						});

					// Restore canvas state after drawing the frame
					ctx.restore();
				} else if (outro.transition.type === "colorWipe") {
					const isRightDirection =
						outro.transition.direction === "right";

					const primaryColor = outro.transition.color || "#073cad";
					const secondaryColor = blendWithWhite(primaryColor, 0.7);
					const tertiaryColor = blendWithBlack(primaryColor, 0.3);

					const elapsed = time - transitionStartAbsoluteTime;
					const progress = Math.min(elapsed / transition_duration, 1);
					const easedProgress = interpolate(
						{ x: 0, y: 0 },
						{ x: 1, y: 1 },
						progress,
						"easeInOutSin",
					);

					ctx.save();
					const canvasWidth = this.coordinates.canvasWidth;
					const canvasHeight = this.coordinates.canvasHeight;

					if (easedProgress <= 0.3) {
						const phaseProgress = easedProgress / 0.3;

						// Calculate base positions
						const smallBlock1Width = Math.min(
							canvasWidth * 0.13,
							canvasWidth * phaseProgress * 0.13,
						);
						const smallBlock1Offset = Math.min(
							canvasWidth * 0.3,
							canvasWidth * phaseProgress * 0.3,
						);

						if (isRightDirection) {
							// Right to left animation
							const bigBlockX = canvasWidth * (1 - phaseProgress);

							ctx.fillStyle = primaryColor;
							ctx.fillRect(
								bigBlockX,
								0,
								smallBlock1Offset,
								canvasHeight,
							);

							ctx.fillStyle = secondaryColor;
							ctx.fillRect(
								bigBlockX + smallBlock1Offset,
								0,
								smallBlock1Width,
								canvasHeight,
							);

							ctx.fillStyle = tertiaryColor;
							ctx.fillRect(
								bigBlockX +
									smallBlock1Offset +
									smallBlock1Width,
								0,
								canvasWidth -
									(smallBlock1Offset + smallBlock1Width),
								canvasHeight,
							);
						} else {
							// Left to right animation - exact mirror of right direction
							const bigBlockX =
								-canvasWidth * (1 - phaseProgress);

							ctx.fillStyle = primaryColor;
							ctx.fillRect(
								canvasWidth + bigBlockX - smallBlock1Offset,
								0,
								smallBlock1Offset,
								canvasHeight,
							);

							ctx.fillStyle = secondaryColor;
							ctx.fillRect(
								canvasWidth +
									bigBlockX -
									smallBlock1Offset -
									smallBlock1Width,
								0,
								smallBlock1Width,
								canvasHeight,
							);

							ctx.fillStyle = tertiaryColor;
							ctx.fillRect(
								0,
								0,
								canvasWidth +
									bigBlockX -
									smallBlock1Offset -
									smallBlock1Width,
								canvasHeight,
							);
						}
					} else if (easedProgress <= 0.5) {
						const phaseProgress = (easedProgress - 0.3) / 0.2;
						const smallBlock1Width = canvasWidth * 0.13;
						const smallBlock1InitialOffset = canvasWidth * 0.3;

						const firstBlockProgress = interpolate(
							{ x: 0, y: 0 },
							{ x: 1, y: 1 },
							phaseProgress,
							"linear",
						);

						const secondBlockStartPoint = 0.8;
						const secondBlockProgress = Math.max(
							0,
							(phaseProgress - secondBlockStartPoint) /
								(1 - secondBlockStartPoint),
						);

						const secondBlockEasedProgress = interpolate(
							{ x: 0, y: 0 },
							{ x: 1, y: 1 },
							secondBlockProgress,
							"linear",
						);

						if (isRightDirection) {
							const smallBlock1Offset =
								smallBlock1InitialOffset *
									(1 - firstBlockProgress) -
								smallBlock1Width * firstBlockProgress;
							const secondBlockWidth = canvasWidth * 0.13;
							const secondBlockInitialOffset = canvasWidth * 0.4;
							const secondBlockX =
								canvasWidth +
								secondBlockWidth -
								secondBlockInitialOffset *
									secondBlockEasedProgress;

							ctx.fillStyle = primaryColor;
							ctx.fillRect(
								0,
								0,
								Math.max(
									smallBlock1Offset + smallBlock1Width,
									0,
								),
								canvasHeight,
							);

							if (smallBlock1Offset + smallBlock1Width > 0) {
								ctx.fillStyle = secondaryColor;
								ctx.fillRect(
									smallBlock1Offset,
									0,
									smallBlock1Width,
									canvasHeight,
								);
							}

							if (phaseProgress >= secondBlockStartPoint) {
								ctx.fillStyle = tertiaryColor;
								ctx.fillRect(
									secondBlockX,
									0,
									secondBlockWidth,
									canvasHeight,
								);
							}

							ctx.fillStyle = tertiaryColor;
							ctx.fillRect(
								Math.max(
									smallBlock1Offset + smallBlock1Width,
									0,
								),
								0,
								canvasWidth -
									Math.max(
										smallBlock1Offset + smallBlock1Width,
										0,
									),
								canvasHeight,
							);
						} else {
							// Mirror of right direction
							const smallBlock1Offset =
								smallBlock1InitialOffset *
									(1 - firstBlockProgress) -
								smallBlock1Width * firstBlockProgress;
							const secondBlockWidth = canvasWidth * 0.13;
							const secondBlockInitialOffset = canvasWidth * 0.4;
							const secondBlockX =
								-secondBlockWidth +
								secondBlockInitialOffset *
									secondBlockEasedProgress;

							ctx.fillStyle = primaryColor;
							ctx.fillRect(
								canvasWidth -
									Math.max(
										smallBlock1Offset + smallBlock1Width,
										0,
									),
								0,
								Math.max(
									smallBlock1Offset + smallBlock1Width,
									0,
								),
								canvasHeight,
							);

							if (smallBlock1Offset + smallBlock1Width > 0) {
								ctx.fillStyle = secondaryColor;
								ctx.fillRect(
									canvasWidth -
										smallBlock1Offset -
										smallBlock1Width,
									0,
									smallBlock1Width,
									canvasHeight,
								);
							}

							if (phaseProgress >= secondBlockStartPoint) {
								ctx.fillStyle = tertiaryColor;
								ctx.fillRect(
									secondBlockX,
									0,
									secondBlockWidth,
									canvasHeight,
								);
							}

							ctx.fillStyle = tertiaryColor;
							ctx.fillRect(
								0,
								0,
								canvasWidth -
									Math.max(
										smallBlock1Offset + smallBlock1Width,
										0,
									),
								canvasHeight,
							);
						}
					} else {
						if (outro.type === "video") {
							this.renderBookendsFrame("outro", 0);
						} else
							drawOutro(this.props.canvas, outro, {
								width: this.coordinates.canvasWidth,
								height: this.coordinates.canvasHeight,
								background:
									this.assets.outro.bgImage?.src || null,
								//logo: this.assets.outro.logo?.src || null,
								imageDataMap:
									this.assets.outro.imageElementsMap || null,
								getFont: this.assets.getFont,
								// background: cp.outroImgRef?.current || null,
								// logo: cp.outroLogoRef?.current || null,
							});

						const phaseProgress = (easedProgress - 0.5) / 0.5;
						const bigBlockWidth = canvasWidth;
						const maxSmallBlockWidth = canvasWidth * 0.13;
						const smallBlockWidth =
							maxSmallBlockWidth * (1 - phaseProgress);
						const maxOffset = canvasWidth * 0.1;
						const offset = maxOffset * (1 - phaseProgress);

						if (isRightDirection) {
							const bigBlockX = -canvasWidth * phaseProgress;

							ctx.fillStyle = tertiaryColor;
							ctx.fillRect(
								bigBlockX,
								0,
								bigBlockWidth,
								canvasHeight,
							);

							if (smallBlockWidth > 0) {
								ctx.fillRect(
									bigBlockX +
										bigBlockWidth -
										smallBlockWidth -
										offset,
									0,
									smallBlockWidth,
									canvasHeight,
								);
							}
						} else {
							const bigBlockX = canvasWidth * phaseProgress;

							ctx.fillStyle = tertiaryColor;
							ctx.fillRect(
								bigBlockX,
								0,
								bigBlockWidth,
								canvasHeight,
							);

							if (smallBlockWidth > 0) {
								ctx.fillRect(
									bigBlockX + offset,
									0,
									smallBlockWidth,
									canvasHeight,
								);
							}
						}
					}

					ctx.restore();
				}
			} else {
				if (outro.type === "video") {
					this.renderBookendsFrame(
						"outro",
						time - this.timer.getScreenclipEndTime(),
					);
				} else
					drawOutro(this.props.canvas, outro, {
						width: this.coordinates.canvasWidth,
						height: this.coordinates.canvasHeight,
						background: this.assets.outro.bgImage?.src || null,
						//logo: this.assets.outro.logo?.src || null,
						imageDataMap:
							this.assets.outro.imageElementsMap || null,
						getFont: this.assets.getFont,
						// background: cp.outroImgRef?.current || null,
						// logo: cp.outroLogoRef?.current || null,
					});
			}
			// drawElement();
		} else {
			//render the video here.
			res.zoom = this.drawScreenclip(time);
		}

		ctx.restore();
		return res;
	};

	private drawScreenclip: (
		time: number,
		opacity?: number,
		scale?: number,
		ignoreEdits?: boolean,
	) => ZoomRender | null = (
		time,
		opacity = 1,
		scale = 1,
		ignoreEdits = false,
	) => {
		//Render the video frame

		let res: ZoomRender | null = null;

		const { canvas } = this.props;

		if (!canvas) {
			// console.error("can't render canvas");
			return res;
		}

		const ctx = canvas.getContext("2d") as CanvasRenderingContext2D;

		//perform zoom
		if (!ignoreEdits) {
			res = this.scaleCanvas(time);
		}

		//draw background
		ctx.drawImage(
			this.assets.background.src,
			0,
			0,
			canvas.width,
			canvas.height,
		);

		this.renderScreenclipFrame(
			this.timer.getUnadjustedTime(time),
			opacity,
			scale,
		);

		// TODO skia: This is not a proper call. Fix this.

		if (
			!ignoreEdits &&
			this.props.canvas &&
			this.videoEdits.elements &&
			this.props.tempCanvas
		) {
			this.elements.renderElements(this.timer.getUnadjustedTime(time));
		}

		return res;
	};

	private renderBookendsFrame = (slide: "intro" | "outro", time: number) => {
		let bookendsVideo: HTMLVideoElement | null = null;

		if (slide === "intro") {
			bookendsVideo = this.assets.intro.bgVideo?.frame(
				time,
			) as HTMLVideoElement;
		} else {
			bookendsVideo = this.assets.outro.bgVideo?.frame(
				time,
			) as HTMLVideoElement;
		}

		if (!bookendsVideo) return;

		const canvas = this.props.canvas;
		const ctx = canvas?.getContext("2d") as CanvasRenderingContext2D;

		if (!bookendsVideo || !canvas) return;

		// Clip rounded rectangle path for the video
		ctx.save();
		ctx.beginPath();

		let vidWidth =
			slide === "intro"
				? this.videoEdits.intro?.naturalWidth
				: this.videoEdits.outro?.naturalWidth;
		let vidHeight =
			slide === "intro"
				? this.videoEdits.intro?.naturalHeight
				: this.videoEdits.outro?.naturalHeight;

		vidWidth = vidWidth || 1920;
		vidHeight = vidHeight || 1080;

		const x_pos = (canvas.width - vidWidth) * 0.5;
		const y_pos = (canvas.height - vidHeight) * 0.5;

		ctx.drawImage(bookendsVideo, 0, 0, canvas.width, canvas.height);
	};

	private renderScreenclipFrame = (
		time: number,
		opacity = 1,
		scale = 1,
		xPos: number | null | undefined = null,
		yPos: number | null | undefined = null,
	) => {
		/**
		 * @param opacity @param scale : used for fade in/out entry and exit. this scale not to be confused with zoom scale!
		 */
		const canvas = this.props.canvas;
		const ctx = canvas?.getContext("2d") as CanvasRenderingContext2D;

		if (!canvas) {
			// console.error("can't render canvas!");
			return;
		}

		let source: HTMLVideoElement = this.assets.screenclip.frame(
			time,
		) as HTMLVideoElement;

		//This logic determines which source needs to be shown on the screen.
		if (this.props.clips?.length) {
			const videoTime = this.timer.timelineToVideoTime(time);
			const sourceId = videoTime.sourceId;
			const sourceClip = this.assets.sources?.find(
				(s) => s.id === sourceId,
			);

			// if (sourceClip?.ref.current) source = sourceClip.ref.current;
			source = sourceClip?.frame(time) as HTMLVideoElement;
		}

		const bgEdits = this.videoEdits.background;

		// Clip rounded rectangle path for the video
		ctx.save();

		ctx.globalAlpha = opacity;
		ctx.beginPath();

		// ctx.clearRect(0, 0, canvas.width, canvas.height);

		let borderRadius =
			(this.assets.screenclip.naturalHeight *
				(this.videoEdits.background?.borderRadius || 0)) /
			100;

		if (this.videoEdits.crop) {
			borderRadius *= this.videoEdits.crop.size[1];
		}

		const video_render_width = this.coordinates.videoRenderWidth * scale;
		const video_render_height = this.coordinates.videoRenderHeight * scale;

		let x_pos = (canvas.width - video_render_width) * 0.5;
		let y_pos = (canvas.height - video_render_height) * 0.5;

		if (xPos !== null && xPos !== undefined) x_pos = xPos;
		if (yPos !== null && yPos !== undefined) y_pos = yPos;

		if (bgEdits?.shadow) {
			drawBoxShadow(
				ctx,
				x_pos,
				y_pos,
				video_render_width,
				video_render_height,
				bgEdits?.shadow,
				borderRadius,
			);
		}
		drawRoundedRect(
			ctx,
			x_pos,
			y_pos,
			video_render_width,
			video_render_height,
			borderRadius,
		);

		ctx.clip();

		const vidWidth = video_render_width;
		const vidHeight = video_render_height;

		// compute crop
		if (this.videoEdits.crop) {
			const crop = this.videoEdits.crop;
			// The natural height and width are the original dimensions of the video
			const sx = crop.position[0] * this.assets.screenclip.naturalWidth; // Source x - start of crop in video coordinates
			const sy = crop.position[1] * this.assets.screenclip.naturalHeight; // Source y - start of crop in video coordinates
			const sWidth = crop.size[0] * this.assets.screenclip.naturalWidth; // Source width - width of crop in video coordinates
			const sHeight = crop.size[1] * this.assets.screenclip.naturalHeight; // Source height - height of crop in video coordinates

			ctx.drawImage(
				source,
				sx,
				sy,
				sWidth,
				sHeight,
				x_pos,
				y_pos,
				video_render_width,
				video_render_height,
			);
		} else {
			ctx.drawImage(source, x_pos, y_pos, vidWidth, vidHeight);
		}

		ctx.restore();
		//
		// Draw the cropped video frame to the canvas
	};

	//#endregion

	//#region GEOMTERY

	scaleCanvas: (time: number) => ZoomRender | null = (time) => {
		let zoomFactor = 1;
		let zoomCenter: Coordinates = { x: 0.5, y: 0.5 };

		let e = 0;
		let f = 0;

		const canvas = this.props.canvas;

		if (!canvas) return { zoomFactor, zoomCenter };
		const ctx = canvas?.getContext("2d") as CanvasRenderingContext2D;

		let zoomEdit: ZoomEdit | null = null;

		const currentTime = this.timer.timelineToVideoTime(time).time;

		let zoomRes: ZoomRender | null = null;

		let prevFactor = 1;
		let nextFactor = 1;
		let nextCenter = { x: 0.5, y: 0.5 };

		if (!this.videoEdits.zooms) return { zoomFactor, zoomCenter };

		const zooms = this.sortedZooms;

		for (let i = 0; i < zooms.length; i++) {
			const currentZoom = zooms[i];

			if (
				currentZoom.startTime <= currentTime &&
				currentTime <= currentZoom.endTime
			) {
				zoomEdit = currentZoom;

				if (i > 0 && currentZoom.startTime === zooms[i - 1].endTime) {
					prevFactor = zooms[i - 1].zoomFactor;
				}

				if (
					i < zooms.length - 1 &&
					currentZoom.endTime === zooms[i + 1].startTime
				) {
					nextFactor = zooms[i + 1].zoomFactor;
					nextCenter = zooms[i + 1].zoomCenter;
				}

				break;
			}
		}

		if (zoomEdit) {
			const transitionTime = Math.min(
				zoomEdit.transitionTime || ZOOM_TRANSITION_TIME,
				(zoomEdit.endTime - zoomEdit.startTime) / 2,
			);

			//timings
			const zoomInFinishTime = zoomEdit.startTime + transitionTime;
			const zoomOutStartTime = zoomEdit.endTime - transitionTime;

			//zoom
			const currZoom = zoomEdit.zoomFactor;
			const currCenter = zoomEdit.zoomCenter;

			if (
				zoomEdit.startTime < currentTime &&
				currentTime <= zoomInFinishTime
			) {
				//The zoom in phase
				if (prevFactor === 1)
					zoomFactor = this.interpolateZoom(
						zoomEdit.startTime,
						zoomInFinishTime,
						prevFactor,
						currZoom,
						currentTime,
					);
				else zoomFactor = currZoom;

				zoomCenter = currCenter;
			} else if (
				zoomInFinishTime < currentTime &&
				currentTime <= zoomOutStartTime
			) {
				//The keep zoom constant phase
				zoomFactor = currZoom;
				zoomCenter = currCenter;
			} else if (
				zoomOutStartTime < currentTime &&
				currentTime <= zoomEdit.endTime
			) {
				//The zoom out phase
				zoomFactor = this.interpolateZoom(
					zoomOutStartTime,
					zoomEdit.endTime,
					currZoom,
					nextFactor,
					currentTime,
				);

				if (
					nextCenter.x === 0.5 &&
					nextCenter.y === 0.5 &&
					nextFactor === 1
				)
					zoomCenter = currCenter;
				else
					zoomCenter = this.interpolateCenter(
						currCenter,
						nextCenter,
						zoomOutStartTime,
						zoomEdit.endTime,
						currentTime,
					);
			}

			const zoom_canvas_coords =
				this.coordinates.fractionalCoordsToCanvasCoords(
					zoomCenter,
					true,
				);
			e = zoom_canvas_coords.x * (1 - zoomFactor);
			f = zoom_canvas_coords.y * (1 - zoomFactor);

			zoomRes = { zoomFactor, zoomCenter, e, f };
		} else {
			zoomRes = null;
		}

		// Apply transformations for zoom effect
		ctx.save();

		// Set the transformations using setTransform
		ctx.setTransform(zoomFactor, 0, 0, zoomFactor, e, f);

		return zoomRes;
	};

	interpolateCenter = (
		startCenter: Coordinates,
		endCenter: Coordinates,
		startTime: number,
		endTime: number,
		currentTime: number,
	): Coordinates => {
		const startX = { y: startCenter.x, x: startTime };
		const endX = { y: endCenter.x, x: endTime };
		const X = interpolate(startX, endX, currentTime, INTERPOLATION_METHOD);

		const startY = { y: startCenter.y, x: startTime };
		const endY = { y: endCenter.y, x: endTime };
		const Y = interpolate(startY, endY, currentTime, INTERPOLATION_METHOD);

		return { x: X, y: Y };
	};

	interpolateZoom: (
		startTime: number,
		endTime: number,
		startZoom: number,
		endZoom: number,
		currentTime: number,
	) => number = (startTime, endTime, startZoom, endZoom, currentTime) => {
		const start = { y: startZoom, x: startTime };
		const end = { y: endZoom, x: endTime };

		return interpolate(start, end, currentTime, INTERPOLATION_METHOD);
	};
}

const blendWithWhite = (hex: string, alpha: number) => {
	// Convert hex to RGB
	const hexToRgb = (hex: string) => {
		hex = hex.replace("#", "");
		const bigint = Number.parseInt(hex, 16);
		return {
			r: (bigint >> 16) & 255, // Extract red
			g: (bigint >> 8) & 255, // Extract green
			b: bigint & 255, // Extract blue
		};
	};

	const { r, g, b } = hexToRgb(hex);

	// Blend with white
	const blendedR = Math.round(r * (1 - alpha) + 255 * alpha);
	const blendedG = Math.round(g * (1 - alpha) + 255 * alpha);
	const blendedB = Math.round(b * (1 - alpha) + 255 * alpha);

	// Return as RGBA string
	return `rgba(${blendedR}, ${blendedG}, ${blendedB}, 1)`;
};

const blendWithBlack = (hex: string, alpha: number) => {
	// Convert hex to RGB
	const hexToRgb = (hex: string) => {
		hex = hex.replace("#", "");
		const bigint = Number.parseInt(hex, 16);
		return {
			r: (bigint >> 16) & 255, // Extract red
			g: (bigint >> 8) & 255, // Extract green
			b: bigint & 255, // Extract blue
		};
	};

	const { r, g, b } = hexToRgb(hex);

	// Blend with white
	const blendedR = Math.round(r * (1 - alpha));
	const blendedG = Math.round(g * (1 - alpha));
	const blendedB = Math.round(b * (1 - alpha));

	// Return as RGBA string
	return `rgba(${blendedR}, ${blendedG}, ${blendedB}, 1)`;
};
