import { ColorPicker, ConfigProvider } from "antd";
import React from "react";
import { ravelColor, unravelColor } from "../../../utils/colorUtils";
import { Color } from "antd/es/color-picker";

type CColorPickerProps = {
	allowClear?: boolean;
	disableOpacity?: boolean;
	color: string;
	opacity?: number; //[0,100]
	onChange: (color?: string, opacity?: number) => void;
	getPopupContainer?: (() => HTMLElement) | undefined;
	children: [React.ReactNode, ...React.ReactNode[]] | React.ReactNode;
	className?: string;
};

const CColorPicker: React.FC<CColorPickerProps> = ({
	color = "#000000",
	opacity = 100,
	...props
}) => {
	const fullColor = ravelColor(color, opacity);

	const onChange = (c: Color) => {
		const { color, opacity } = unravelColor(c.toHexString());

		// const newColor = color
		// if (opacity === 0 && newColor.color !== color) {
		// 	newColor.opacity = 100;
		// }

		if (props.allowClear && opacity === 0) {
			props.onChange(undefined);
		} else {
			props.onChange(color, opacity);
		}
	};

	return (
		<ConfigProvider
			theme={{
				components: {
					ColorPicker: {
						zIndexBase: 9999999999999,
						zIndexPopupBase: 9999999999999,
					},
				},
			}}
		>
			<ColorPicker
				getPopupContainer={props.getPopupContainer}
				className={props.className}
				style={{ zIndex: 9999999999999 }}
				allowClear={props.allowClear}
				disabledAlpha={props.disableOpacity}
				value={fullColor}
				onChange={onChange}
			>
				{props.children}
			</ColorPicker>
		</ConfigProvider>
	);
};
export default CColorPicker;
