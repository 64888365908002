import { GigaUserApi } from "@giga-user-fern/api";
import { VideoClip } from "@giga-user-fern/api/types/api/resources/guides";
import { VideoEdits } from "@giga-user-fern/api/types/api/resources/video";
import { dummyVideoEdits } from "../../../videoEditTypes/core";
import { sourcesAreEqual } from "../../canvasUtils";

export type CanvasTimerProps = {
	videoDuration: number; //duration of the main screenclip.
	clips?: VideoClip[];
};

export const dummyTimerProps = {
	videoDuration: 0,
};

export class CanvasTimer {
	/**
	 * This class is used to calculate timings for the canvas.
	 */

	videoEdits: VideoEdits;
	props: CanvasTimerProps;

	constructor(videoEdits: VideoEdits, props: CanvasTimerProps) {
		this.videoEdits = videoEdits;
		this.props = { ...props };
	}

	//#region TIME GETTERS

	getScreenclipStartTime: () => number = () => {
		/**
		 * Gets the timestamp in the video at which the screenclip starts
		 * (replaces getVideoStartTime)
		 */

		//start time for a clip might vary based on clips before?

		const edits = this.videoEdits;

		if (!edits) return 0;

		if (edits.intro && edits.intro.visible) {
			var introDuration = 0;

			introDuration =
				edits.intro.duration + (edits.intro.transition?.duration ?? 0);

			return introDuration - (edits.intro.intersectionDuration || 0);
		} else {
			return 0;
		}
	};

	getScreenclipEndTime: () => number = () => {
		/**
		 * Gets the timestamp in the video at which the screenclip ends
		 * (replaces getVideoEndTime)
		 */

		const edits = this.videoEdits;

		const screenclipStartTime = this.getScreenclipStartTime();
		const screenclipDuration = this.getScreenclipsDuration();

		if (!this.props.videoDuration) return 0;
		if (!edits) return this.props.videoDuration;

		return screenclipStartTime + screenclipDuration;
	};

	getScreenclipsDuration: () => number = () => {
		var totTime = 0;
		const { clips } = this.props;

		if (!this.props.videoDuration) {
			//do nothing here
			return 0;
		}

		if (clips && clips.length > 0) {
			const totalClipsTime = clips.reduce(
				(acc, clip) => acc + clip.endTime - clip.startTime,
				0,
			);
			totTime = totalClipsTime;
		} else {
			totTime = this.props.videoDuration;
		}

		return totTime;
	};

	getVideoDuration: () => number = () => {
		/**
		 * gets the total duration of the video (intro + screenclip + outro - any intersection times)
		 * (replaces getTotalDuration)
		 */

		const edits = this.videoEdits;

		const screenclipStartTime = this.getScreenclipStartTime();

		var totTime = 0;
		if (!this.props.videoDuration) {
			//do nothing here
			return 0;
		} else {
			totTime = screenclipStartTime + this.props.videoDuration;

			const totalClipsTime = this.getScreenclipsDuration();
			totTime = screenclipStartTime + totalClipsTime;

			if (edits?.outro && edits.outro.visible) {
				totTime =
					totTime +
					edits.outro.duration -
					(edits.outro.intersectionDuration || 0);

				if (edits.outro.transition) {
					totTime += edits.outro.transition.duration;
				}
			}
		}

		return totTime;
	};

	//#endregion

	//#region TIME TRANSFORMERS

	getAdjustedTime: (screenclipTime: number) => number = (screenclipTime) => {
		/**
		 * Takes in a timestamp of the screenclip and returns the corresponding time in the final video
		 */

		const screenclipStartTime = this.getScreenclipStartTime();

		return screenclipStartTime + screenclipTime;
	};

	getUnadjustedTime: (time: number) => number = (time) => {
		/**
		 * Takes in a time of the final video (including intro and outro)
		 * returns time of screenclip
		 */

		const screenclipStartTime = this.getScreenclipStartTime();
		return time - screenclipStartTime;
	};

	timelineToVideoTime: (_time: number) => {
		time: number;
		sourceId?: GigaUserApi.Id;
	} = (_time: number) => {
		/**
		 * @param time is the time in the timeline (do not count intro and outro)
		 * time is the time wrt frontend clips.
		 * @returns time wrt backend clips. i.e: the time in the actual video .
		 */

		const time = this.getUnadjustedTime(_time);
		const { clips } = this.props;

		if (clips && clips.length > 0) {
			//there are some clips, we need to compute video time accordingly
			var elapsedTime = 0;

			// sortedClips.sort((a, b) => a.startTime - b.startTime)

			var i = 0;
			while (i < clips.length) {
				const currentClip = clips[i];
				const clipDuration =
					currentClip.endTime - currentClip.startTime;
				if (clipDuration + elapsedTime >= time) {
					const f = {
						time: currentClip.startTime + (time - elapsedTime),
						sourceId: currentClip.srcId,
					};
					return f;
				}

				elapsedTime += clipDuration;
				i++;
			}
		}
		//there are no clips
		return {
			time,
		};
	};

	videoToTimelineTime: (
		t_v: number,
		srcId?: GigaUserApi.Id,
		clips?: VideoClip[],
	) => number = (t_v, srcId, overwriteClips) => {
		/**
		 * @param t_v is the time in the video (clip). (do not count intro and outro)
		 * time is the time wrt backend clips.
		 * @srcId is the source Id we are looking at
		 * @param clips if you want to pass your own clips instead of read from the real clips.
		 * @returns time wrt frontend clips.
		 */

		const { clips } = this.props;
		var _clips;
		if (overwriteClips) {
			_clips = [...overwriteClips];
		} else {
			_clips = [...(clips || [])];
		}

		if (_clips && _clips.length > 0) {
			var elapsedTime = 0; //elapsed time on the frontend clips timeline

			// sortedClips.sort((a, b) => a.startTime - b.startTime)

			for (const clip of _clips) {
				if (
					t_v >= clip.startTime &&
					t_v <= clip.endTime &&
					sourcesAreEqual(clip.srcId, srcId)
				) {
					const t_t = elapsedTime + t_v - clip.startTime;
					return t_t;
				} else {
					elapsedTime += clip.endTime - clip.startTime;
				}
			}
		}

		//no clips
		return t_v;
	};

	//#endregion
}

export default new CanvasTimer(dummyVideoEdits, dummyTimerProps);
