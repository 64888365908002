const circledPauseIcon = (color = "#ffffff") => {
	return (
		<svg
			width="100%"
			height="100%"
			viewBox="0 0 12 12"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M10.125 2.25V9.75C10.125 9.94891 10.046 10.1397 9.90533 10.2803C9.76468 10.421 9.57391 10.5 9.375 10.5H7.5C7.30109 10.5 7.11032 10.421 6.96967 10.2803C6.82902 10.1397 6.75 9.94891 6.75 9.75V2.25C6.75 2.05109 6.82902 1.86032 6.96967 1.71967C7.11032 1.57902 7.30109 1.5 7.5 1.5H9.375C9.57391 1.5 9.76468 1.57902 9.90533 1.71967C10.046 1.86032 10.125 2.05109 10.125 2.25ZM4.5 1.5H2.625C2.42609 1.5 2.23532 1.57902 2.09467 1.71967C1.95402 1.86032 1.875 2.05109 1.875 2.25V9.75C1.875 9.94891 1.95402 10.1397 2.09467 10.2803C2.23532 10.421 2.42609 10.5 2.625 10.5H4.5C4.69891 10.5 4.88968 10.421 5.03033 10.2803C5.17098 10.1397 5.25 9.94891 5.25 9.75V2.25C5.25 2.05109 5.17098 1.86032 5.03033 1.71967C4.88968 1.57902 4.69891 1.5 4.5 1.5Z"
				fill={color}
			/>
		</svg>
	);
};
export default circledPauseIcon;
