import { ElementEdit } from "@giga-user-fern/api/types/api";
import { hexToRGBA } from "../../../../utils/colorUtils";
import { interpolate } from "../../video_effects/interpolations";

//Formatting
const PADDING_W = 10;
const PADDING_H = 10;

//Animation
const INTERPOLATION_METHOD = "easeOutCubic";
const ANIMATION_TIME = 0.3;

export const drawFilledRoundedRect = (
	ctx: CanvasRenderingContext2D,
	x: number,
	y: number,
	width: number,
	height: number,
	radius: number,
	color: string,
) => {
	const XR = x + radius;
	const YR = y + radius;

	const XW = x + width;
	const YH = y + height;

	const XWR = XW - radius;
	const YHR = YH - radius;

	ctx.beginPath();
	ctx.moveTo(XR, y);

	ctx.lineTo(XWR, y);
	ctx.quadraticCurveTo(XW, y, XW, YR);

	ctx.lineTo(XW, YHR);
	ctx.quadraticCurveTo(XW, YH, XWR, YH);

	ctx.lineTo(XR, YH);
	ctx.quadraticCurveTo(x, YH, x, YHR);

	ctx.lineTo(x, YR);
	ctx.quadraticCurveTo(x, y, XR, y);

	ctx.fillStyle = color;
	ctx.fill();
};

export const canvasPrintText: (
	canvas: HTMLCanvasElement,
	position: {
		x: number;
		y: number;
		w: number;
		h: number;
		scalingFactor: number;
	},
	element: ElementEdit,
	fontName: string,
	getFont: (name: string | undefined) => string,
	animation?: {
		animate: boolean;
		t: number;
	},
) => void = async (canvas, pos, ele, fontName, getFont, animation) => {
	let opacity = 1;
	let fillStyle: string;

	let { x, y } = pos;
	const { w, h } = pos;
	const { textdata } = ele;

	if (!textdata) return;

	if (animation?.animate) {
		const { t } = animation;

		//compute the opacity
		if (t > ele.startTime && t <= ele.startTime + ANIMATION_TIME) {
			const start = { y: 0, x: ele.startTime };
			const end = { y: 1, x: ele.startTime + ANIMATION_TIME };

			opacity = interpolate(start, end, t, INTERPOLATION_METHOD);
		} else if (t > ele.endTime - ANIMATION_TIME) {
			const start = { y: 1, x: ele.endTime - ANIMATION_TIME };
			const end = { y: 0, x: ele.endTime };

			opacity = interpolate(start, end, t, INTERPOLATION_METHOD);
		}
	}
	const ctx = canvas.getContext("2d") as CanvasRenderingContext2D;

	const fontSize = textdata.fontSize * canvas.height;
	const textAlign = textdata.alignment;
	const lines = textdata.lines || [""];
	const color = textdata.textColor || "#000000";
	let lineHeight = fontSize * 1.2;
	if (textdata.lineHeight) {
		lineHeight = textdata.lineHeight * fontSize;
	}

	if (textdata.alignment === "center") {
		x = x - w / 2;
		y = y - h / 2;
	} else if (textdata.alignment === "right") {
		x = x - w;
	}

	if (textdata.backgroundColor) {
		ctx.save();
		let bgOpacity = textdata.backgroundOpacity || 100;
		if (textdata.backgroundOpacity === 0) bgOpacity = 0;

		fillStyle = hexToRGBA(textdata.backgroundColor, opacity * bgOpacity);

		ctx.fillStyle = fillStyle;
		drawFilledRoundedRect(ctx, x, y, w, h, h * 0.0, fillStyle);
		ctx.fill();
		ctx.restore();
	}

	const write = async (fontName: string) => {
		const font = getFont(fontName);
		ctx.font = `${fontSize}px/${fontSize}px ${font}`;
		ctx.fillStyle = hexToRGBA(color, opacity * 100);
		ctx.textBaseline = "top";

		drawText(ctx, x, y, w, h, lines, textAlign, lineHeight);
	};

	write(fontName);
};

function drawText(
	ctx: CanvasRenderingContext2D,
	x: number,
	y: number,
	w: number,
	h: number,
	lines: string[],
	textAlign: "left" | "center" | "right",
	lineHeight: number,
) {
	const paddedY = y + PADDING_H;
	const startY = paddedY;
	// Set the font style
	// ctx.font = `${fontSize}px ${fontFamily}`;
	ctx.textAlign = textAlign;
	// Determine horizontal position based on text alignment
	let posX: number;
	switch (textAlign) {
		case "center":
			posX = x + w / 2; // Center of the rectangle for center alignment
			break;
		case "right":
			posX = x + w - PADDING_W; // Adjust for padding for right alignment
			break;
		case "left":
			posX = x + PADDING_W; // Adjust for padding for left alignment
			break;
	}
	// Draw each line of text according to the specified alignment
	lines.forEach((line, i) => {
		ctx.fillText(line, posX, startY + i * lineHeight);
	});
}
