const volmid = (color?: string) => {
	const fill = color || "#000000";

	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="100%"
			height="100%"
			viewBox="0 0 24 24"
			fill="none"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M11.533 5.09901C11.7028 5.18056 11.846 5.30839 11.9464 5.46779C12.0467 5.62719 12.0999 5.81167 12.1 6.00001V18.6C12.0999 18.7884 12.0466 18.9728 11.9462 19.1322C11.8459 19.2916 11.7025 19.4194 11.5327 19.5009C11.3629 19.5824 11.1736 19.6143 10.9864 19.5929C10.7993 19.5715 10.622 19.4977 10.475 19.38L6.25 16H3C2.73478 16 2.48043 15.8947 2.29289 15.7071C2.10536 15.5196 2 15.2652 2 15V9.60001C2 9.33479 2.10536 9.08044 2.29289 8.8929C2.48043 8.70536 2.73478 8.60001 3 8.60001H6.25L10.475 5.22001C10.622 5.10222 10.7994 5.02837 10.9866 5.00697C11.1737 4.98556 11.3632 5.01746 11.533 5.09901ZM10.1 8.08001L7.225 10.38C7.04759 10.5219 6.82716 10.5991 6.6 10.599H4V14H6.6C6.82728 14.0002 7.04772 14.0778 7.225 14.22L10.1 16.52V8.08001ZM15.833 7.75301C16.0205 7.56554 16.2748 7.46022 16.54 7.46022C16.8052 7.46022 17.0595 7.56554 17.247 7.75301C18.3718 8.87817 19.0037 10.404 19.0037 11.995C19.0037 13.586 18.3718 15.1118 17.247 16.237C17.0584 16.4192 16.8058 16.52 16.5436 16.5177C16.2814 16.5154 16.0306 16.4102 15.8452 16.2248C15.6598 16.0394 15.5546 15.7886 15.5523 15.5264C15.55 15.2642 15.6508 15.0116 15.833 14.823C16.5829 14.0729 17.0041 13.0557 17.0041 11.995C17.0041 10.9344 16.5829 9.91712 15.833 9.16701C15.6455 8.97948 15.5402 8.72517 15.5402 8.46001C15.5402 8.19484 15.6455 7.94054 15.833 7.75301Z"
				fill={fill}
			/>
		</svg>
	);
};

export default volmid;
