import { GigaUserApi } from "@giga-user-fern/api";
import { ElementEdit, TextData } from "@giga-user-fern/api/types/api";
import { getFontSyncWhenDownloaded } from "../../../../../utils/fontsUtils";
import { fractionalCoordsToCanvasCoordsUtil } from "../../canvasUtils";
const TextAlignment = GigaUserApi.elementEdit.TextAlignment;
type TextAlignmentType = GigaUserApi.elementEdit.TextAlignment;

export const DEFAULT_LINE_HEIGHT = 1.2;

//Formatting
const PADDING_W = 10;
const PADDING_H = 10;

export class MutableTextbox {
	/**
	 * This class is used to help the user manipulate text elements on the canvas.
	 * It is
	 */

	element: ElementEdit;
	canvas: HTMLCanvasElement | undefined;

	constructor(element: ElementEdit, canvas: HTMLCanvasElement | undefined) {
		this.element = element;
		if (canvas) {
			this.canvas = canvas;
		} else {
			// this.canvas = document.createElement("canvas");
			// this.canvas.width = 1920;
			// this.canvas.height = 1080;
		}
	}

	resizeAndReposition(newProps: {
		size?: number[];
		position?: number[];
	}): ElementEdit | undefined {
		const { size, position } = newProps;

		let newElement: ElementEdit | undefined = undefined;

		if (size) {
			const mutableTextbox = new MutableTextbox(
				this.element,
				this.canvas,
			);
			const resizedElement = mutableTextbox.resize(size, position);

			if (resizedElement) {
				newElement = resizedElement;
			}
		} else if (position) {
			//size is not changing

			newElement = {
				...this.element,
				size: size ?? this.element.size,
				position: position ?? this.element.position,
			};

			const mutableTextbox = new MutableTextbox(newElement, this.canvas);
			const adjustedElement = mutableTextbox.adjustTextElement();

			if (adjustedElement) {
				newElement = adjustedElement;
			}
		}

		return newElement;
	}

	changeAlignment(alignment: TextAlignmentType) {
		if (!this.element.textdata) return;
		const currentAlignment = this.element.textdata?.alignment;

		if (currentAlignment === alignment) return this.element;

		const newPosition: number[] = [...this.element.position];

		if (currentAlignment === TextAlignment.Left) {
			if (alignment === TextAlignment.Center) {
				newPosition[0] += this.element.size[0] / 2;
				newPosition[1] += this.element.size[1] / 2;
			} else if (alignment === TextAlignment.Right) {
				newPosition[0] += this.element.size[0];
			}
		} else if (currentAlignment === TextAlignment.Center) {
			if (alignment === TextAlignment.Left) {
				newPosition[0] -= this.element.size[0] / 2;
				newPosition[1] -= this.element.size[1] / 2;
			} else if (alignment === TextAlignment.Right) {
				newPosition[0] += this.element.size[0] / 2;
				newPosition[1] -= this.element.size[1] / 2;
			}
		} else if (currentAlignment === TextAlignment.Right) {
			if (alignment === TextAlignment.Left) {
				newPosition[0] -= this.element.size[0];
			} else if (alignment === TextAlignment.Center) {
				newPosition[0] -= this.element.size[0] / 2;
				newPosition[1] += this.element.size[1] / 2;
			}
		}
		const newElement = {
			...this.element,
			position: newPosition,
			textdata: {
				...this.element.textdata,
				alignment: alignment,
			},
		};

		const mutableTextbox = new MutableTextbox(newElement, this.canvas);
		const finalTextbox = mutableTextbox.adjustTextElement();
		return finalTextbox;
	}

	resize(newSize: number[], newPosition?: number[]): ElementEdit | undefined {
		const oldSize = this.element.size;

		if (!this.element.textdata) return;

		const newElement = {
			...this.element,
			size: [newSize[0], newSize[1]],
			position: newPosition ?? this.element.position,
		};

		if (newSize[0] !== oldSize[0] && newSize[1] === oldSize[1]) {
			//only width is changed
			const newMutableTextbox = new MutableTextbox(
				newElement,
				this.canvas,
			);
			return newMutableTextbox.adjustTextElement();
		} else {
			//width and height are both changing
			const heightFactor = newSize[1] / oldSize[1];
			const widthFactor = newSize[0] / oldSize[0];

			const fontFactor = Math.max(heightFactor, widthFactor);

			if (newSize[0] < 0.01 || newSize[1] < 0.01) return;

			newElement.textdata = {
				...this.element.textdata,
				fontSize: this.element.textdata.fontSize * fontFactor,
			};
			const newMutableTextbox = new MutableTextbox(
				newElement,
				this.canvas,
			);
			const recomputedElement = newMutableTextbox.recomputeDimensions();
			return recomputedElement;
		}
	}

	adjustTextElement() {
		//previously called adjustTextElement
		if (!this.element.textdata || !this.canvas) return;

		const canvas = this.canvas;
		const ctx = canvas?.getContext("2d");
		if (!ctx) return;

		const lines = this.wrapText();

		const updatedTextData: TextData = {
			...this.element.textdata,
			lines: lines,
		};

		this.element = {
			...this.element,
			textdata: updatedTextData,
		};

		const newHeight_f = this.computeHeight();
		ctx.save();
		const fontName = getFontSyncWhenDownloaded(
			this.element.textdata?.font ?? "",
		);

		ctx.font = `${(this.element.textdata?.fontSize ?? 0) * canvas.height}px ${fontName}`;
		let minWidth = 0;
		for (const line of lines) {
			for (const word of line.split(" ")) {
				const wordWidth = ctx.measureText(word).width;

				if (wordWidth > minWidth) {
					minWidth = wordWidth;
				}
			}
		}
		ctx.restore();

		let maxWidth: number = this.element.size[0];
		const mediaWidth: number = this.canvas.width;

		maxWidth = maxWidth * mediaWidth;

		if (maxWidth <= minWidth) {
			maxWidth = minWidth + 60;
		}

		const newSizeX = maxWidth / mediaWidth;

		const newElement: ElementEdit = {
			...this.element,
			size: [newSizeX, newHeight_f],
		};

		return newElement;
	}

	computeHeight(): number {
		//previously known as computeTextboxHeight

		if (!this.element.textdata || !this.canvas) return 0;

		const canvas = this.canvas;

		if (!canvas) return 0;

		const canvasHeight: number = this.canvas.height;

		const _fontSize = this.element.textdata.fontSize;
		const lineHeight =
			this.element.textdata.lineHeight ?? DEFAULT_LINE_HEIGHT;
		const lines = this.element.textdata.lines;

		const fontSize = _fontSize * canvasHeight;
		const newHeight =
			(lines.length - 1) * fontSize * lineHeight + fontSize + PADDING_H;
		const newHeight_f = newHeight / canvasHeight;

		this.element = {
			...this.element,
			size: [this.element.size[0], newHeight_f],
		};

		return newHeight_f;
	}

	wrapText(): string[] {
		// TODO: Adjust the wrapText function to account for the scaling factor

		if (!this.element.textdata) return [];

		const fontSize_f = this.element.textdata?.fontSize;
		const canvas = this.canvas;

		if (!canvas || !this.element.textdata) return [];

		const fontSize = fontSize_f * canvas.height;
		const ctx = canvas.getContext("2d") as CanvasRenderingContext2D;

		const text = this.element.textdata.text;

		const write = async () => {
			//Get the font name dynamically
			const fontName = await getFontSyncWhenDownloaded(
				this.element.textdata?.font,
			);
			if (!fontName) throw new Error("Font not found");

			ctx.font = `${fontSize}px ${fontName}`;
		};

		write();

		const canvasDims = fractionalCoordsToCanvasCoordsUtil(
			{ x: this.element.size[0], y: this.element.size[1] },
			canvas,
		);

		const maxWidth = canvasDims.x;

		const scalingFactor = 1;
		const scaledMaxWidth = maxWidth * scalingFactor - PADDING_W * 2; // Apply scaling factor to maxWidth for accurate measurement

		const paragraphs = text.split("\n");
		const lines: string[] = [];

		for (const paragraph of paragraphs) {
			const words = paragraph.split(" ");
			let currentLine = words.length > 0 ? words[0] : "";

			for (const word of words.slice(1)) {
				// Measure text width with scaling applied
				const width =
					ctx.measureText(currentLine + " " + word).width /
					scalingFactor;

				if (width < scaledMaxWidth) {
					currentLine += " " + word;
				} else {
					lines.push(currentLine);
					currentLine = word;
				}
			}
			if (currentLine) {
				lines.push(currentLine);
			}
		}

		return lines;
	}

	calcMinTextWidth = () => {
		const activeElement = this.element;
		const canvas = this.canvas;
		if (activeElement?.geo === "text" && canvas) {
			let minWidth = 0;
			const ctx = canvas.getContext("2d");
			if (activeElement?.textdata?.lines && ctx) {
				ctx.save();
				ctx.font = `${activeElement.textdata.fontSize * canvas.width}px ${activeElement.textdata.font}`;
				for (const line of activeElement?.textdata?.lines ?? []) {
					const words = line.split(" ");
					for (const word of words) {
						const width = ctx.measureText(word).width;
						if (width > minWidth) {
							minWidth = width;
						}
					}
				}
				ctx.restore();
			}
			return (minWidth + 60) / canvas.width;
		}
	};

	recomputeDimensions = () => {
		const element = this.element;
		if (!element.textdata || !this.canvas) return this.element;

		const fontSize = element.textdata.fontSize;
		const font = getFontSyncWhenDownloaded(element.textdata.font);
		const canvas = this.canvas;
		const ctx = canvas.getContext("2d");

		if (!ctx) return this.element;

		ctx.save();
		ctx.font = `${fontSize * canvas.height}px ${font}`;

		let maxWidth =
			ctx.measureText(element.textdata.lines[0]).width / canvas.width;
		for (const line of element.textdata.lines) {
			const lineWidth = ctx.measureText(line).width;
			if (lineWidth > maxWidth) {
				maxWidth = lineWidth;
			}
		}
		const newWidth = (maxWidth + 60) / canvas.width;
		const newHeight = this.computeHeight();

		const newElement: ElementEdit = {
			...element,
			size: [newWidth, newHeight],
		};

		ctx.restore();
		return newElement;
	};
}

const dummyElementEdit: ElementEdit = {
	id: GigaUserApi.Id("dummy"),
	geo: "text",
	startTime: 0,
	endTime: 0,
	position: [0, 0],
	size: [0, 0],
};

export default new MutableTextbox(dummyElementEdit, undefined);
