const fullScreenIcon = (color?: string) => {
	const fill = color || "#000000";

	return (
		<svg
			width="100#"
			height="100#"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M16.875 3.75V7.5C16.875 7.66576 16.8092 7.82473 16.6919 7.94194C16.5747 8.05915 16.4158 8.125 16.25 8.125C16.0842 8.125 15.9253 8.05915 15.8081 7.94194C15.6908 7.82473 15.625 7.66576 15.625 7.5V5.25859L11.6922 9.19219C11.5749 9.30946 11.4159 9.37535 11.25 9.37535C11.0841 9.37535 10.9251 9.30946 10.8078 9.19219C10.6905 9.07491 10.6247 8.91585 10.6247 8.75C10.6247 8.58415 10.6905 8.42509 10.8078 8.30781L14.7414 4.375H12.5C12.3342 4.375 12.1753 4.30915 12.0581 4.19194C11.9408 4.07473 11.875 3.91576 11.875 3.75C11.875 3.58424 11.9408 3.42527 12.0581 3.30806C12.1753 3.19085 12.3342 3.125 12.5 3.125H16.25C16.4158 3.125 16.5747 3.19085 16.6919 3.30806C16.8092 3.42527 16.875 3.58424 16.875 3.75ZM8.30781 10.8078L4.375 14.7414V12.5C4.375 12.3342 4.30915 12.1753 4.19194 12.0581C4.07473 11.9408 3.91576 11.875 3.75 11.875C3.58424 11.875 3.42527 11.9408 3.30806 12.0581C3.19085 12.1753 3.125 12.3342 3.125 12.5V16.25C3.125 16.4158 3.19085 16.5747 3.30806 16.6919C3.42527 16.8092 3.58424 16.875 3.75 16.875H7.5C7.66576 16.875 7.82473 16.8092 7.94194 16.6919C8.05915 16.5747 8.125 16.4158 8.125 16.25C8.125 16.0842 8.05915 15.9253 7.94194 15.8081C7.82473 15.6908 7.66576 15.625 7.5 15.625H5.25859L9.19219 11.6922C9.30946 11.5749 9.37535 11.4159 9.37535 11.25C9.37535 11.0841 9.30946 10.9251 9.19219 10.8078C9.07491 10.6905 8.91585 10.6247 8.75 10.6247C8.58415 10.6247 8.42509 10.6905 8.30781 10.8078Z"
				fill={color}
			/>
		</svg>
	);
};

export default fullScreenIcon;
