import React, { useContext, useEffect, useRef, useState } from "react";
import HelpCenterHome from "./HelpCenterHome/main/HelpCenterHome";
import {
	HelpCenterProps,
	Navigate,
} from "@gigauser/common/src/help-center/HelpCenter/HelpCenter";
import logger from "@gigauser/common/src/utils/logger";
import { isProdDomain } from "@gigauser/common/src/help-center/utils/routeUtils";
import { HelpCenterPage } from "../../App";
import HelpCenterBrowser from "./HelpCenterBrowser/main/HelpCenterBrowser";
import ExtHelpCenterContext from "./ExtHelpCenterContext";
import HelpCenterSearchBox from "./HelpCenterSearchBox/HelpCenterSearchBox";
import { useAppSelector, useAppDispatch } from "../../redux";
import HelpCenterUnlisted from "./HelpCenterUnlisted/HelpCenterUnlisted";
import { HelpCenterConfig } from "@giga-user-fern/api/types/api";
import orgSetupData from "@gigauser/common/src/setup/orgSetupData";
import { saver } from "@gigauser/common/src/network/saver";
import { setTheme } from "../../redux/slices/hcUiSlice";
import { captureEventInPanchayat } from "@gigauser/common/src/utils/panchayat";
import useScrollTracker from "@gigauser/common/src/hooks/useScrollTracker";

type ExtHelpCenterProps = {
	page: HelpCenterPage;
	helpCenterConfig: HelpCenterConfig;
	helpCenterProps: HelpCenterProps;
};

const ExtHelpCenter: React.FC<ExtHelpCenterProps> = (props) => {
	let navigate: Navigate | undefined;
	const extHelpCenterRef = useRef<HTMLDivElement>(null);

	const helpCenterContext = useContext(ExtHelpCenterContext);

	if (helpCenterContext.navigator) {
		navigate = helpCenterContext.navigator();
	}

	const isSearchVisible = useAppSelector((state) => state.hcUi.searchVisible);

	const appTheme = useAppSelector((state) => state.hcUi.theme);

	const guide = useAppSelector((state) => state.hc.currentGuide);

	const sendAnalytics = (percentage: number, guideId: string) => {
		const milestones = [10, 25, 33, 50, 66, 75, 100];

		if (milestones.includes(percentage) && guideId) {
			captureEventInPanchayat({
				eventName: "GuideScrolledInHelpCenter",
				value: {
					guide_id: guideId,
					organization_id: props.helpCenterProps
						.getOrganization()
						?.id.toString() as string,

					session_id: props.helpCenterProps
						.helpCenterSessionId as string,
					scroll_percentage: percentage,
				},
			});
		}
	};

	// Use the scroll tracking hook
	useScrollTracker(
		extHelpCenterRef,
		sendAnalytics,
		guide?.id.toString() as string,
	);

	return (
		<div
			className={`ExtHelpCenter-wrapper ${appTheme}`}
			ref={extHelpCenterRef}
		>
			{isSearchVisible ? (
				<HelpCenterSearchBox
					page={props.page}
					helpCenterProps={props.helpCenterProps}
				/>
			) : null}
			{props.page === "Browser" ? (
				<HelpCenterBrowser
					helpCenterConfig={props.helpCenterConfig}
					helpCenterProps={props.helpCenterProps}
				/>
			) : props.page === "Home" ? (
				<HelpCenterHome helpCenterProps={props.helpCenterProps} />
			) : (
				<HelpCenterBrowser
					helpCenterConfig={props.helpCenterConfig}
					viewOnly
					helpCenterProps={props.helpCenterProps}
				/>
			)}
		</div>
	);
};
export default ExtHelpCenter;
