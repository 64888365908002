import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { GigaUserApi } from "@giga-user-fern/api";

// export type PlatformHomePage =
// 	| "home"
//     | "projects"
// 	| "bin"
// 	| "organization"
// 	| "anal"
// 	| "admin"
// 	| "hosting"
// 	| "help-center-config"
// 	| "settings"
// 	| "usage";

export type VideoCustomizerPage =
	| "Intro"
	| "Background"
	| "Outro"
	| "Zooms"
	| "Elements"
	| "Music"
	| "Voiceover"
	| "Outro_Transitions"
	| "Intro_Transitions"
	| "Transitions";
export type ActiveFormat = "Video" | "Article";

type PlatformUiState = {
	value: {
		// homePage: PlatformHomePage;
		activeFormat: ActiveFormat;
		videoUploadProgress: number | null;
		customizerPage: VideoCustomizerPage | null;
		activeZoom: GigaUserApi.Id | null;
		activeElement: GigaUserApi.Id | null;
		hardRefreshTranscript: boolean;
		exportSrtFile: boolean;

		activeIntroTemplate: GigaUserApi.Id | null;
		activeOutroTemplate: GigaUserApi.Id | null;
		activeBackgroundTemplate: GigaUserApi.Id | null;
		activeCoverElement: GigaUserApi.Id | null;
		activeArea: "video" | "element" | "background" | null;
		activeElementDefaultTab: "design" | "animation";
		elementsCustomizerDefaultTab: "add-elements" | "my-elements" | null;
		activeTransitionCustomizerTab: "intro" | "outro" | null;
	};
};

const initialState: PlatformUiState = {
	value: {
		// homePage: "home",
		activeFormat: "Video",
		videoUploadProgress: null,
		customizerPage: "Voiceover",
		activeZoom: null,
		activeElement: null,
		hardRefreshTranscript: false,
		exportSrtFile: false,
		activeIntroTemplate: GigaUserApi.Id(""),
		activeOutroTemplate: GigaUserApi.Id(""),
		activeBackgroundTemplate: GigaUserApi.Id(""),
		activeCoverElement: null,
		activeArea: null,
		activeElementDefaultTab: "design",
		elementsCustomizerDefaultTab: "add-elements",
		activeTransitionCustomizerTab: "intro",
	},
};

export const platformUiSlice = createSlice({
	name: "platformUi",
	initialState: initialState,
	reducers: {
		// setHomePage: (state, action: PayloadAction<PlatformHomePage>) => {
		// 	state.value.homePage = action.payload;
		// },

		setActiveFormat: (state, action: PayloadAction<ActiveFormat>) => {
			state.value.activeFormat = action.payload;
		},

		setCustomizerPage: (
			state,
			action: PayloadAction<VideoCustomizerPage | null>,
		) => {
			state.value.customizerPage = action.payload;
		},

		setActiveZoom: (
			state,
			action: PayloadAction<GigaUserApi.Id | null>,
		) => {
			state.value.activeZoom = action.payload;
		},

		setActiveElement: (
			state,
			action: PayloadAction<GigaUserApi.Id | null>,
		) => {
			state.value.activeCoverElement = null;
			state.value.activeElement = action.payload;
		},

		setActiveArea: (
			state,
			action: PayloadAction<"video" | "element" | "background" | null>,
		) => {
			state.value.activeArea = action.payload;
		},

		setActiveElementDefaultTab: (
			state,
			action: PayloadAction<"design" | "animation">,
		) => {
			state.value.activeElementDefaultTab = action.payload;
		},

		setActiveTransitionCustomizerTab: (
			state,
			action: PayloadAction<"intro" | "outro" | null>,
		) => {
			state.value.activeTransitionCustomizerTab = action.payload;
		},

		setElementsCustomizerDefaultTab: (
			state,
			action: PayloadAction<"add-elements" | "my-elements" | null>,
		) => {
			state.value.elementsCustomizerDefaultTab = action.payload;
		},

		setActiveCoverElement: (
			state,
			action: PayloadAction<GigaUserApi.Id | null>,
		) => {
			state.value.activeElement = null;
			state.value.activeCoverElement = action.payload;
		},

		setHardRefreshTranscript: (state, action: PayloadAction<boolean>) => {
			state.value.hardRefreshTranscript = action.payload;
		},

		setExportSrtFile: (state, action: PayloadAction<boolean>) => {
			state.value.exportSrtFile = action.payload;
		},

		setVideoUploadProgress: (state, action: PayloadAction<number>) => {
			state.value.videoUploadProgress = action.payload;
		},

		setActiveIntroTemplate: (
			state,
			action: PayloadAction<GigaUserApi.Id | null>,
		) => {
			state.value.activeIntroTemplate =
				action.payload || GigaUserApi.Id("");
		},

		setActiveOutroTemplate: (
			state,
			action: PayloadAction<GigaUserApi.Id | null>,
		) => {
			state.value.activeOutroTemplate =
				action.payload || GigaUserApi.Id("");
		},

		setActiveBackgroundTemplate: (
			state,
			action: PayloadAction<GigaUserApi.Id | null>,
		) => {
			state.value.activeBackgroundTemplate =
				action.payload || GigaUserApi.Id("");
		},
	},
});

export const {
	setActiveBackgroundTemplate,
	setActiveIntroTemplate,
	setActiveOutroTemplate,
	setVideoUploadProgress,
	setExportSrtFile,
	setActiveZoom,
	setActiveElement,
	setActiveArea,
	setHardRefreshTranscript,
	// setHomePage,
	setCustomizerPage,
	setActiveCoverElement,
	setActiveFormat,
	setActiveElementDefaultTab,
	setElementsCustomizerDefaultTab,
	setActiveTransitionCustomizerTab,
} = platformUiSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export default platformUiSlice.reducer;
