import { ElementEdit } from "@giga-user-fern/api/types/api";
import { FractionalCoordsToCanvasCoordsFunction } from "../canvasUtils";
import { CanvasImageAsset } from "../immutables/assets/CanvasAssets";
import { canvasPrintText } from "./canvasTextbox";

type DrawElementsProps = {
	allEleEdits: ElementEdit[];
	canvas: HTMLCanvasElement;
	fractionalCoordsToCanvasCoords: FractionalCoordsToCanvasCoordsFunction;
	showLogo: boolean;
	getFont: (name: string | undefined) => string;
	cp?: any;
	imageDataMap: Map<string, CanvasImageAsset>;
};

export const drawCoverElements = (props: DrawElementsProps) => {
	//this is used only in covers.
	const { allEleEdits, canvas, fractionalCoordsToCanvasCoords } = props;

	let eleList: ElementEdit[] = [];

	eleList = allEleEdits;
	const elementEdits = eleList;

	if (!canvas) return;

	const ctx = canvas?.getContext("2d") as CanvasRenderingContext2D;

	const mediaRenderHeight = canvas.height;
	const mediaRenderWidth = canvas.width;

	if (elementEdits.length) {
		for (const ele of elementEdits) {
			const { position, size, geo } = ele;
			const [x, y] = position;
			const [width, height] = size;

			const rect_coords = fractionalCoordsToCanvasCoords(
				{ x, y },
				canvas,
			);

			const rectX = rect_coords.x;
			const rectY = rect_coords.y;
			const rectWidth = width * mediaRenderWidth; ///adj_zf
			const rectHeight = height * mediaRenderHeight; // / adj_zf;

			ctx.save();

			if (geo === "text") {
				if (!canvas) return;
				const attributeWidth = canvas.width;

				let displayedWidth: number;
				try {
					displayedWidth = canvas.getBoundingClientRect().width;
				} catch (_) {
					displayedWidth = attributeWidth;
				}
				const scalingFactor = attributeWidth / displayedWidth;

				if (ele.textdata) {
					canvasPrintText(
						canvas,
						{
							x: rectX,
							y: rectY,
							w: rectWidth,
							h: rectHeight,
							scalingFactor,
						},
						ele,
						ele.textdata.font ?? "Inter",
						props.getFont,
					);
				}
			} else if (geo === "image") {
				if (ele.imagedata?.src) {
					const image = props.imageDataMap.get(ele.id);
					if (image) {
						const { naturalHeight, naturalWidth } = ele.imagedata;
						const image_height =
							rectWidth * (naturalHeight / naturalWidth);
						ctx.drawImage(
							image.src,
							rectX,
							rectY,
							rectWidth,
							image_height,
						);
					}
				}
			}

			ctx.restore();
		}
	}

	return elementEdits;
};
