const circledPlayIcon = (color = "#ffffff") => {
	return (
		<svg
			width="100%"
			height="100%"
			viewBox="0 0 12 12"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M11.25 5.99975C11.2503 6.12707 11.2177 6.25231 11.1552 6.36328C11.0928 6.47425 11.0027 6.56718 10.8937 6.63303L4.14 10.7646C4.02613 10.8343 3.89572 10.8724 3.76222 10.8749C3.62873 10.8773 3.49699 10.8441 3.38063 10.7787C3.26536 10.7142 3.16935 10.6202 3.10245 10.5064C3.03556 10.3925 3.00019 10.2629 3 10.1308V1.86865C3.00019 1.7366 3.03556 1.60698 3.10245 1.49312C3.16935 1.37927 3.26536 1.28528 3.38063 1.22084C3.49699 1.15538 3.62873 1.12217 3.76222 1.12464C3.89572 1.12711 4.02613 1.16518 4.14 1.2349L10.8937 5.36646C11.0027 5.43231 11.0928 5.52524 11.1552 5.63621C11.2177 5.74718 11.2503 5.87242 11.25 5.99975Z"
				fill={color}
			/>
		</svg>
	);
};
export default circledPlayIcon;
