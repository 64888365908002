import React, { useContext, useEffect, useRef, useState } from "react";
import { HelpCenterPage } from "../../../App";
import "./HelpCenterSearchBox.css";
import Icon from "@gigauser/common/src/ui/Icon/Icon";
import searchIcon from "@gigauser/common/src/assets/svgs/searchIcon";
import { getBrandingColor } from "@gigauser/common/src/core/utils/styleUtils";
import ExtHelpCenterContext from "../ExtHelpCenterContext";
import { captureEvent } from "@gigauser/common/src/core/analytics/analytics";
import { GuidePreview } from "@gigauser/common/src/core/types/guide";
import LoadingRing from "@gigauser/common/src/assets/gifs/LoadingRing/LoadingRing";
import { helpSaver as saver } from "@gigauser/common/src/network/helpcenterSaver";

import documentIcon from "@gigauser/common/src/assets/svgs/documentIcon";
import downArrowheadIcon from "@gigauser/common/src/assets/svgs/downArrowheadIcon";
import { useAppDispatch, useAppSelector } from "../../../redux";
import { setSearchVisible } from "../../../redux/slices/hcUiSlice";
import useOpenGuide from "../../../hooks/useOpenGuide";
import { rootCollection } from "@gigauser/common/src/types/files";
import { Collection } from "@gigauser/common/src/core/types/collections";
import useRunDebouncedSearch from "../../../hooks/useRunDebouncedSearch";
import orgSetupData from "@gigauser/common/src/setup/orgSetupData";
import { HelpCenterProps } from "@gigauser/common/src/help-center/HelpCenter/HelpCenter";
import { captureEventInPanchayat } from "@gigauser/common/src/utils/panchayat";

type HelpCenterSearchBoxProps = {
	page: HelpCenterPage;
	helpCenterProps: HelpCenterProps;
};

const HelpCenterSearchBox: React.FC<HelpCenterSearchBoxProps> = (
	props: HelpCenterSearchBoxProps,
) => {
	const helpCenterContext = useContext(ExtHelpCenterContext);
	const [loadingSearch, setLoadingSearch] = useState(false);

	const [searchString, setSearchString] = useState("");
	const [searchResults, setSearchResults] = useState<GuidePreview[]>([]);

	const openGuide = useOpenGuide();

	const searchInputRef = useRef<HTMLInputElement>(null);

	const isVisible = useAppSelector((state) => state.hcUi.searchVisible);

	const dispatch = useAppDispatch();

	const runDebouncedSearch = useRunDebouncedSearch();

	const setLoading = (b: boolean) => {
		setLoadingSearch(b);
	};

	const setResults = (r: GuidePreview[]) => {
		setSearchResults(r);
	};

	const onChangeSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearchString(e.target.value);

		runDebouncedSearch({
			searchString: e.target.value,
			setLoading,
			setResults,
			helpCenterProps: props.helpCenterProps,
		});
	};

	const closeSearch = () => {
		dispatch(setSearchVisible(false));
	};

	useEffect(() => {
		if (searchInputRef.current) {
			searchInputRef.current.focus();
		}
	}, [isVisible]);

	const appTheme = useAppSelector((state) => state.hcUi.theme);

	if (saver.organization?.id && orgSetupData[saver.organization?.id]) {
		var searchTitle = orgSetupData[saver.organization?.id]?.searchTitle;
	}

	if (!isVisible) return null;

	return (
		<div className="HelpCenterSearchLayout">
			<div className="HelpCenterSearchBlur" onClick={closeSearch}>
				.
			</div>

			<div className={`HelpCenterSearchBox`}>
				<div className="searchinput-container">
					{loadingSearch || false ? (
						<LoadingRing color={getBrandingColor(appTheme)} />
					) : (
						<Icon className="searchIcon">
							{searchIcon(getBrandingColor(appTheme))}
						</Icon>
					)}

					<input
						className="search-input"
						placeholder={searchTitle || "Search for help ..."}
						value={searchString}
						onChange={onChangeSearchInput}
						ref={searchInputRef}
					/>
				</div>

				{searchResults ? (
					<div className="searchresults-container">
						{searchResults.map((guidePreview) => {
							const onOpen = async () => {
								closeSearch();
								console.log(
									"going to open!",
									guidePreview.id.toString(),
								);
								captureEventInPanchayat({
									eventName: "GuideClickedInSearchResults",
									value: {
										guide_id_clicked:
											guidePreview.id.toString(),
										session_id: props.helpCenterProps
											.helpCenterSessionId as string,
										organization_id: props.helpCenterProps
											.getOrganization()
											?.id.toString() as string,
									},
								});

								var path: [Collection, ...Collection[]] = [
									rootCollection,
								];
								if (guidePreview.parentId) {
									const res = await helpCenterContext.getPath(
										guidePreview.parentId,
									);
									if (res) path = res;
								}

								if (path) {
									openGuide(guidePreview, path);
								}
							};

							return (
								<div
									className="ext-searchResult"
									key={guidePreview.id}
									onClick={onOpen}
								>
									<Icon className="ext-searchResult-icon">
										{documentIcon(
											getBrandingColor(appTheme),
										)}
									</Icon>
									<div className="ext-searchResult-text">
										<div className="ext-searchResult-title">
											{guidePreview.header.name}{" "}
										</div>
										<div className="ext-searchResult-desc">
											{guidePreview.header.description}{" "}
										</div>
									</div>

									<Icon className="ext-searchResult-arrow">
										{downArrowheadIcon(
											appTheme === "gigauser-light"
												? "white"
												: "#0e1117",
										)}
									</Icon>
								</div>
							);
						})}
					</div>
				) : null}
			</div>
		</div>
	);
};
export default HelpCenterSearchBox;
