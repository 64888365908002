import React, { useContext, useEffect, useRef, useState } from "react";
import "./CanvasPlayer.css";
import { calculateMediaDuration } from "../canvasUtils/videoUtils";
import CanvasPlayerContext from "../CanvasPlayerContext";
import { Coordinates } from "@giga-user-fern/api/types/api/resources/baseTypes/types";
import {
	setActiveElement,
	setActiveCoverElement,
	setCustomizerPage,
	setActiveArea,
	setActiveElementDefaultTab,
} from "../../../redux/slices/platformUiSlice";
import { useAppDispatch, useAppSelector } from "../../../redux";
import SizeControllerThumb, {
	SizeControllerPos,
} from "../../../components/formats/RichText/components/EditScreenshot/components/SizeController/SizeControllerThumb";
import {
	UpdateShapeFunction,
	UpdateShapeProperties,
} from "../../../components/formats/RichText/components/EditScreenshot/ScreenshotEditorContext";
import {
	updateElement,
	updateIntroEdits,
	updateIntroElement,
	updateOutroEdits,
	updateOutroElement,
} from "../../../redux/slices/guideSlice";
import { GigaUserApi } from "@giga-user-fern/api";
import DragController from "../../../components/formats/RichText/components/EditScreenshot/components/SizeController/DragController";
import { Spinner } from "@chakra-ui/react";
import { selectOverlay } from "../../../layouts/Overlay/overlaySlice";
import cr from "../canvasUtils/immutables/CanvasRenderer";
import { MutableTextbox } from "../canvasUtils/mutables/elements/MutableTextbox";
import { CanvasCoordinates } from "../canvasUtils/immutables/coordinates/CanvasCoordinates";
import { GuideLines } from "./GuideLines";
import { selectFullScreenOverlay } from "../../../layouts/FullScreenOverlay/FullScreenOverlaySlice";
import { ElementEdit, ElementGeo } from "@giga-user-fern/api/types/api";
import { isElementWrtVideo } from "../canvasUtils/canvasUtils";
import { MutableElement } from "../canvasUtils/mutables/elements/MutableElement";
import { migrateCover } from "../canvasUtils/migrations/migrateCover";
import { PADDING_H } from "../canvasUtils/immutables/framer/CanvasElements";
import { PADDING_W } from "../canvasUtils/immutables/framer/CanvasElements";
import { extractFontsRequired } from "../../../utils/fontsUtils";
import { loadAllFonts } from "../../../utils/fontsUtils";

export type CanvasPlayerProps = {
	//OPTIONAL
	onLoadedVideo?: () => void;
	onError?: () => void;
	onTimeUpdate?: () => void;
	onEnded?: () => void;
};

export const ZOOM_TRANSITION_TIME = 1.2;

const CanvasPlayer: React.FC<CanvasPlayerProps> = (props) => {
	const cp = useContext(CanvasPlayerContext);

	const [currentZoom, setCurrentZoom] = useState<{
		zoomFactor: number;
		e: number;
		f: number;
		zoomCenter: Coordinates;
	} | null>(null);

	const dispatch = useAppDispatch();

	const currentElements = useRef<ElementEdit[]>([]);
	const oldCoordinatesRef = useRef<CanvasCoordinates | null>(null);
	const [hoveredArea, setHoveredArea] = useState<
		"background" | "video" | "element" | null
	>(null);
	const activeArea = useAppSelector(
		(state) => state.platformUi.value.activeArea,
	);

	const [_, setCanvasRenderer] = useState<any>(null);

	const [isElementNearCenter, _setIsElementNearCenter] = useState({
		vertical: false,
		horizontal: false,
	});

	const [hoverElementId, setHoverElementId] = useState<GigaUserApi.Id | null>(
		null,
	);
	const activeElementId = useAppSelector(
		(state) => state.platformUi.value.activeElement,
	);

	const activeCoverElementId = useAppSelector(
		(state) => state.platformUi.value.activeCoverElement,
	);

	const [verticalElementGuideLine, setVerticalElementGuideLine] = useState({
		startCoord: 0,
		endCoord: 0,
		baseCoord: 0,
	});

	const [horizontalElementGuideLine, setHorizontalElementGuideLine] =
		useState({
			startCoord: 0,
			endCoord: 0,
			baseCoord: 0,
		});

	const isOverlayPresent = useAppSelector(selectOverlay).overlay;
	const isFullScreenOverlayPresent = useAppSelector(selectFullScreenOverlay);
	const isModalPresent = useAppSelector((state) => state.modal.isOpen);

	const isOverlayOpen =
		isOverlayPresent || isFullScreenOverlayPresent || isModalPresent;

	const videoEdits = cp.videoEdits;
	const fonts = extractFontsRequired(videoEdits);

	let activeElement: ElementEdit | null = null;
	let hoverElement: ElementEdit | null = null;

	if (videoEdits.elements) {
		activeElement =
			videoEdits.elements.find((e) => e.id === activeElementId) ||
			activeElement;
		hoverElement =
			videoEdits.elements.find((e) => e.id === hoverElementId) ||
			hoverElement;
	}

	if (videoEdits.intro?.coverEdits) {
		activeElement =
			videoEdits.intro.coverEdits.find(
				(e) => e.id === activeCoverElementId,
			) || activeElement;
		hoverElement =
			videoEdits.intro.coverEdits.find((e) => e.id === hoverElementId) ||
			hoverElement;
	}

	if (videoEdits.outro?.coverEdits) {
		activeElement =
			videoEdits.outro.coverEdits.find(
				(e) => e.id === activeCoverElementId,
			) || activeElement;
		hoverElement =
			videoEdits.outro.coverEdits.find((e) => e.id === hoverElementId) ||
			hoverElement;
	}

	let animationFrameId = 0;
	// var ctx:CanvasRenderingContext2D

	const vidRef = cp.vidRef;
	const canvasRef = cp.canvasRef;

	if (!vidRef) throw new Error("Somehow missing vidRef in CanvasPlayer");

	//#region CANVAS DIMENSIONS

	const renderer = cp.initRenderer();

	const videoWidth = vidRef.current?.videoWidth ?? 1920;
	const videoHeight = vidRef.current?.videoHeight ?? 1080;

	const paddingFactor = 0;

	const [canvasWidth, setCanvasWidth] = useState(
		videoWidth + paddingFactor * videoWidth,
	);
	const [canvasHeight, setCanvasHeight] = useState(
		videoHeight + paddingFactor * videoWidth,
	);

	const initCanvasDims = (width: number, height: number) => {
		if (width && height) {
			setCanvasWidth(width);
			setCanvasHeight(height);
		}
	};

	const renderCanvas = async () => {
		const r = cp.initRenderer();

		const screenclipStartTime = r.timer.getScreenclipStartTime();
		const screenclipEndTime = r.timer.getScreenclipEndTime();

		if (screenclipStartTime === 0 && screenclipEndTime === 0) {
			//renderer is dummy
			return;
		}

		const currentTime = cp.currentTimeRef?.current ?? 0;
		const res = r.framer.render(currentTime);

		if (res.zoom) {
			const { zoomCenter, zoomFactor } = res.zoom;
			const zoom_canvas_coords =
				r.coordinates.fractionalCoordsToCanvasCoords(zoomCenter, true);
			const e = zoom_canvas_coords.x * (1 - zoomFactor);
			const f = zoom_canvas_coords.y * (1 - zoomFactor);
			setCurrentZoom({ zoomFactor, e, f, zoomCenter });
		} else {
			if (currentZoom !== null) {
				setCurrentZoom(null);
			}
		}

		if (
			cp.currentTime >= screenclipEndTime &&
			cp.videoEdits.outro?.visible
		) {
			currentElements.current = cp.videoEdits.outro?.coverEdits ?? [];
		} else if (
			cp.currentTime < screenclipStartTime &&
			cp.videoEdits.intro?.visible
		) {
			currentElements.current = cp.videoEdits.intro?.coverEdits ?? [];
		} else {
			const tempCurrentElements: ElementEdit[] = [];
			for (const ele of videoEdits.elements ?? []) {
				if (
					ele.startTime <=
						r.timer.getUnadjustedTime(cp.currentTime) &&
					ele.endTime >= r.timer.getUnadjustedTime(cp.currentTime)
				) {
					tempCurrentElements.push(ele);
				}
			}
			currentElements.current = tempCurrentElements;
		}
	};

	//#region useEffects

	useEffect(() => {
		//without this hot reload is not working for some reason.
		setCanvasRenderer(cr);
	}, []);

	useEffect(() => {
		/**
		 * Dimensions are changing.
		 */

		const { canvasWidth, canvasHeight } = renderer.coordinates;
		initCanvasDims(canvasWidth, canvasHeight);
	}, [
		renderer.coordinates.canvasWidth,
		renderer.coordinates.canvasHeight,
		renderer.coordinates.videoRenderWidth,
		renderer.coordinates.videoRenderHeight,
	]);

	useEffect(() => {
		const r = cp.initRenderer();
		oldCoordinatesRef.current = r.coordinates;

		renderCanvas();

		if (!cp.paused) {
			cp.pause();
		}
	}, [cp.videoEdits]);

	useEffect(() => {
		if (cp.paused) {
			// cancelAnimationFrame(animationFrameId)
			cp.initRenderer();
			renderCanvas();
		}
	}, [
		cp.currentTime,
		cp.videoEdits,
		// cp.videoEdits, videoWidth, videoHeight,
		canvasHeight,
		canvasWidth,
	]);

	useEffect(() => {
		if (vidRef.current) {
			calculateMediaDuration(vidRef.current).then((duration) => {
				if (props.onLoadedVideo) {
					props.onLoadedVideo();
				}
			});
		}
	}, [vidRef]);

	useEffect(() => {
		if (!isOverlayOpen) {
			dispatch(setActiveElement(null));
		}
	}, [isOverlayOpen]);

	useEffect(() => {
		//This is because when background visibility (canvas width) is changed,
		//The width of text is calculated wrong for some reason.
		renderCanvas();
	}, [cp.videoEdits.background?.visible]);

	useEffect(() => {
		if (!cp.paused) startRendering();
	}, [cp.paused]);

	useEffect(() => {
		const vidRef = cp.vidRef?.current;
		if (!cp.loading && vidRef) {
			// DONT REMOVE THIS CODE! DOUBLE TEXT WILL COME OTHERWISE
			// Check if the fonts are loaded, and if not, wait for them
			if ((document as any).fonts) {
				(document as any).fonts.ready.then(() => {
					// Fonts are loaded, so now we can render the canvas
					if (!canvasRef?.current) return;
					renderCanvas();
				});
			} else {
				// The Font Loading API isn't supported, so just render (this is a fallback)
				renderCanvas();
			}
		}
		// cover edits are added because clicking on a same template again doesnt lead to a load even if leads to change in cover edits
	}, [cp.loading]);

	useEffect(() => {
		if (!canvasRef?.current) return;
		if (canvasRef.current.height === 0) return;
		renderCanvas();
	}, [
		videoEdits.intro?.coverEdits,
		videoEdits.outro?.coverEdits,
		canvasHeight,
		canvasWidth,
	]);

	//#endregion useEffects

	//#region COORDINATE SYSTEM CONVERTERS

	const pixelsToFractionalCoords: (
		pos: Coordinates,
		options?: {
			difference?: boolean;
			wrtVideo?: boolean;
		},
	) => Coordinates = (
		pos,
		options = {
			difference: false,
			wrtVideo: false,
		},
	) => {
		/**
		 * @param difference : to be used when the coordinates input are a difference between two points and not an absolute coordinate value
		 * @param wrtVideo: if true, the returned fractional value is with respect to the video and not the full canvas
		 */

		const { difference, wrtVideo } = options;

		const canvas = canvasRef?.current;
		const video = vidRef.current;

		if (!canvas || !video) return pos;

		const rect = canvas.getBoundingClientRect();

		const scaleDownFactor_w = canvas.width / rect.width;
		const scaleDownFactor_h = canvas.height / rect.height;

		const canvasPixelWidth = rect.width;
		const videoPixelWidth =
			renderer.coordinates.videoRenderWidth / scaleDownFactor_w;

		const canvasPixelHeight = rect.height;
		const videoPixelHeight =
			renderer.coordinates.videoRenderHeight / scaleDownFactor_h;

		let pw = 0;
		let ph = 0;

		if (!difference && wrtVideo) {
			//we need to factor in the padding between canvas and video
			pw = (canvasPixelWidth - videoPixelWidth) / 2;
			ph = (canvasPixelHeight - videoPixelHeight) / 2;
		}

		const x_f =
			(pos.x - pw) / (wrtVideo ? videoPixelWidth : canvasPixelWidth);
		const y_f =
			(pos.y - ph) / (wrtVideo ? videoPixelHeight : canvasPixelHeight);

		const f = { x: x_f, y: y_f };
		return f;
	};

	const fractionalCoordsToPixels: (
		pos_f: Coordinates,
		wrtVideo?: boolean,
	) => Coordinates = (pos_f, wrtVideo = false) => {
		const canvas = canvasRef?.current;
		const video = vidRef.current;

		if (!canvas || !video) return pos_f;

		const rect = canvas.getBoundingClientRect();

		const scaleDownFactor_w = canvas.width / rect.width;
		const scaleDownFactor_h = canvas.height / rect.height;

		const canvasPixelWidth = rect.width;
		const videoPixelWidth =
			renderer.coordinates.videoRenderWidth / scaleDownFactor_w;

		const canvasPixelHeight = rect.height;
		const videoPixelHeight =
			renderer.coordinates.videoRenderHeight / scaleDownFactor_h;

		const x_p = wrtVideo
			? pos_f.x * videoPixelWidth +
				(canvasPixelWidth - videoPixelWidth) / 2
			: pos_f.x * canvasPixelWidth;

		const y_p = wrtVideo
			? pos_f.y * videoPixelHeight +
				(canvasPixelHeight - videoPixelHeight) / 2
			: pos_f.y * canvasPixelHeight;

		return { x: x_p, y: y_p };
	};

	//#endregion

	const startRendering = () => {
		if (animationFrameId === 0) {
			realTimeRender(); // Start rendering when video is played
		}
	};

	const handleMouseMove = (
		e: React.MouseEvent<HTMLCanvasElement, MouseEvent>,
	) => {
		const canvas = canvasRef?.current;
		if (!canvas) {
			return;
		}

		const rect = canvas.getBoundingClientRect();
		const x = e.clientX - (rect?.left ?? 0);
		const y = e.clientY - (rect?.top ?? 0);

		const videoTopLeft = fractionalCoordsToPixels(
			{
				x: 0,
				y: 0,
			},
			true,
		);

		const videoBottomRight = fractionalCoordsToPixels(
			{
				x: 1,
				y: 1,
			},
			true,
		);

		const isHoveringVideo =
			x <= videoBottomRight.x &&
			x >= videoTopLeft.x &&
			y <= videoBottomRight.y &&
			y >= videoTopLeft.y &&
			!isCurrentTimeInIntroOrOutro();

		let isHoveringElement = false;

		for (const element of currentElements.current) {
			const wrtVideo = isElementWrtVideo(element);

			const fracCoords = pixelsToFractionalCoords(
				{ x, y },
				{
					difference: false,
					wrtVideo: wrtVideo,
				},
			);

			const { size } = element;
			const position = new MutableElement(element).getPosition();
			const x_f = fracCoords.x;
			const y_f = fracCoords.y;

			const zoomFactor = currentZoom?.zoomFactor ?? 1;
			const zoomCenter = currentZoom?.zoomCenter ?? { x: 0.5, y: 0.5 };

			const w_f = size[0];
			const h_f = size[1];

			// check if the element is flipped vertically or horizontally
			const isFlippedVertically = h_f < 0;
			const isFlippedHorizontally = w_f < 0;

			const size_x = Math.abs(w_f) * zoomFactor;
			const size_y =
				element.geo === "image" && element.imagedata
					? (() => {
							const { naturalHeight, naturalWidth } =
								element.imagedata;
							const w_p = fractionalCoordsToPixels({
								x: Math.abs(w_f),
								y: Math.abs(h_f),
							}).x;
							const h_p = (w_p * naturalHeight) / naturalWidth;
							const adjusted_h_f = pixelsToFractionalCoords(
								{ x: w_p, y: h_p },
								{
									difference: true,
									wrtVideo: isElementWrtVideo(element),
								},
							).y;
							return Math.abs(adjusted_h_f) * zoomFactor;
						})()
					: Math.abs(h_f) * zoomFactor;

			let position_x =
				position.x * zoomFactor + zoomCenter.x * (1 - zoomFactor);
			let position_y =
				position.y * zoomFactor + zoomCenter.y * (1 - zoomFactor);

			// if the element is flipped horizontally, move the x position to the left
			if (isFlippedHorizontally) {
				position_x -= size_x;
			}

			// if the element is flipped vertically, move the y position to the top
			if (isFlippedVertically) {
				position_y -= size_y;
			}

			const scaledPositionX = position_x;
			const scaledPositionY = position_y;

			// check if the mouse is hovering over the element with respect to the scaled position and size
			const isHovering =
				x_f >= scaledPositionX &&
				x_f <= scaledPositionX + size_x &&
				y_f >= scaledPositionY &&
				y_f <= scaledPositionY + size_y;

			if (isHovering) {
				setHoverElementId(element.id);
				setHoveredArea("element");
				isHoveringElement = true;

				break;
			}
		}

		if (!isHoveringElement) {
			if (isHoveringVideo) {
				setHoveredArea("video");
			} else {
				if (currentZoom) {
					const videoOverlay = getZoomedVideoOverlayStyle();
					const bgTopLeft = fractionalCoordsToPixels(
						{ x: 0, y: 0 },
						false,
					);
					const bgBottomRight = fractionalCoordsToPixels(
						{ x: 1, y: 1 },
						false,
					);
					const bgWidth = bgBottomRight.x - bgTopLeft.x;
					const bgHeight = bgBottomRight.y - bgTopLeft.y;
					if (
						videoOverlay.width >= bgWidth &&
						videoOverlay.height >= bgHeight
					) {
						setHoveredArea("video");
					} else {
						setHoveredArea("background");
					}
				} else {
					setHoveredArea("background");
				}
			}
		}
	};

	const realTimeRender = () => {
		/**
		 * Renders the canvas in real time as the video is playing.
		 * Stops rendering if video is paused.
		 * @note previously called renderVideoToCanvas (this is what its name
		 * still is on ChatGPT in case you look for it)
		 */

		const video = vidRef.current;
		const canvas = canvasRef?.current;

		if (
			!video ||
			!canvas ||
			!cp.currentTimeRef ||
			!cp.systemTimeAtPlayRef ||
			!cp.videoTimeAtPlayRef
		)
			return;

		if (video.readyState === 4) {
			renderCanvas();

			// if (vidRef.current && !vidRef.current?.paused) {
			//Here, if the main video is playing, we are going to set the time.
			//We need to set the time faster than onTimeUpdate, which is too infrequent.
			//NOTE: This logic, needs to accomodate clips/sections in the future.

			// const videoTime = renderer.timer.videoToTimelineTime(vidRef.current.currentTime);
			const currTime = Date.now();
			const newTime =
				cp.videoTimeAtPlayRef.current +
				(currTime - cp.systemTimeAtPlayRef.current) / 1000;
			cp.setCurrentTime(newTime);

			if (newTime >= renderer.timer.getVideoDuration()) {
				cp.pause();
			}

			// }
		}

		// If video is paused, stop rendering
		if (cp.pausedRef?.current) {
			if (animationFrameId) {
				cancelAnimationFrame(animationFrameId);
				animationFrameId = 0; // reset animationFrameId
			}
			return;
		} else {
			animationFrameId = requestAnimationFrame(realTimeRender);
		}
	};

	//#region COORDINATE SYSTEM CONVERTERS

	const scaleCoordinates: (c: Coordinates) => Coordinates = (c) => {
		// Takes as input canvas coords and returns as output canvas coords
		if (!currentZoom) return c;

		// Apply the zoom effect
		const [X, Y] = [c.x, c.y];
		const [A, B, C, D, E, F] = [
			currentZoom.zoomFactor,
			0,
			0,
			currentZoom.zoomFactor,
			currentZoom.e,
			currentZoom.f,
		];

		const transformedX = A * X + C * Y + E;
		const transformedY = B * X + D * Y + F;

		const transformedPoint = [transformedX, transformedY];

		return { x: transformedPoint[0], y: transformedPoint[1] };
	};

	const updateElementEdit: UpdateShapeFunction = (id, updatedProperties) => {
		const { size, position } = updatedProperties;
		let currentElement = null;

		if (videoEdits.elements) {
			currentElement =
				videoEdits.elements.find((e) => e.id === id) ?? currentElement;
		}
		if (videoEdits.intro?.coverEdits) {
			currentElement =
				videoEdits.intro.coverEdits.find((e) => e.id === id) ??
				currentElement;
		}
		if (videoEdits.outro?.coverEdits) {
			currentElement =
				videoEdits.outro.coverEdits.find((e) => e.id === id) ??
				currentElement;
		}

		if (!currentElement) return;

		let newElement: ElementEdit = { ...currentElement };

		for (const [key, value] of Object.entries(updatedProperties)) {
			(newElement as any)[key] = value;
		}

		//if the element is a textbox, we need to recalculate the lines
		const video = vidRef.current;
		const canvas = canvasRef?.current;

		if (!video || !canvas || !cp.currentTimeRef) return;

		//TODO: Here there is some error.
		if (
			currentElement.geo === "text" &&
			canvas &&
			currentElement.textdata &&
			(size || position)
		) {
			//resizeAndReposition
			const currentTextbox = new MutableTextbox(currentElement, canvas);
			const newTextbox = currentTextbox.resizeAndReposition({
				size,
				position,
			});
			if (newTextbox) {
				newElement = newTextbox;
			}
		}

		if (cp.currentTime < renderer.timer.getScreenclipStartTime()) {
			dispatch(updateIntroElement(newElement));
		} else if (cp.currentTime >= renderer.timer.getScreenclipEndTime()) {
			dispatch(updateOutroElement(newElement));
		} else {
			dispatch(updateElement(newElement));
		}
	};

	useEffect(() => {
		// ctx = canvasRef.current?.getContext('2d') as CanvasRenderingContext2D;

		return () => {
			cancelAnimationFrame(animationFrameId);
		};
	}, []);
	let hoverDivTopLeft = { x: 0, y: 0 };
	let hoverDivBotRight = { x: 0, y: 0 };
	let hoverDivWidth = 0;
	let hoverDivHeight = 0;
	let activeDivTopLeft = { x: 0, y: 0 };
	let activeDivBotRight = { x: 0, y: 0 };
	let activeDivWidth = 0;
	let activeDivHeight = 0;
	const primeElement = cp.fullscreen ? null : activeElement || hoverElement;

	if (hoverElement) {
		//text and image are wrt full canvas
		const wrtVideo = isElementWrtVideo(hoverElement);
		const hoverPos = new MutableElement(hoverElement).getPosition();

		const w = hoverElement.size[0];
		let h = hoverElement.size[1];
		// check if the element is flipped vertically or horizontally (added for arrow support)
		const isFlippedVertically = h < 0;
		const isFlippedHorizontally = w < 0;

		// if the element is flipped horizontally, add the width to the x position to get the top left position
		// if the element is flipped vertically, add the height to the y position to get the top left position
		const topLeftPos = {
			x: isFlippedHorizontally ? hoverPos.x + w : hoverPos.x,
			y: isFlippedVertically ? hoverPos.y + h : hoverPos.y,
		};

		// if the element is flipped horizontally, add the width to the x position to get the bottom right position
		// if the element is flipped vertically, add the height to the y position to get the bottom right position
		const bottomRightPos = {
			x: isFlippedHorizontally ? hoverPos.x : hoverPos.x + w,
			y: isFlippedVertically ? hoverPos.y : hoverPos.y + h,
		};

		hoverDivTopLeft = renderer.coordinates.fractionalCoordsToCanvasCoords(
			topLeftPos,
			wrtVideo,
		);

		hoverDivTopLeft = scaleCoordinates(hoverDivTopLeft);

		hoverDivTopLeft = fractionalCoordsToPixels(
			renderer.coordinates.canvasCoordsToFractionalCoords(
				hoverDivTopLeft,
			),
		);

		if (hoverElement.geo === "image" && hoverElement.imagedata) {
			const { naturalHeight, naturalWidth } = hoverElement.imagedata;
			const w_p = fractionalCoordsToPixels({ x: w, y: h }).x;
			const h_p = (w_p * naturalHeight) / naturalWidth;
			h = pixelsToFractionalCoords(
				{ x: w_p, y: h_p },
				{
					difference: true,
					wrtVideo: isElementWrtVideo(hoverElement),
				},
			).y;
		}

		hoverDivBotRight = renderer.coordinates.fractionalCoordsToCanvasCoords(
			bottomRightPos,
			wrtVideo,
		);

		if (hoverElement.geo === "text") {
			hoverDivBotRight.x += PADDING_W;
			hoverDivBotRight.y += PADDING_H;
		}

		hoverDivBotRight = scaleCoordinates(hoverDivBotRight);

		hoverDivBotRight = fractionalCoordsToPixels(
			renderer.coordinates.canvasCoordsToFractionalCoords(
				hoverDivBotRight,
				wrtVideo,
			),
			wrtVideo,
		);

		// ensure the element has a non-zero width and height
		hoverDivWidth = Math.max(
			Math.abs(hoverDivBotRight.x - hoverDivTopLeft.x),
			20,
		);
		hoverDivHeight = Math.max(
			Math.abs(hoverDivBotRight.y - hoverDivTopLeft.y),
			20,
		);

		if (
			cp.currentTime >= renderer.timer.getScreenclipEndTime() ||
			cp.currentTime <= renderer.timer.getScreenclipStartTime()
		) {
			if (canvasRef?.current) {
				const rect = canvasRef.current.getBoundingClientRect();
				const hoverPositions = new MutableElement(
					hoverElement,
				).getPosition();
				hoverDivTopLeft = {
					x: hoverPositions.x * rect.width,
					y: hoverPositions.y * rect.height,
				};
				hoverDivWidth = w * rect.width;
				hoverDivHeight = h * rect.height;
				if (hoverElement.imagedata) {
					const { naturalHeight, naturalWidth } =
						hoverElement.imagedata;
					hoverDivHeight =
						(hoverDivWidth * naturalHeight) / naturalWidth;
				}
			}
		}
	}

	if (activeElement) {
		const w = activeElement.size[0];
		let h = activeElement.size[1];

		// check if the element is flipped vertically or horizontally (added for arrow support)
		const isFlippedVertically = h < 0;
		const isFlippedHorizontally = w < 0;

		if (activeElement.geo === "image" && activeElement.imagedata) {
			const { naturalHeight, naturalWidth } = activeElement.imagedata;
			const w_p = fractionalCoordsToPixels({ x: w, y: h }).x;
			const h_p = (w_p * naturalHeight) / naturalWidth;
			h = pixelsToFractionalCoords(
				{ x: w_p, y: h_p },
				{
					difference: true,
					wrtVideo: isElementWrtVideo(activeElement),
				},
			).y;
		}

		const activePos = new MutableElement(activeElement).getPosition();

		// if the element is flipped horizontally, add the width to the x position to get the top left position
		// if the element is flipped vertically, add the height to the y position to get the top left position
		const topLeftPos = {
			x: isFlippedHorizontally ? activePos.x + w : activePos.x,
			y: isFlippedVertically ? activePos.y + h : activePos.y,
		};

		// if the element is flipped horizontally, add the width to the x position to get the bottom right position
		// if the element is flipped vertically, add the height to the y position to get the bottom right position
		const bottomRightPos = {
			x: isFlippedHorizontally ? activePos.x : activePos.x + w,
			y: isFlippedVertically ? activePos.y : activePos.y + h,
		};

		activeDivTopLeft = renderer.coordinates.fractionalCoordsToCanvasCoords(
			topLeftPos,
			isElementWrtVideo(activeElement),
		);
		activeDivBotRight = renderer.coordinates.fractionalCoordsToCanvasCoords(
			bottomRightPos,
			isElementWrtVideo(activeElement),
		);

		if (activeElement.geo === "text") {
			activeDivBotRight.x += PADDING_W;
			activeDivBotRight.y += PADDING_H;
		}

		activeDivTopLeft = scaleCoordinates(activeDivTopLeft);
		activeDivBotRight = scaleCoordinates(activeDivBotRight);

		activeDivTopLeft = fractionalCoordsToPixels(
			renderer.coordinates.canvasCoordsToFractionalCoords(
				activeDivTopLeft,
				isElementWrtVideo(activeElement),
			),
			isElementWrtVideo(activeElement),
		);
		activeDivBotRight = fractionalCoordsToPixels(
			renderer.coordinates.canvasCoordsToFractionalCoords(
				activeDivBotRight,
				isElementWrtVideo(activeElement),
			),
			isElementWrtVideo(activeElement),
		);

		// swap the top left and bottom right positions if the element is flipped vertically or horizontally ( top left becomes bottom right and bottom right becomes top left )
		if (isFlippedVertically) {
			[activeDivTopLeft.y, activeDivBotRight.y] = [
				activeDivBotRight.y,
				activeDivTopLeft.y,
			];
		}
		if (isFlippedHorizontally) {
			[activeDivTopLeft.x, activeDivBotRight.x] = [
				activeDivBotRight.x,
				activeDivTopLeft.x,
			];
		}

		// Calculate width and height using absolute values
		activeDivWidth = Math.abs(activeDivBotRight.x - activeDivTopLeft.x);
		activeDivHeight = Math.abs(activeDivBotRight.y - activeDivTopLeft.y);

		if (
			cp.currentTime >= renderer.timer.getScreenclipEndTime() ||
			cp.currentTime <= renderer.timer.getScreenclipStartTime()
		) {
			if (canvasRef?.current) {
				const rect = canvasRef.current.getBoundingClientRect();
				const activePositions = new MutableElement(
					activeElement,
				).getPosition();

				activeDivTopLeft = {
					x:
						(isFlippedHorizontally
							? activePositions.x + w
							: activePositions.x) * rect.width,
					y:
						(isFlippedVertically
							? activePositions.y + h
							: activePositions.y) * rect.height,
				};

				activeDivWidth = Math.abs(w) * rect.width;
				activeDivHeight = Math.abs(h) * rect.height;
				if (activeElement.imagedata) {
					const { naturalHeight, naturalWidth } =
						activeElement.imagedata;
					activeDivHeight =
						(activeDivWidth * naturalHeight) / naturalWidth;
				}
			}
		}
	}

	const getSizeControls = (
		element: ElementEdit | null,
	): SizeControllerPos[] => {
		if (!element) return ["tl", "tr", "br", "bl", "l", "r"];
		if (element.geo === "image") {
			return ["tl", "tr", "br", "bl"];
		} else if (element.geo === "arrow") {
			return ["tl", "br"];
		} else {
			return ["tl", "tr", "br", "bl", "l", "r"];
		}
	};

	const showElementEditor: (element: ElementEdit) => boolean = (element) => {
		let showElementEditor = false;
		if (isOverlayOpen) return false;

		if (
			cp.currentTimeRef &&
			element.startTime <=
				renderer.timer.getUnadjustedTime(cp.currentTimeRef.current) &&
			element.endTime >=
				renderer.timer.getUnadjustedTime(cp.currentTimeRef.current) &&
			cp.paused
		) {
			showElementEditor = true;
		}

		const isIntroElement = videoEdits.intro?.coverEdits?.find(
			(e) => e.id === element.id,
		);
		const isOutroElement = videoEdits.outro?.coverEdits?.find(
			(e) => e.id === element.id,
		);

		if (
			cp.currentTime >= renderer.timer.getScreenclipEndTime() &&
			cp.paused &&
			cp.currentTimeRef &&
			isOutroElement
		) {
			showElementEditor = true;
		}

		if (
			cp.currentTime < renderer.timer.getScreenclipStartTime() &&
			cp.paused &&
			cp.currentTimeRef &&
			isIntroElement
		) {
			showElementEditor = true;
		}

		return showElementEditor;
	};
	const setIsElementNearCenter: () => void = () => {
		if (!activeElement) return;
		const activeElementPos = new MutableElement(
			activeElement,
		).getPosition();
		const x = activeElementPos.x + activeElement.size[0] / 2;
		const y = activeElementPos.y + activeElement.size[1] / 2;

		let horizontal = false;
		let vertical = false;
		if (Math.abs(x - 0.5) < 0.015) {
			horizontal = true;
		}
		if (Math.abs(y - 0.5) < 0.015) {
			vertical = true;
		}
		_setIsElementNearCenter({
			horizontal: horizontal,
			vertical: vertical,
		});
	};

	useEffect(() => {
		setIsElementNearCenter();
	}, [activeElement?.position]);

	const handleCanvasClick = () => {
		dispatch(setActiveArea(hoveredArea));
		dispatch(setActiveElement(null));
		setHoverElementId(null);

		if (
			cp.currentTime <= renderer.timer.getScreenclipStartTime() &&
			hoveredArea === "background"
		) {
			dispatch(setCustomizerPage("Intro"));
		}

		if (
			cp.currentTime >= renderer.timer.getScreenclipEndTime() &&
			hoveredArea === "background"
		) {
			dispatch(setCustomizerPage("Outro"));
		}
	};

	useEffect(() => {
		const handleClickOutside = (event: any) => {
			if (!canvasRef?.current) return;

			if (!canvasRef.current.contains(event.target)) {
				// Ignore clicks from elements inside '.VideoToolBar'
				if (event.target?.closest?.(".VideoToolBar")) {
					return;
				}

				// if event.target contains my-element-container or its child elements, ignore the click
				if (
					event.target?.closest?.(".new-element-img-icon") ||
					event.target?.closest?.(".my-element-container") ||
					event.target?.closest?.(".CTabs") ||
					event.target?.closest?.(".css-8ipyqe-option") ||
					event.target?.closest?.(".ant-color-picker-palette") ||
					event.target?.closest?.(".Timelines-container") ||
					event.target?.closest?.(".ant-popover")
				) {
					return;
				}

				dispatch(setActiveArea(null));
				dispatch(setActiveElement(null));
			}
		};

		document.addEventListener("click", handleClickOutside);

		return () => {
			document.removeEventListener("click", handleClickOutside);
		};
	}, [dispatch]);

	const isCurrentTimeInIntroOrOutro = () => {
		return (
			cp.currentTime <= renderer.timer.getScreenclipStartTime() ||
			cp.currentTime >= renderer.timer.getScreenclipEndTime()
		);
	};

	// Helper function to compute video overlay style accounting for zoom factor
	const getZoomedVideoOverlayStyle = () => {
		const videoTopLeftCanvas =
			renderer.coordinates.fractionalCoordsToCanvasCoords(
				{ x: 0, y: 0 },
				true,
			);
		const videoBottomRightCanvas =
			renderer.coordinates.fractionalCoordsToCanvasCoords(
				{ x: 1, y: 1 },
				true,
			);
		const scaledTopLeft = currentZoom
			? scaleCoordinates(videoTopLeftCanvas)
			: videoTopLeftCanvas;
		const scaledBottomRight = currentZoom
			? scaleCoordinates(videoBottomRightCanvas)
			: videoBottomRightCanvas;
		const fracTopLeft = renderer.coordinates.canvasCoordsToFractionalCoords(
			scaledTopLeft,
			true,
		);
		const fracBottomRight =
			renderer.coordinates.canvasCoordsToFractionalCoords(
				scaledBottomRight,
				true,
			);
		const pixelTopLeft = fractionalCoordsToPixels(fracTopLeft, true);
		const pixelBottomRight = fractionalCoordsToPixels(
			fracBottomRight,
			true,
		);
		return {
			top: pixelTopLeft.y,
			left: pixelTopLeft.x,
			width: pixelBottomRight.x - pixelTopLeft.x,
			height: pixelBottomRight.y - pixelTopLeft.y,
		};
	};

	return (
		<>
			<div
				className="gigauser-canvasplayer"
				style={{
					display: cp.loading ? "none" : "flex",
				}}
			>
				<canvas
					id="gigauser-video-canvas"
					className="gigauser-video-canvas"
					width={canvasWidth}
					height={canvasHeight}
					ref={canvasRef}
					onMouseMove={handleMouseMove}
					onMouseLeave={() => {
						setHoveredArea(null);

						_setIsElementNearCenter({
							vertical: false,
							horizontal: false,
						});
					}}
					onClick={handleCanvasClick}
				></canvas>

				{hoveredArea === "background" && (
					<div
						className="video-hover-overlay"
						style={{
							position: "absolute",
							top:
								fractionalCoordsToPixels({ x: 0, y: 0 }, false)
									.y + "px",
							left:
								fractionalCoordsToPixels({ x: 0, y: 0 }, false)
									.x + "px",
							width:
								fractionalCoordsToPixels({ x: 1, y: 1 }, false)
									.x -
								fractionalCoordsToPixels({ x: 0, y: 0 }, false)
									.x +
								"px",
							height:
								fractionalCoordsToPixels({ x: 1, y: 1 }, false)
									.y -
								fractionalCoordsToPixels({ x: 0, y: 0 }, false)
									.y +
								"px",
							border: "2px dashed  #d43f8c",
							pointerEvents: "none",
							zIndex: 10,
						}}
					/>
				)}

				{!isCurrentTimeInIntroOrOutro() &&
					hoveredArea === "video" &&
					(() => {
						const style = getZoomedVideoOverlayStyle();
						return (
							<div
								className="video-hover-overlay"
								style={{
									position: "absolute",
									top: style.top + "px",
									left: style.left + "px",
									width: style.width + "px",
									height: style.height + "px",
									border: "2px dashed #d43f8c",
									pointerEvents: "none",
									zIndex: 10,
								}}
							/>
						);
					})()}

				{activeArea === "background" && (
					<div
						className="video-hover-overlay"
						style={{
							position: "absolute",
							top:
								fractionalCoordsToPixels({ x: 0, y: 0 }, false)
									.y + "px",
							left:
								fractionalCoordsToPixels({ x: 0, y: 0 }, false)
									.x + "px",
							width:
								fractionalCoordsToPixels({ x: 1, y: 1 }, false)
									.x -
								fractionalCoordsToPixels({ x: 0, y: 0 }, false)
									.x +
								"px",
							height:
								fractionalCoordsToPixels({ x: 1, y: 1 }, false)
									.y -
								fractionalCoordsToPixels({ x: 0, y: 0 }, false)
									.y +
								"px",
							border: "2px solid #d43f8c",
							pointerEvents: "none",
							zIndex: 10,
						}}
					/>
				)}

				{!isCurrentTimeInIntroOrOutro() &&
					activeArea === "video" &&
					(() => {
						const style = getZoomedVideoOverlayStyle();
						return (
							<div
								className="video-hover-overlay"
								style={{
									position: "absolute",
									top: style.top + "px",
									left: style.left + "px",
									width: style.width + "px",
									height: style.height + "px",
									border: "2px solid #d43f8c",
									pointerEvents: "none",
									zIndex: 10,
								}}
							/>
						);
					})()}

				{primeElement && showElementEditor(primeElement) ? (
					<div>
						{hoverElement &&
							hoverElement.id !== activeElement?.id && (
								<div
									className={`canvas-hoverElementDiv ${"show-border"} `}
									onClick={(e) => {
										if (
											cp.currentTime <
												renderer.timer.getScreenclipStartTime() ||
											cp.currentTime >=
												renderer.timer.getScreenclipEndTime()
										) {
											if (hoverElement) {
												dispatch(
													setActiveArea("element"),
												);
												dispatch(
													setActiveCoverElement(
														hoverElement.id,
													),
												);
											} else {
												dispatch(
													setActiveCoverElement(null),
												);
											}
										} else {
											if (hoverElement) {
												dispatch(
													setActiveArea("element"),
												);
												dispatch(
													setActiveElementDefaultTab(
														"design",
													),
												);
												dispatch(
													setActiveElement(
														hoverElement.id,
													),
												);
											} else {
												dispatch(
													setActiveElement(null),
												);
											}
										}
										dispatch(setCustomizerPage("Elements"));
										// dispatch(
										// 	setActiveElement(primeElement.id),
										// );
										e.stopPropagation();
									}}
									style={{
										left: `${hoverDivTopLeft.x}px`,
										top: `${hoverDivTopLeft.y}px`,
										width: `${hoverDivWidth}px`,
										height: `${hoverDivHeight}px`,
										// change the scale to flip the element if it is flipped vertically or horizontally
										transform: `scale(${hoverElement?.size[0] ? (hoverElement.size[0] < 0 ? -1 : 1) : 1}, ${hoverElement?.size[1] ? (hoverElement.size[1] < 0 ? -1 : 1) : 1})`,
									}}
								/>
							)}
						<div
							className={`canvas-hoverElementDiv ${"hide-border"} `}
							onClick={(e) => {
								if (
									cp.currentTime <
										renderer.timer.getScreenclipStartTime() ||
									cp.currentTime >=
										renderer.timer.getScreenclipEndTime()
								) {
									if (hoverElement) {
										dispatch(
											setActiveCoverElement(
												hoverElement.id,
											),
										);
									} else {
										dispatch(setActiveCoverElement(null));
									}
								} else {
									if (hoverElement) {
										dispatch(
											setActiveElement(hoverElement.id),
										);
										dispatch(
											setActiveElementDefaultTab(
												"design",
											),
										);
									} else {
										dispatch(setActiveElement(null));
									}
								}
								dispatch(setCustomizerPage("Elements"));

								e.stopPropagation();
							}}
							style={{
								left: `${Math.min(activeDivTopLeft.x, activeDivBotRight.x)}px`,
								top: `${Math.min(activeDivTopLeft.y, activeDivBotRight.y)}px`,
								width: `${activeDivWidth}px`,
								height: `${activeDivHeight}px`,
								// change the scale to flip the element if it is flipped vertically or horizontally
								transform: `scale(${activeElement?.size[0] ? (activeElement.size[0] < 0 ? -1 : 1) : 1}, ${activeElement?.size[1] ? (activeElement.size[1] < 0 ? -1 : 1) : 1})`,
							}}
						>
							{activeElement &&
							showElementEditor(activeElement) ? (
								<DragController
									shape={activeElement}
									zoomFactor={currentZoom?.zoomFactor}
									getRelativeCoords={(pos) =>
										pixelsToFractionalCoords(pos, {
											difference: true,
											wrtVideo: activeElement
												? isElementWrtVideo(
														activeElement,
													)
												: undefined,
										})
									}
									getAbsoluteCoords={(pos) =>
										fractionalCoordsToPixels(
											pos,
											activeElement
												? isElementWrtVideo(
														activeElement,
													)
												: undefined,
										)
									}
									updateShape={updateElementEdit}
									toggleCenterGuideLines={
										_setIsElementNearCenter
									}
									toggleVerticalElementGuideLines={
										setVerticalElementGuideLine
									}
									toggleHorizontalElementGuideLines={
										setHorizontalElementGuideLine
									}
									boundLimits={activeElement.geo !== "image"}
									elements={currentElements.current}
								/>
							) : null}
							{getSizeControls(activeElement).map((pos) =>
								activeElement &&
								showElementEditor(activeElement) ? (
									<SizeControllerThumb
										shape={activeElement}
										position={pos}
										key={pos}
										zoomFactor={currentZoom?.zoomFactor}
										getRelativeCoords={(pos) =>
											pixelsToFractionalCoords(pos, {
												difference: true,
												wrtVideo: activeElement
													? isElementWrtVideo(
															activeElement,
														)
													: undefined,
											})
										}
										getAbsoluteCoords={(pos) =>
											pixelsToFractionalCoords(pos, {
												difference: true,
												wrtVideo: activeElement
													? isElementWrtVideo(
															activeElement,
														)
													: undefined,
											})
										}
										updateShape={(
											id: string,
											updatedProperties: UpdateShapeProperties,
										) => {
											updateElementEdit(
												id,
												updatedProperties,
											);
										}}
										boundLimits
										lockRatio={
											activeElement.geo === "text" ||
											activeElement.geo === "image"
										}
										MIN_DIM_W={
											activeElement.geo === "arrow"
												? -100000 // arrows can have any width to allow rotation
												: 0.01
										}
									/>
								) : null,
							)}
						</div>

						{activeElement &&
						showElementEditor(activeElement) &&
						isElementNearCenter.vertical &&
						!isOverlayOpen ? (
							<div className="horizontal-line"></div>
						) : null}

						{activeElement &&
						showElementEditor(activeElement) &&
						isElementNearCenter.horizontal &&
						!isOverlayOpen ? (
							<div className="vertical-line"></div>
						) : null}
						{activeElement && showElementEditor(activeElement) ? (
							<GuideLines
								type="horizontal"
								elementGuideLine={horizontalElementGuideLine}
							/>
						) : null}

						{activeElement && showElementEditor(activeElement) ? (
							<GuideLines
								type="vertical"
								elementGuideLine={verticalElementGuideLine}
							/>
						) : null}
					</div>
				) : null}
			</div>
			<div
				className="gigauser-canvasplayer-spinner"
				style={{
					display: cp.loading ? undefined : "none",
				}}
			>
				<Spinner size={"xl"} color="#d43f8c" />
			</div>
		</>
	);
};

export default CanvasPlayer;
