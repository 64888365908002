import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState, useCallback } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../redux";
import { useRemirrorContext } from "@remirror/react";
import overlaySlice, {
	closeOverlay,
	openOverlay,
} from "../../../../../layouts/Overlay/overlaySlice";
import { getImagesAroundPosition } from "../../utils/getImagesAroundPosition";
import { Node, NodeType } from "@remirror/pm/dist-types/model";
import VideoSlider from "./VideoSlider";
import { saver } from "../../../../../network/saver";
import { selectGuidePreview } from "../../../../../redux/slices/guideSlice";
import { Id } from "../../../../../core/types/baseTypes";
import { openModal } from "../../../../../layouts/CModal/modalSlice";
// Import other hooks if necessary

const useAddScreenshot = (type: "videoTranscript" | "textEditor") => {
	const dispatch = useAppDispatch();
	const guidePreview = useAppSelector(selectGuidePreview);
	const { commands, manager } = useRemirrorContext();
	const src = useAppSelector(
		(state) => state.guide.value.guide?.guideData.video.originalSrc,
	) as string;
	// Define the command to insert the screenshot
	const insertScreenshot = async (uri: string, timestamp: number) => {
		//@ts-ignore
		const resp = await saver.uploadImage(guidePreview?.id, uri);

		getAspectRatioFromDataURI(uri, (aspectRatio: number) => {
			let node: Node = manager.schema.nodes.image.create({
				src: uri,
				metadata: {
					stepId: Date.now().toString(),
					timestamp: timestamp,
					s3ObjectId: resp,
				},
				width: aspectRatio < 1 ? "240px" : "100%",
			});
			if (type === "textEditor") {
				node = manager.schema.nodes.paragraph.create(
					{ nodeTextAlignment: "center" },
					[node],
				);
			}
			commands.insertNode(node);
			dispatch(closeOverlay());
		});
	};
	// Retrieve original source from the current guide
	// Define original timestamps based on cursor position and existing screenshots

	const reloadSrc = async () => {
		const newData = await saver.guides.data.get(guidePreview?.id as Id);
		return newData?.video.originalSrc as string;
	};
	// Pass to component

	return () => {
		const pos = manager.view.state.selection;
		const { start, end } = getImagesAroundPosition(
			pos.from,
			manager.view.state.doc,
		);
		dispatch(
			openOverlay({
				heading: {
					icon: null,
					title: "Add Sync Marker",
					data: "",
				},
				component: (
					<VideoSlider
						type={type}
						source={src}
						initialStartTimestamp={start}
						initialEndTimestamp={end}
						addImage={insertScreenshot}
						reloadSource={reloadSrc}
					/>
				),
			}),
		);
	};
};

function getAspectRatioFromDataURI(
	dataURI: string,
	callback: (aspectRatio: number) => void,
) {
	// Create an image object
	const img = new Image();

	// Define the onload function
	img.onload = () => {
		// Get the image dimensions
		const width = img.width;
		const height = img.height;

		// Calculate the aspect ratio
		const aspectRatio = width / height;

		// Callback with the aspect ratio
		callback(aspectRatio);
	};

	// Set the image source to the data URI
	img.src = dataURI;
}

export default useAddScreenshot;
