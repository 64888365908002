import React, { useContext, useEffect, useState } from "react";
import {
	Slider,
	SliderTrack,
	SliderFilledTrack,
	SliderThumb,
	SliderMark,
} from "@chakra-ui/react";

import "./CanvasScrubber.css";
import CanvasPlayerContext from "../../../CanvasPlayerContext";
import { CanvasTimer } from "@gigauser/common/src/core/canvas/canvasUtils/immutables/timer/CanvasTimer";

type CanvasScrubberProps = {};

const CanvasScrubber: React.FC<CanvasScrubberProps> = () => {
	const [sliderValue, setSliderValue] = React.useState(5);
	const [showTooltip, setShowTooltip] = React.useState(false);

	const [mouseDown, setMouseDown] = useState(false);

	const [smooth, setSmooth] = useState(true);

	const cp = useContext(CanvasPlayerContext);
	const timer = new CanvasTimer(cp.videoEdits, {
		videoDuration: cp.vidRef?.current?.duration ?? 0,
	});

	const vidRef = cp.vidRef;

	if (!vidRef) throw new Error("Missing vidRef in CanvasScrubber!");

	const [value, setValue] = useState(cp.currentTime);

	const onChange = (value: number) => {
		cp.pause();
		cp.hardsetCurrentTime(value);
		setSmooth(false);
	};

	const onChangeEnd = () => {
		if (cp.paused) setSmooth(false);
		else setSmooth(true);
	};

	useEffect(() => {
		setValue(cp.currentTime);
	}, [cp.currentTime]);

	useEffect(() => {
		if (cp.paused) setSmooth(false);
		else setSmooth(true);
	}, [cp.paused]);

	const totalDuration = timer.getVideoDuration();

	return (
		<div
			onMouseEnter={() => {
				setMouseDown(true);
			}}
			onMouseUp={() => {
				setTimeout(() => {
					setMouseDown(false);
				}, 400);
			}}
		>
			<Slider
				aria-label="slider-ex-1"
				defaultValue={0}
				value={value}
				min={0}
				max={totalDuration}
				onChange={onChange}
				onChangeEnd={onChangeEnd}
				className="gigauser-scrubber-slider"
			>
				<SliderTrack className="gigauser-scrubber-slidertrack">
					<SliderFilledTrack
						className={`gigauser-scrubber-sliderfilledtrack 
                 gigauser-scrubber-sliderfilledtrack ${smooth ? "gigauser-smooth-track" : ""}`}
					/>
				</SliderTrack>
				<SliderThumb className="gigauser-scrubber-sliderthumb" />
			</Slider>
		</div>
	);
};
export default CanvasScrubber;
