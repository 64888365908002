import { GigaUserApi } from "@giga-user-fern/api";
import {
	Coordinates,
	ElementEdit,
	ElementGeo,
} from "@giga-user-fern/api/types/api";

export type TransitionType =
	| "circleWipe"
	| "crossfade"
	| "rainbowWipe"
	| "cornerWipe"
	| "stack"
	| "colorWipe"
	| "fade";

export const drawRoundedRect = (
	ctx: CanvasRenderingContext2D,
	x: number,
	y: number,
	width: number,
	height: number,
	radius: number,
) => {
	// Ensure the radius does not exceed 1/2 of the smallest rectangle dimension
	radius = Math.min(radius, width / 2, height / 2);

	// Begins a new path (to avoid issues with previous paths)
	ctx.beginPath();

	// Moves to the start point of the top horizontal line
	ctx.moveTo(x + radius, y);

	// Draws lines and curves
	ctx.lineTo(x + width - radius, y);
	ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
	ctx.lineTo(x + width, y + height - radius);
	ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
	ctx.lineTo(x + radius, y + height);
	ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
	ctx.lineTo(x, y + radius);
	ctx.quadraticCurveTo(x, y, x + radius, y);

	// Closes the path and optionally you can fill or stroke the path here
	// ctx.closePath();
};

export const drawBoxShadow = (
	ctx: CanvasRenderingContext2D,
	x: number,
	y: number,
	width: number,
	height: number,
	shadowFactor: number,
	borderRadius?: number,
) => {
	// Draw shadow
	const shadowOffset = 0; // Adjust as needed
	ctx.save();
	ctx.beginPath();

	borderRadius = borderRadius || 0;

	drawRoundedRect(
		ctx,
		x - shadowOffset,
		y - shadowOffset,
		width + 2 * shadowOffset,
		height + 2 * shadowOffset,
		borderRadius + shadowOffset,
	);
	ctx.shadowColor = "rgba(0, 0, 0, 0.6)"; // Adjust as needed
	ctx.shadowBlur = shadowFactor; // Adjust as needed
	ctx.shadowOffsetX = 2; // Adjust as needed
	ctx.shadowOffsetY = 2; // Adjust as needed
	ctx.fillStyle = "#c7c7c7";
	ctx.fill();
	ctx.restore();
};

export const getScaleFactor = (width: number, height: number) => {
	const w_f = width / 1680;
	const h_f = height / 920;

	return { f: (w_f + h_f) / 2, wf: w_f, hf: h_f };
};

export type FractionalCoordsToCanvasCoordsFunction = (
	pos: Coordinates,
	canvas?: HTMLCanvasElement,
) => Coordinates;

export const fractionalCoordsToCanvasCoordsUtil: FractionalCoordsToCanvasCoordsFunction =
	(pos, canvas) => {
		if (!canvas) return pos;

		const canvas_x = canvas.width * pos.x;
		const canvas_y = canvas.height * pos.y;

		return { x: canvas_x, y: canvas_y };
	};

export const sourcesAreEqual = (
	source1: GigaUserApi.Id,
	source2?: GigaUserApi.Id,
) => {
	if (source1.includes("clip_") || source2?.includes("clip_")) {
		//either of the sources is a clip (the other could be the original)
		return source1 === source2;
	} else return true;
};

export const loadImage = (src: string): Promise<HTMLImageElement> => {
	return new Promise((resolve, reject) => {
		const img = new Image();
		img.onload = () => resolve(img);
		img.onerror = reject;
		img.src = src;
	});
};

export const isElementWrtVideo = (element: ElementEdit) => {
	/**
	 * Takes in an element, and checks if this elements size and position are wrt video or canvas.
	 */
	const elementsWrtCanvas: ElementGeo[] = ["text", "image"];
	const wrtVideo = !elementsWrtCanvas.includes(element.geo);

	return wrtVideo;
};
