import React, { useContext, useEffect, useState } from "react";
import "./HelpCenterIndex.css";
import ExtHelpCenterContext from "../ExtHelpCenterContext";
import {
	GuidePreview,
	Organization,
} from "@gigauser/common/src/core/types/guide";
import { Collection } from "@gigauser/common/src/core/types/collections";
import {
	CollectionNode,
	Tree,
	setTree,
	updateNodeInTree,
} from "../../../redux/slices/hcSlice";
import { useAppDispatch, useAppSelector } from "../../../redux/index";
import FolderAccordion from "./FolderAccordion/FolderAccordion";
import useGetSubtree from "./hooks/useGetSubtree";
import { rootCollection } from "@gigauser/common/src/types/files";
import useWindowSize from "@gigauser/common/src/hooks/useWindowSize";
import { indexbarThreshold } from "../layout";
import {
	getCollectionNodeInTree,
	updateCollectionPathInTree,
} from "./treeProcessingUtils";
import { current } from "@reduxjs/toolkit";
import logo from "@gigauser/common/src/assets/logos/Logo.svg";
import Icon from "@gigauser/common/src/ui/Icon/Icon";
import orgSetupData from "@gigauser/common/src/setup/orgSetupData";

type HelpCenterIndexProps = {};

const HelpCenterIndex: React.FC<HelpCenterIndexProps> = () => {
	// const [initLoading, setInitLoading] = useState(true)

	const dispatch = useAppDispatch();
	const tree = useAppSelector((state) => state.hc.tree);

	const getSubtree = useGetSubtree();

	const collectionPath = useAppSelector((state) => state.hc.collectionPath);

	var hideVideoBranding = false;

	const helpCenterContext = useContext(ExtHelpCenterContext);
	const org = helpCenterContext.getOrganization();
	const orgId = org?.id;

	if (orgId) {
		hideVideoBranding =
			orgSetupData[orgId.toString()]?.hideVideoBranding || false;
	}

	const expandTree = async () => {
		if (!collectionPath) {
			return;
		}
		let currentTree = tree;
		if (tree.length === 0) {
			currentTree = await getSubtree();
		}
		const _collectionPath = [...collectionPath].filter(
			(collection) => collection.id !== rootCollection.id,
		);
		const collectionNodes: CollectionNode[] = JSON.parse(
			JSON.stringify(
				_collectionPath.map((x) =>
					getCollectionNodeInTree(currentTree, x),
				),
			),
		);
		const updatedCollectionNodes = collectionNodes.map(async (x, index) => {
			if (x && Array.isArray(x.children) && !x.collapsed) {
				x.collapsed = false;
			} else {
				const children = await getSubtree(_collectionPath[index].id);
				const finalNode: CollectionNode = {
					type: "collection",
					collection: _collectionPath[index],
					collapsed: false,
					children: children,
				};
				return finalNode;
			}
			return x;
		});
		const fullCollectionNode = await Promise.all(updatedCollectionNodes);
		const newTree = updateCollectionPathInTree(
			currentTree,
			fullCollectionNode,
		);
		dispatch(setTree(newTree));
	};

	useEffect(() => {
		expandTree();
	}, [collectionPath]);

	const windowSize = useWindowSize();

	return (
		<div className="HelpCenterIndex-Container">
			<div
				className={`HelpCenterIndex style-scroll-square ${windowSize.width < indexbarThreshold ? "hide" : ""}`}
			>
				<div className="mainFolderAccordion">
					{collectionPath && (
						<FolderAccordion
							tree={tree}
							parentSegments={[]}
						></FolderAccordion>
					)}
				</div>
			</div>
			<div
				className="powered-by-Clueso-container"
				style={{ display: hideVideoBranding ? "none" : undefined }}
			>
				<a
					className="powered-by-Clueso"
					href={
						"https://www.clueso.io/?utm_source=customerKB&utm_medium=poweredby&utm_campaign=" +
						window.location.hostname
					}
					target="_blank"
					rel="noreferrer"
				>
					<p className="helpcenter-index-footer-text">Powered by </p>
					<img
						className="helpcenter-index-footer-logo"
						src={logo}
					></img>
				</a>
			</div>
		</div>
	);
};

export default HelpCenterIndex;
